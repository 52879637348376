import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { getTimeZone, updateTimeZone } from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import { arrayToString } from "../../../../../../helpers/stringArray";
import Header from "../../../../../Header/Header";

export default function TimeZone() {
  const [timeZone, setTimeZone] = React.useState("");
  const { token } = React.useContext(AuthContext);

  React.useEffect(() => {
    getTimeZone(token)
      .then((res) => {
        console.log({ res });
        if (res && res.status === 200) {
          setTimeZone(res.data);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token]);

  const handleSubmit = React.useCallback(
    (event) => {
      console.log({ timeZone });
      updateTimeZone(token, timeZone)
        .then((res) => {
          console.log({ res });
          if (res && res.status === 200) {
            alert("Update Successfully!");
            setTimeZone(res.data);
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    [timeZone, token]
  );
  return (
    <>
      <Header title="Time Zone" />
      <Grid container spacing={1} direction="column">
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Value (Ex: +0700)"
            fullWidth
            variant="outlined"
            value={timeZone}
            onChange={(e) => setTimeZone(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
