import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import ClassItem from "./ClassItem";
import EditClass, {
  updateBookingDiscount,
  updateOtherClassNoCalendar,
} from "./EditClass";
import AddIcon from "@mui/icons-material/Add";
import {
  deleteClass,
  getBookingAll,
  getClassAll,
  getClassByDateRange,
  getCustomerAll,
  getRoomAll,
  searchClass,
  updateBookingDiscountPercent,
} from "../../../../api/api";
import {
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import ListHeader from "../../../Header/ListHeader";
import ListSearch from "../../../Header/ListSearch";
import {
  deleteCalendarEvent,
  updateCalendarEvent,
} from "../../../../services/calendar";
import moment from "moment";
import { CLASS_DATE_RANGES } from "../../../../constants";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import { AppContext } from "../../../../context/AppProvider";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";

export async function deleteItemClass(
  token,
  item,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const eventRes = await deleteCalendarEvent(
    item.calendarId,
    item.roomId.calendarId
  );
  const eventStatusList = [404, 410, 200, 205, 204];
  if (eventStatusList.indexOf(eventRes.status) > -1) {
    const classStatus = await deleteClass(token, item)
      .then((res) => {
        // successCallback(res);
        return res.status;
      })
      .catch((err) => {
        console.log({ err });
        // errorCallback(err);
        return err.response.status;
      });
    return {
      calendarCode: eventRes.status,
      serverCode: classStatus,
    };
  } else {
    return { calendarCode: eventRes.status, serverCode: 0 };
  }
  //   setRules(newRules);
}

export default function Class() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [bookings, setBookings] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const { searchMem, setSearchMem } = React.useContext(AppContext);
  const { dateRangeMem, setDateRangeMem } = React.useContext(AppContext);
  // const [dateRange, setDateRange] = React.useState(CLASS_DATE_RANGES[0].value);
  const [fetching, setFetching] = React.useState(false);
  // const [progress, setProgress] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateDatas = React.useCallback((newDatas, setInit = true) => {
    // console.log({ res });
    newDatas.sort(function (a, b) {
      return new Date(a.startTime) - new Date(b.startTime);
    });
    console.log({ newDatas });
    if (setInit) {
      setInitDatas(newDatas);
    }
    setDatas(newDatas);
    // console.log({ newDatas });
  }, []);

  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchClass(token, { search: value })
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      // if (searchText) {
      // setRangeTime(CLASS_DATE_RANGES[0].value);
      // console.log("filter");
      // const newDatas = initDatas.filter(function (value, index, arr) {
      //   return (
      //     value.customerId.name
      //       .toLowerCase()
      //       .includes(search.toLowerCase()) ||
      //     value.customerId.phone.includes(search)
      //   );
      // });
      // setDatas(newDatas);
      debounceFetcher(searchText);
      // } else {
      // console.log("not filter");
      //   setDatas(initDatas);
      // }
      // navigate(pathname + "/" + search.id);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      // const successCallback = (res) => {
      //   setFetching(false);
      //   alert("Delete Successfully!");
      //   // console.log({ res });
      //   const newDatas = datas.filter(function (value, index, arr) {
      //     return value._id !== res.data._id;
      //   });
      //   setDatas(newDatas);
      //   updateOtherClassNoCalendar(token, res.data.bookingId, true, []);
      // };
      // const errorCallback = () => {
      //   setFetching(false);
      // };
      setProgress(10);
      setFetching(true);
      let alertContent = "";
      const { calendarCode, serverCode } = await deleteItemClass(token, item);
      setProgress(50);
      if (serverCode === 200) {
        const newDatas = datas.filter(function (value, index, arr) {
          return value._id !== item._id;
        });
        setDatas(newDatas);
        const { update, updatedCount, errorCount } =
          await updateOtherClassNoCalendar(
            token,
            item.bookingId._id,
            true,
            [],
            [],
            setProgress,
            50,
            80
          );
        if (update) {
          alertContent += `Calendar: [${updatedCount} OK] - [${errorCount} ERROR]`;
        }
        const res = await updateBookingDiscount(
          token,
          item?.bookingId,
          mountedRef
        );
        if (res?.msg) {
          alertContent += `\n${res.msg}`;
        }
        // if (item?.bookingId?.discountType === "rule") {
        //   await updateBookingDiscountPercent(token, item?.bookingId)
        //     .then((res) => {
        //       if (!mountedRef.current) return null;
        //       if (res && res.status === 200) {
        //         console.log({ booking: res });
        //       }
        //     })
        //     .catch((err) => {
        //       if (!mountedRef.current) return null;
        //       console.log({ err });
        //       alertContent += "Update Booking Discount Percent failed! ";
        //     });
        // }
        alertContent += `Delete Success: [Calendar: ${calendarCode}] - [DB: ${serverCode}]`;
      } else {
        alertContent += `Delete ERROR! [Calendar: ${calendarCode}] - [DB: ${serverCode}]`;
      }
      setProgress(100);
      setFetching(false);
      alert(alertContent);
      // deleteCalendarEvent(item.calendarId, item.roomId.calendarId, () => {
      //   deleteClass(token, item)
      //     .then(async (res) => {
      //       alert("Delete Successfully!");
      //       console.log({ res });
      //       const newDatas = datas.filter(function (value, index, arr) {
      //         return value._id !== res.data._id;
      //       });
      //       setDatas(newDatas);
      //       updateOtherClassNoCalendar(token, res.data.bookingId, true, []);
      //     })
      //     .catch((err) => {
      //       console.log({ err });
      //       setFetching(false);
      //     });
      // });
      //   setRules(newRules);
    },
    [token, datas]
  );
  React.useEffect(() => {
    async function abc() {
      if (searchMem) {
        handleSearch(searchMem);
      } else {
        let alertContent;
        console.log("Getting classes");
        setProgress(50);
        setFetching(true);
        if (dateRangeMem >= 0) {
          const startDate = moment()
            .subtract(dateRangeMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangeMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getClassByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getClassAll(token)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    abc();
  }, [dateRangeMem, handleSearch, searchMem, token, updateDatas]);
  const action = pathArray.pop();
  // console.log({ action });
  if (action === "new") {
    return (
      <EditClass
        datas={datas}
        setDatas={updateDatas}
        customers={customers}
        bookings={bookings}
        rooms={rooms}
      />
    );
    // } else if (action === "view" && id) {
    // return <ViewClass itemId={id} datas={datas} />;
    // } else if (id) {
    //   console.log("id");
    //   return (
    //     <EditClass
    //       itemId={id}
    //       datas={datas}
    //       setDatas={updateDatas}
    //       customers={customers}
    //       bookings={bookings}
    //       rooms={rooms}
    //     />
    //   );
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      rows.push(
        <Grid item key={item._id}>
          <ClassItem
            key={item._id}
            number={i + 1}
            item={item}
            deleteItem={deleteItem}
          />
        </Grid>
      );
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    const selectBox = (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dateRangeMem}
        // label="Date Range"
        fullWidth
        onChange={(event) => {
          // setDateRange(event.target.value);
          setDateRangeMem(event.target.value);
        }}
      >
        {CLASS_DATE_RANGES.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
    return (
      <>
        <Header
          title="Classes"
          rightButton={userInfo?.role === "admin" ? rightButton : null}
        />
        <ListSearch
          handleChange={(searchText) => {
            if (searchText) {
              handleSearch(searchText);
            } else {
              setDatas(initDatas);
            }
            setSearchMem(searchText);
          }}
          selectBox={selectBox}
          placeholder="Name or Phone"
          value={searchMem}
        />
        <ListHeader
          titles={[
            { width: 1, align: "center", title: "No." },
            { width: 5, align: "left", title: "Time" },
            { width: 1, align: "center", title: "Room" },
            { width: 3, align: "center", title: "Pax" },
            { width: 2, align: "center", title: "Action" },
          ]}
        />
        <div className="list-item">
          {fetching ? (
            <div className="center-text">
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={progress} />
              </Box>
            </div>
          ) : (
            <Grid container direction="column" spacing={0}>
              {rows?.length > 0 ? (
                rows
              ) : (
                <Grid item>
                  <Box component="div" className="center-text">
                    No Class found!
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </>
    );
  }
}
