import { Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { deleteWorkingRoom, getWorkingRoomAll } from "../../../../../api/api";
import EditWorkingRoom from "./EditWorkingRoom";
import WorkingRoomItem from "./WorkingRoomItem";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import ListSearch from "../../../../Header/ListSearch";
import ListHeader from "../../../../Header/ListHeader";

export default function WorkingRoom() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  console.log({ pathArray });
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  //   console.log("re-render");
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  
  const updateDatas = React.useCallback((res) => {
    console.log({ res });
    if (res && res.status === 200) {
      const newDatas = res.data;
      // newDatas.sort(function (a, b) {
      //   return new Date(b.createdAt) - new Date(a.createdAt);
      // });
      setInitDatas(newDatas);
      setDatas(newDatas);
    }
  }, []);
  const handleSearch = React.useCallback(
    (search) => {
      console.log({ search });
      if (search) {
        // console.log("filter");
        const newDatas = initDatas.filter(function (value, index, arr) {
          return value.name.toLowerCase().includes(search.toLowerCase());
        });
        setDatas(newDatas);
      } else {
        // console.log("not filter");
        setDatas(initDatas);
      }
      // navigate(pathname + "/" + search.id);
    },
    [initDatas]
  );
  const deleteItem = React.useCallback(
    (item) => {
      deleteWorkingRoom(token, item)
        .then((res) => {
          alert("Delete Successfully!");
          console.log({ res });
          const newDatas = datas.filter(function (value, index, arr) {
            return value._id !== res.data._id;
          });
          setDatas(newDatas);
        })
        .catch((err) => {
          console.log({ err });
        });
      //   setRules(newRules);
    },
    [token, datas]
  );
  React.useEffect(() => {
    console.log("Getting new data");
    getWorkingRoomAll(token)
      .then((res) => {
        updateDatas(res);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token, updateDatas]);
  const action = pathArray.pop();
  console.log({ action });
  if (action === "new") {
    return <EditWorkingRoom datas={datas} setDatas={setDatas} />;
  } else if (id) {
    return <EditWorkingRoom itemId={id} datas={datas} setDatas={setDatas} />;
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      //   if (
      //     userInfo?.role === "admin" ||
      //     (userInfo?.role === "staff" && userInfo?._id === item._id)
      //   ) {
      rows.push(
        <Grid item key={item._id}>
          <WorkingRoomItem number={i + 1} item={item} deleteItem={deleteItem} />
        </Grid>
      );
      //   }
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header title="Working Rooms" rightButton={rightButton} />
        <ListSearch handleChange={handleSearch} />
        <ListHeader
          titles={[
            { width: 1, align: "center", title: "No." },
            { width: 9, align: "center", title: "Name" },
            { width: 2, align: "center", title: "Action" },
          ]}
        />
        <Grid container direction="column" spacing={0}>
          {rows}
        </Grid>
      </>
    );
  }
}
