import "./Settings.css";
import React from "react";
import { Button, Grid } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import DiscountFixed from "./Items/DiscountFixed/DiscountFixed";
import RemindTime from "./Items/RemindTime/RemindTime";
import DiscountRules from "./Items/DiscountRules/DiscountRules";
import { useNavigate } from "react-router-dom";
import Header from "../../../Header/Header";
import SMSContent from "./Items/SMSContent/SMSContent";
import { SETTING_MENU } from "./constants";
import OperationPercent from "./Items/OperationPercent/OperationPercent";
import AllowanceReward from "./Items/AllowanceReward/AllowanceReward";
import TimeZone from "./Items/TimeZone/TimeZone";

export default function Settings() {
  //   const { path } = useMatch();
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();

  const handleItemClick = React.useCallback(
    (event, type) => {
      console.log({ type });
      navigate("/" + paths[1] + "/" + type);
    },
    [navigate, paths]
  );

  if (paths.length === 2) {
    return (
      <>
        <Header title="Settings" />
        <Grid container direction="column" spacing={0}>
          {SETTING_MENU.map((item) => (
            <Grid item key={item.type}>
              <Button
                variant="outlined"
                startIcon={item.icon}
                fullWidth
                className="btn-item-settings"
                size="large"
                color="secondary"
                onClick={(event) => handleItemClick(event, item.type)}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <Routes>
        <Route element={<DiscountFixed />} path="/discountFixed" />
        <Route element={<DiscountRules />} exact path="/discountRules" />
        <Route element={<DiscountRules />} path="/discountRules/:id" />
        <Route element={<DiscountRules />} exact path="/discountRules/new" />
        <Route element={<RemindTime />} path="/remindTime" />
        <Route element={<SMSContent />} path="/smsParkingMotor" />
        <Route element={<SMSContent />} path="/smsParkingMotor/:id" />
        <Route element={<SMSContent />} exact path="/smsParkingMotor/new" />
        <Route element={<SMSContent />} path="/smsParkingCar" />
        <Route element={<SMSContent />} path="/smsParkingCar/:id" />
        <Route element={<SMSContent />} exact path="/smsParkingCar/new" />
        <Route element={<SMSContent />} path="/smsRemind" />
        <Route element={<SMSContent />} path="/smsRemind/:id" />
        <Route element={<SMSContent />} exact path="/smsRemind/new" />
        <Route element={<SMSContent />} path="/smsConfirm" />
        <Route element={<SMSContent />} path="/smsConfirm/:id" />
        <Route element={<SMSContent />} exact path="/smsConfirm/new" />
        <Route element={<SMSContent />} path="/smsClasses" />
        <Route element={<SMSContent />} path="/smsClasses/:id" />
        <Route element={<SMSContent />} exact path="/smsClasses/new" />
        <Route element={<OperationPercent />} path="/operationPercent" />
        <Route element={<AllowanceReward />} path="/allowanceReward" />
        <Route element={<TimeZone />} path="/timeZone" />
      </Routes>
    );
  }
}
