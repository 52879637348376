import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default function CustomerItem({ number, item, deleteItem }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleEditClick = React.useCallback(
    (event) => {
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  return (
    <>
      <Grid container spacing={0} direction="row" className="item-list">
        <Grid item xs={1}>
          <Typography align="center">{number}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography noWrap align="left">{item.name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography algin="left">{item.phone}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(event) => handleEditClick(event)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
