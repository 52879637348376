import { Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { AuthContext } from "../../../../context/AuthProvider";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

export default function UserItem({ item, number, deleteItem }) {
  const { token, userInfo } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleEditClick = React.useCallback(
    (event) => {
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  return (
    <>
      <Grid container spacing={0} direction="row" className="item-list">
        <Grid item xs={1}>
          <Typography align="center">{number}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="left">{item.username}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography noWrap align="center">
            {item.name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center">{item.role}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(event) => handleEditClick(event)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          {userInfo?.role === "admin" ? (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => handleDeleteClick(event)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(event) => {}}
            >
              <HorizontalRuleIcon fontSize="inherit" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}
