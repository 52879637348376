import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import Header from "../Header/Header";
import { Grid } from "@mui/material";
import "./PhotoViewer.css";

export default function PhotoViewer({ src, title }) {
  const [zoomamount, setZoomamount] = React.useState(1);
  function zoom(el) {
    if (el.type === "in") {
      setZoomamount((prev) => prev + 0.1);
    } else {
      if (zoomamount > 1) {
        setZoomamount((prev) => prev - 0.1);
      }
    }
  }

  return (
    <>
      <Header title={title} />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <button onClick={() => zoom({ type: "in" })}>Zoom In</button>
          <button onClick={() => zoom({ type: "out" })}>Zoom out</button>
        </Grid>
        <Grid item>
          <div className="overflowimgcontainer">
            <ScrollContainer className="grabbercontainer" hideScrollbars={true}>
              <img
                style={{
                  width: 100 * zoomamount + "%",
                //   height: 50 * zoomamount + "%",
                }}
                src={src}
                alt=""
              />
            </ScrollContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
