import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { RECURRENCE_ACTION_VALUE } from "../constants";

export default function ConfirmUpdateDialog(props) {
  const {
    setDialogOpenState,
    dialogOpen,
    handleItemClick,
    countAll,
    countFromNow,
    title,
  } = props;
  const options = [
    { label: `This ${title}`, value: RECURRENCE_ACTION_VALUE.thisOne },
    {
      label: `This and following ${title}(s) (${countFromNow})`,
      value: RECURRENCE_ACTION_VALUE.oneAndFollowing,
    },
    {
      label: `All ${title}(s) (${countAll})`,
      value: RECURRENCE_ACTION_VALUE.all,
    },
  ];

  const handleClose = () => {
    setDialogOpenState(false);
  };

  const handleListItemClick = (value) => {
    handleItemClick(value);
  };

  return (
    <Dialog onClose={handleClose} open={dialogOpen}>
      <DialogTitle>Edit recurring Class</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options.map((option) => (
          <ListItem
            button
            onClick={() => handleListItemClick(option.value)}
            key={option.value}
          >
            <ListItemText primary={option.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

ConfirmUpdateDialog.propTypes = {
  setDialogOpenState: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  countAll: PropTypes.number,
  countFromNow: PropTypes.number,
  title: PropTypes.string,
};
