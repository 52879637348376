// import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import moment from "moment";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import "moment/locale/vi";
import {
  convertTimeToHourMin,
  numberWithCommas,
} from "../../../../../helpers/stringDate";
import { Typography, Link } from "@mui/material";
import "./WorkingSummary.css";
import { Box } from "@mui/system";
import { getAllowanceReward, getWorkingSummary } from "../../../../../api/api";
import {
  WORKING_SESSION_STATUS,
  WORKING_STATUS,
  WORKING_SUMMARY_TYPE,
  WORKING_SUMMARY_TYPE_NOW_ALL,
} from "../../../../../constants";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import {
  SETTING_ENABLE_ALLOWANCE,
  SETTING_ENABLE_REWARD,
} from "../../../../../constants";
import { arrayToString } from "../../../../../helpers/stringArray";

function WorkingSessionItem({ data, allowanceReward }) {
  const status = WORKING_SESSION_STATUS.filter((value, index, array) => {
    return value.value === data.status;
  })[0];
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {data.no + ". "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        <Link
          href={
            window.location.protocol +
            "//" +
            window.location.host +
            "/workingMenu/workingSession/" +
            data.data._id
          }
        >
          {moment(data.data.startTime).format("DD/MM/yyyy HH:mm") +
            "-" +
            moment(data.data.endTime).format("HH:mm")}
        </Link>
      </Box>
      <Box component="div" sx={{ fontWeight: "regular" }}>
        {`(${data.hour} hours - ${status?.label})`}
        {"\n"}
        {`Salary: ${numberWithCommas(data.salary, " đ")}`}
        {allowanceReward?.allowance
          ? " - Allowance: " + numberWithCommas(data.allowance, " đ")
          : ""}
        {` - Total: ${numberWithCommas(data.total, " đ")}`}
        {` - Payed: ${numberWithCommas(data.data.payed, " đ")}`}
        {data.data.note ? `\n - Note: ${data.data.note}` : ``}
      </Box>
    </Grid>
  );
}

function SummaryItem({ label, value }) {
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        {label + ": "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {value}
      </Box>
    </Grid>
  );
}

export default function WorkingSummary() {
  const { id } = useParams();
  console.log({ id });
  moment.locale("vi");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token, userInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [fetching, setFetching] = React.useState({
    setting: false,
    data: false,
  });
  const [allowanceReward, setAllowanceReward] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    async function doTask() {
      let alertContent;
      console.log("Getting Setting...");
      setProgress(0);
      setFetching((prevState) => ({ ...prevState, setting: true }));
      await getAllowanceReward(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            const item = res.data;
            console.log({ item });
            setAllowanceReward(item);
          }
        })
        .catch((err) => {
          console.log({ err });
          if (!mountedRef.current) return null;
          alertContent = err;
          // setFetching((prevState) => ({ ...prevState, setting: false }));
        });
      setProgress(50);
      setFetching((prevState) => ({ ...prevState, setting: false }));
      if (id && !alertContent) {
        console.log("Getting Working summary...");
        setFetching((prevState) => ({ ...prevState, data: true }));
        await getWorkingSummary(token, id)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const item = res.data;
              console.log({ item });
              setData(item);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, data: false }));
      }
      if (alertContent) {
        alert(alertContent);
      }
    }
    doTask();
  }, [id, token]);

  const workingStatus = WORKING_STATUS.filter((value, index, array) => {
    return data?.data.status === value.value;
  })[0];

  const workingSummaryItems = [
    {
      label: "Tình trạng (Status)",
      value: function (key) {
        return workingStatus?.label;
      },
    },
    {
      label: "Tổng số ca (Total Session)",
      value: function (key) {
        return data?.sessionCount[key];
      },
    },
    {
      label: "Tổng số giờ (Working hours)",
      value: function (key) {
        return data?.totalHours[key];
      },
    },
    {
      label: "Tổng lương theo giờ (Salary by hour)",
      value: function (key) {
        return numberWithCommas(data?.totalSalary[key], " đ");
      },
    },
    {
      label: "Tổng phụ cấp (Total allowance)",
      value: function (key) {
        return numberWithCommas(data?.totalAllowance[key], " đ");
      },
    },
    {
      label: "Tổng thưởng (Total Kpi Hour reward)",
      value: function (key) {
        return numberWithCommas(data?.totalKpiHourReward[key], " đ");
      },
    },
    {
      label: "Tổng (Total)",
      value: function (key) {
        return numberWithCommas(data?.total[key], " đ");
      },
    },
    {
      label: "Đã nhận (Payed)",
      value: function (key) {
        return numberWithCommas(data?.totalPayed[key], " đ");
      },
    },
    {
      label: "Còn lại (Remain)",
      value: function (key) {
        return numberWithCommas(data?.remain[key], " đ");
      },
    },
  ];
  for (let i = 0; i < workingSummaryItems.length; i++) {
    const item = workingSummaryItems[i];
    if (!allowanceReward?.allowance && item.label.includes("allowance")) {
      workingSummaryItems.splice(i, 1);
    }
  }
  for (let i = 0; i < workingSummaryItems.length; i++) {
    const item = workingSummaryItems[i];
    if (!allowanceReward?.reward && item.label.includes("reward")) {
      workingSummaryItems.splice(i, 1);
    }
  }
  const parentPath = React.useMemo(() => {
    if (userInfo?.role === "staff") {
      let pathArray = pathname.split("/");
      // console.log({pathArray});
      pathArray.splice(-2);
      // console.log({ pathArray });
      const path= arrayToString(pathArray, "/");
      // console.log({path});
      return path;
    } else {
      return null;
    }
  }, [pathname, userInfo?.role]);
  return (
    <>
      <Header title="Working Summary" parentPath={parentPath} />
      {fetching.setting || fetching.data ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <div className="content">
          {WORKING_SUMMARY_TYPE_NOW_ALL.map((type) => (
            <div key={type.value}>
              <Typography
                variant="h4"
                component="div"
                className="working-summary-header"
              >
                {`${type.name}`}
              </Typography>
              <Grid
                container
                direction="column"
                spacing={1}
                className="invoice"
              >
                {workingSummaryItems.map((item) => (
                  <SummaryItem
                    key={item.label}
                    label={item.label}
                    value={item.value(type.value)}
                  />
                ))}
              </Grid>
            </div>
          ))}
          {/* <Grid container direction="column" spacing={1} className="invoice">
            {workingSummaryItems.map((item) => (
              <SummaryItem
                key={item.label}
                label={item.label}
                value={item.value}
              />
            ))}
          </Grid> */}
          <Typography
            variant="h4"
            component="div"
            className="working-summary-header"
          >
            Sessions
          </Typography>
          <Grid container direction="column" spacing={1} className="invoice">
            {data?.sessionSummaries.all.length > 0 ? (
              data?.sessionSummaries.all.map((item) => (
                <WorkingSessionItem
                  key={item.data._id}
                  data={item}
                  allowanceReward={allowanceReward}
                />
              ))
            ) : (
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "bold" }}
              >
                No session in this working!
              </Box>
            )}
          </Grid>
        </div>
      )}
    </>
  );
}
