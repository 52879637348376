import { Button, Grid, Box, Chip } from "@mui/material";

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import moment from "moment";
import "moment/locale/vi";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import LinearProgress from "@mui/material/LinearProgress";

import "./StatisticalRoom.css";
import { getStatisticalRoomByDateRange } from "../../../../../api/api";
import DatePickerField from "../../../../../helpers/DatePickerField";
import LineChart from "../../../../../helpers/LineChart/LineChart";
import HighlightCard from "../../../../../helpers/HighlightCard/HighlightCard";
import BarChart from "../../../../../helpers/BarChart/BarChart";

export default function StatisticalRoom() {
  moment.locale("vi");
  // moment.utcOffset(0);
  // const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token, userInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [fetching, setFetching] = React.useState({
    data: false,
  });
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    const start = moment();
    start.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const end = moment();
    end.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    setStartDate(start.toISOString());
    setEndDate(end.toISOString());
  }, []);
  const handleSubmit = React.useCallback(
    async (ev) => {
      // setProgress(10);
      setFetching((prevState) => ({ ...prevState, data: true }));
      let alertContent;
      console.log({ startDate, endDate });
      await getStatisticalRoomByDateRange(token, {
        startDate,
        endDate,
      })
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            console.log({ res });
            setData(res.data);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setFetching((prevState) => ({ ...prevState, data: false }));
      if (alertContent) {
        alert(alertContent);
      }
    },
    [endDate, startDate, token]
  );

  return (
    <>
      <Header title="Statistical Room" />
      {fetching.data ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <DatePickerField
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  // console.log({newValue});
                  if (newValue) {
                    newValue.set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    });
                    setStartDate(newValue.toISOString());
                  }
                }}
              />
            </Grid>
            <Grid item>
              <DatePickerField
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  // console.log({newValue});
                  if (newValue) {
                    newValue.set({
                      hour: 23,
                      minute: 59,
                      second: 59,
                      millisecond: 999,
                    });
                    setEndDate(newValue.toISOString());
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
            {data ? (
              <>
                <Grid item>
                  <BarChart
                    title={"Rooms"}
                    xProps={{
                      title: "Room",
                      categories: data?.summary?.map(
                        (item) => item?.data?.name
                      ),
                    }}
                    datas={data?.summary?.map((item) => ({
                      ...item,
                      feeWithDiscount: item.feeWithDiscount / 100000,
                    }))}
                    types={[
                      {
                        name: "Both",
                        type: null,
                        color: null,
                        title: "Hour/Fee(*10K)",
                      },
                      {
                        name: "Hour",
                        type: "hour",
                        color: "#dc2127",
                        title: "Hour",
                      },
                      {
                        name: "Fee",
                        type: "feeWithDiscount",
                        color: "#51b749",
                        title: "Fee (*10K)",
                      },
                    ]}
                  />
                </Grid>
                <Grid item></Grid>
                <Grid item>
                  <LineChart
                    title={"Rooms"}
                    xProps={{
                      title: "Date",
                      categories: data?.summary[0]?.dates?.map((item) =>
                        moment(item.date).format("DD/MM/YY")
                      ),
                    }}
                    datas={data?.summary?.map((item) => ({
                      name: "Room " + item?.data?.name,
                      data: item?.dates,
                      color: item?.data?.color?.background,
                    }))}
                    types={[
                      { name: "Hour", type: "hour" },
                      { name: "Fee", type: "feeWithDiscount" },
                    ]}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs sm>
                      <HighlightCard
                        title="Total Room"
                        value={data?.summary?.length}
                        type="three"
                      />
                    </Grid>
                    <Grid item xs sm>
                      <HighlightCard
                        title="Total Hour"
                        value={data?.totalHour}
                        type="one"
                      />
                    </Grid>
                    <Grid item xs sm>
                      <HighlightCard
                        title="Total Fee"
                        value={data?.totalFeeWithDiscount}
                        type="two"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {data?.summary?.map((item) => {
              return (
                <>
                  <Grid item>
                    <LineChart
                      title={"Room " + item?.data?.name}
                      xProps={{
                        title: "Date",
                        categories: item?.dates?.map((item) =>
                          moment(item.date).format("DD/MM/YY")
                        ),
                      }}
                      datas={[
                        {
                          name: "Room " + item?.data?.name,
                          data: item?.dates,
                          color: item?.data?.color?.background,
                        },
                      ]}
                      types={[
                        { name: "Hour", type: "hour" },
                        { name: "Fee", type: "feeWithDiscount" },
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs sm>
                        <HighlightCard
                          title="Hour"
                          value={item?.hour}
                          type="one"
                        />
                      </Grid>
                      <Grid item xs sm>
                        <HighlightCard
                          title="Fee"
                          value={item?.feeWithDiscount}
                          type="two"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
}
