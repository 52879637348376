import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import {
  getAllowanceReward,
  getRemindTime,
  updateAllowanceReward,
  updateRemindTime,
} from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ALLOWANCE_REWARD_ACTIVE_OPTIONS,
  REMIND_ACTIVE_OPTIONS,
} from "../../../../../../constants";
import LinearProgressWithLabel from "../../../../../../helpers/LinearProgressWithLabel";

export default function AllowanceReward() {
  const [allowance, setAllowance] = React.useState(false);
  const [reward, setReward] = React.useState(false);
  const { token } = React.useContext(AuthContext);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    async function doTask() {
      console.log("Getting data...");
      let alertContent;
      setProgress(50);
      setFetching(true);
      await getAllowanceReward(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          console.log({ res });
          if (res && res.status === 200) {
            setAllowance(res.data.allowance);
            setReward(res.data.reward);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
    }
    doTask();
  }, [token]);
  const handleSubmit = React.useCallback(
    async (event) => {
      let alertContent;
      setProgress(50);
      setFetching(true);
      const newPayload = {
        allowance,
        reward,
      };
      console.log({ newPayload });
      await updateAllowanceReward(token, newPayload)
        .then((res) => {
          if (!mountedRef.current) return null;
          console.log({ res });
          if (res && res.status === 200) {
            alertContent = `Update success!`;
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
    },
    [allowance, reward, token]
  );
  return (
    <>
      <Header title="Allowance Reward" />
      {fetching ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Autocomplete
              className="allowance-options"
              options={ALLOWANCE_REWARD_ACTIVE_OPTIONS}
              value={allowance}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                setAllowance(newValue);
                // console.log({newValue});
              }}
              id="allowance-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Allowance" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              className="reward-options"
              options={ALLOWANCE_REWARD_ACTIVE_OPTIONS}
              value={reward}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                setReward(newValue);
                // console.log({newValue});
              }}
              id="reward-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Reward" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleSubmit(event)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
