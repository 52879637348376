import { Grid, TextField } from "@mui/material";
import React from "react";
import "./ListSearch.css";

export default function ListSearch({ handleChange, selectBox, ...props }) {
  return (
    <Grid container direction="row" spacing={1} className="section-search">
      <Grid item xs>
        <TextField
          {...props}
          label="Search"
          className="search-field"
          InputLabelProps={{ shrink: true }}
          fullWidth
          name="deposit"
          variant="outlined"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      </Grid>
      {selectBox ? (
        <Grid item xs={3}>
          {selectBox}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
