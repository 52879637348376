import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { Grid, MenuItem, Select, LinearProgress } from "@mui/material";
import moment from "moment";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import {
  deleteSpendingSession,
  getSpendingSessionAll,
  getSpendingSessionByDateRange,
  searchSpendingSession,
} from "../../../../../api/api";
import SpendingSessionItem from "./SpendingSessionItem";
import { SPENDING_SESSION_DATE_RANGES } from "../../../../../constants";
import { AuthContext } from "../../../../../context/AuthProvider";
import { AppContext } from "../../../../../context/AppProvider";
import ListSearch from "../../../../Header/ListSearch";
import ListHeader from "../../../../Header/ListHeader";
import Header from "../../../../Header/Header";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";

export default function SpendingSession() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const { searchSpendingSessionMem, setSearchSpendingSessionMem } =
    React.useContext(AppContext);
  const { dateRangeSpendingSessionMem, setDateRangeSpendingSessionMem } =
    React.useContext(AppContext);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const updateDatas = React.useCallback((newDatas, setInit = true) => {
    // console.log({ res });
    if (userInfo?.role !== "admin") {
      newDatas = newDatas.filter((value, index, array) => {
        return value.workingSessionId?.userId?._id === userInfo?._id;
      });
    }
    newDatas.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    console.log({ newDatas });
    if (setInit) {
      setInitDatas(newDatas);
    }
    setDatas(newDatas);
    // console.log({ newDatas });
  }, [userInfo?._id, userInfo?.role]);

  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchSpendingSession(token, { search: value })
        .then((res) => {
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      debounceFetcher(searchText);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching(true);
      let alertContent;
      const posStatus = await deleteSpendingSession(token, item)
        .then((res) => {
          // successCallback(res);
          return res.status;
        })
        .catch((err) => {
          console.log({ err });
          // errorCallback(err);
          return err.response.status;
        });
      setProgress(50);
      if (posStatus === 200) {
        const newDatas = datas.filter(function (value, index, arr) {
          return value._id !== item._id;
        });
        setDatas(newDatas);
        alertContent = `Delete success!`;
      } else {
        alertContent = `Delete failed!`;
      }
      setProgress(100);
      setFetching(false);
      if (alertContent) {
        alert(alertContent);
      }
    },
    [token, datas]
  );
  React.useEffect(() => {
    async function abc() {
      if (searchSpendingSessionMem) {
        handleSearch(searchSpendingSessionMem);
      } else {
        let alertContent;
        console.log("Getting spending sessions...");
        setProgress(50);
        setFetching(true);
        if (dateRangeSpendingSessionMem >= 0) {
          const startDate = moment()
            .subtract(dateRangeSpendingSessionMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangeSpendingSessionMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getSpendingSessionByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getSpendingSessionAll(token)
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    abc();
  }, [
    dateRangeSpendingSessionMem,
    handleSearch,
    searchSpendingSessionMem,
    token,
    updateDatas,
  ]);
  let rows = [];
  for (var i = 0; i < datas.length; i++) {
    const item = datas[i];
    rows.push(
      <Grid item key={item._id}>
        <SpendingSessionItem
          key={item._id}
          number={i + 1}
          item={item}
          deleteItem={deleteItem}
        />
      </Grid>
    );
  }
  const rightButton = {
    icon: <AddIcon fontSize="inherit" />,
    callback: function (event) {
      navigate(pathname + "/new");
    },
  };
  const selectBox = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={dateRangeSpendingSessionMem}
      // label="Date Range"
      fullWidth
      onChange={(event) => {
        setDateRangeSpendingSessionMem(event.target.value);
      }}
    >
      {SPENDING_SESSION_DATE_RANGES.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      <Header
        title="Spending Sessions"
        rightButton={userInfo?.role === "admin" ? rightButton : null}
      />
      <ListSearch
        handleChange={(searchText) => {
          if (searchText) {
            handleSearch(searchText);
          } else {
            setDatas(initDatas);
          }
          setSearchSpendingSessionMem(searchText);
        }}
        selectBox={selectBox}
        placeholder="WorkingId | Session | User"
        value={searchSpendingSessionMem}
      />
      <ListHeader
        titles={[
          { width: 1, align: "center", title: "No." },
          { width: 4, align: "left", title: "Time" },
          { width: 1, align: "left", title: "Session" },
          { width: 2, align: "right", title: "User" },
          { width: 2, align: "center", title: "Total" },
          { width: 2, align: "center", title: "Action" },
        ]}
      />
      <div className="list-item">
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <Grid container direction="column" spacing={0}>
            {rows?.length > 0 ? (
              rows
            ) : (
              <Grid item>
                <Box component="div" className="center-text">
                  No Spending Session found!
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
