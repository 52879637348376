import React from "react";
import Button from "@mui/material/Button";

import "./Dashboard.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import LogoutIcon from "@mui/icons-material/Logout";
import { LOCAL_STORAGE_KEY_TOKEN } from "../../constants";
import { logout } from "../Login/Login";
import { Box, Container, Grid } from "@mui/material";
import { DASHBOARD_MENU, DASHBOARD_MENU_STAFF } from "./constants";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Booking from "./Items/Booking/Booking";
import BookingSummary from "./Items/Booking/BookingSummary";
import Class from "./Items/Class/Class";
import EditClass from "./Items/Class/EditClass";
import InvoiceClass from "./Items/Class/InvoiceClass";
import AddonClass from "./Items/Class/AddonClass";
import EditVat from "./Items/Vat/EditVat";
import Customer from "./Items/Customer/Customer";
import User from "./Items/User/User";
import Room from "./Items/Room/Room";
import Settings from "./Items/Settings/Settings";
import AddonMenu from "./Items/Addon/AddonMenu";
import Vat from "./Items/Vat/Vat";
import Pos from "./Items/Pos/Pos";
import EditPos from "./Items/Pos/EditPos";
import SpendingMenu from "./Items/Spending/SpendingMenu";
import WorkingMenu from "./Items/Working/WorkingMenu";
import EditBooking from "./Items/Booking/EditBooking";
import { AuthContext } from "../../context/AuthProvider";
import StatisticalMenu from "./Items/Statistical/StatisticalMenu";
import { APP_VERSION } from "../../context/AppProvider";
import PhotoViewer from "../PhotoViewer/PhotoViewer";
// import {version} from '../../../package.json';
export default function Dashboard() {
  //   console.log("Dashboard");
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  // console.log({ paths });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const handleItemClick = React.useCallback(
    (event, type) => {
      console.log({ type });
      navigate("/" + type);
    },
    [navigate]
  );
  const rightButton = {
    icon: <LogoutIcon fontSize="inherit" />,
    callback: function (event) {
      const result = window.confirm("Are you sure to logout?");
      if (result) {
        logout(navigate, null);
      }
    },
  };
  if (paths.length === 2 && paths[1] === "") {
    let menu = DASHBOARD_MENU;
    if (userInfo?.role === "staff") {
      menu = DASHBOARD_MENU_STAFF;
    }
    return (
      <>
        <Header title="The bib Space" rightButton={rightButton} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          className="dashboard-user-info"
        >
          <Grid item>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "regular" }}
            >
              {"Version: "}&nbsp;
            </Box>
          </Grid>
          <Grid item>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "bold" }}
            >
              {APP_VERSION}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          className="dashboard-user-info"
        >
          <Grid item>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "regular" }}
            >
              {"Hello!"}&nbsp;
            </Box>
          </Grid>
          <Grid item>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "bold" }}
            >
              {userInfo?.username}
            </Box>
          </Grid>
          <Grid item>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "regular" }}
            >
              &nbsp;{`(${userInfo?.role})`}
            </Box>
          </Grid>
        </Grid>
        <Container maxWidth="xs" className="dashboard-container">
          {menu.map((item) => (
            <Button
              variant="contained"
              key={item.type}
              startIcon={item.icon}
              fullWidth
              className="btn-item-dashboard"
              size="large"
              color="primary"
              onClick={(event) => handleItemClick(event, item.type)}
            >
              {item.text}
            </Button>
          ))}
        </Container>
      </>
    );
  } else {
    // console.log("sub");
    return (
      <Routes>
        <Route element={<Booking />} path="/booking" />
        <Route element={<EditBooking />} path="/booking/:id" />
        <Route element={<EditBooking />} exact path="/booking/new" />
        <Route element={<BookingSummary />} path="/booking/:id/summary" />
        <Route element={<EditVat />} path="/booking/:bookingId/vat" />
        <Route element={<Class />} exact path="/class" />
        <Route element={<EditClass />} path="/class/:id" />
        <Route element={<InvoiceClass />} path="/class/:id/invoice" />
        {/* <Route element={<AddonClass />} path="/class/:id/addon" /> */}
        <Route element={<EditVat />} path="/class/:classId/vat" />
        <Route element={<EditPos />} path="/class/:classId/pos" />
        <Route element={<EditClass />} exact path="/class/new" />
        <Route element={<Customer />} path="/customer" />
        <Route element={<Customer />} path="/customer/:id" />
        <Route element={<Customer />} exact path="/customer/new" />
        <Route
          element={<Customer />}
          exact path="/customer/:id/photo"
        />
        <Route element={<User />} exact path="/user" />
        <Route element={<User />} path="/user/:id" />
        <Route element={<User />} exact path="/user/new" />
        <Route element={<User />} path="/user/:id/updatePassword" />
        <Route element={<Room />} exact path="/room" />
        <Route element={<Room />} path="/room/:id" />
        <Route element={<Room />} exact path="/room/new" />
        <Route element={<Settings />} exact path="/settings" />
        <Route element={<Settings />} path="/settings/*" />
        <Route element={<AddonMenu />} exact path="/addonMenu" />
        <Route element={<AddonMenu />} path="/addonMenu/*" />
        <Route element={<SpendingMenu />} exact path="/spendingMenu" />
        <Route element={<SpendingMenu />} path="/spendingMenu/*" />
        <Route element={<WorkingMenu />} exact path="/workingMenu" />
        <Route element={<WorkingMenu />} path="/workingMenu/*" />
        <Route element={<Vat />} exact path="/vat" />
        <Route element={<Vat />} path="/vat/:id" />
        <Route element={<Vat />} exact path="/vat/new" />
        <Route element={<Pos />} exact path="/pos" />
        <Route element={<EditPos />} path="/pos/:id" />
        <Route element={<EditPos />} exact path="/pos/new" />
        <Route element={<StatisticalMenu />} exact path="/statisticalMenu" />
        <Route element={<StatisticalMenu />} path="/statisticalMenu/*" />
      </Routes>
    );
  }
}
