import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { getRemindTime, updateRemindTime } from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
import { REMIND_ACTIVE_OPTIONS } from "../../../../../../constants";

export default function RemindTime() {
  const [active, setActive] = React.useState(false);
  const [hour, setHour] = React.useState(0);
  const [minute, setMinute] = React.useState(0);
  const [second, setSecond] = React.useState(0);
  const { token } = React.useContext(AuthContext);
  const updateFetch = React.useCallback((res) => {
    console.log({ res });
    if (res && res.status === 200) {
      setActive(res.data.active);
      setHour(res.data.hour);
      setMinute(res.data.minute);
      setSecond(res.data.second);
    }
  }, []);
  React.useEffect(() => {
    getRemindTime(token)
      .then((res) => {
        updateFetch(res);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token, updateFetch]);
  const handleSubmit = React.useCallback(
    (event) => {
      const newPayload = {
        hour: parseInt(hour),
        minute: parseInt(minute),
        second: parseInt(second),
        active: active === "true",
      };
      console.log({ newPayload });
      updateRemindTime(token, newPayload)
        .then((res) => {
          alert("Update Successfully!");
          updateFetch(res);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    [token, hour, minute, second, updateFetch, active]
  );
  return (
    <>
      <Header title="Remind Time" />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Autocomplete
            className="remind-active-options"
            options={REMIND_ACTIVE_OPTIONS}
            value={active}
            getOptionLabel={(option) => option.toString()}
            onChange={(event, newValue) => {
              setActive(newValue);
              // console.log({newValue});
            }}
            id="remind-active-options"
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Active" variant="outlined" />
            )}
          />
          {/* <TextField
            id="outlined-basic"
            label="Active"
            fullWidth
            variant="outlined"
            value={active}
            onChange={(e) => setActive(e.target.value)}
          /> */}
        </Grid>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Hour"
            fullWidth
            variant="outlined"
            value={hour}
            onChange={(e) => setHour(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Minute"
            fullWidth
            variant="outlined"
            value={minute}
            onChange={(e) => setMinute(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Second"
            fullWidth
            variant="outlined"
            value={second}
            onChange={(e) => setSecond(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
