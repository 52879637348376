import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  deleteSpending,
  deleteSpendingCate,
  getSpendingCateAll,
  searchSpending,
} from "../../../../../api/api";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import AddIcon from "@mui/icons-material/Add";
import ListSearch from "../../../../Header/ListSearch";
import ListHeader from "../../../../Header/ListHeader";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import EditSpendingCate from "./EditSpendingCate";
import SpendingCateItem from "./SpendingCateItem";

export default function SpendingCate() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  console.log({ pathArray });
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const [fetching, setFetching] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const updateDatas = React.useCallback(
    (data, newItem, updatedItem, deletedItem) => {
      // newDatas.sort(function (a, b) {
      //   return new Date(b.createdAt) - new Date(a.createdAt);
      // });
      if (data) {
        setInitDatas(data);
        setDatas(data);
      } else if (newItem) {
        setInitDatas((prevState) => [...prevState, newItem]);
        setDatas((prevState) => [...prevState, newItem]);
      } else if (updatedItem) {
        setInitDatas((prevState) =>
          prevState.map((item) => {
            return item._id === updatedItem._id ? updatedItem : item;
          })
        );
        setDatas((prevState) =>
          prevState.map((item) => {
            return item._id === updatedItem._id ? updatedItem : item;
          })
        );
      } else if (deletedItem) {
        setInitDatas((prevState) => {
          return prevState.filter((value, index, array) => {
            return value._id !== deletedItem._id;
          });
        });
        setDatas((prevState) => {
          return prevState.filter((value, index, array) => {
            return value._id !== deletedItem._id;
          });
        });
      }
    },
    []
  );
  const handleSearch = React.useCallback(
    (search) => {
      // console.log({ search });
      setSearch(search);
      if (search) {
        // console.log("filter");
        const newDatas = initDatas.filter(function (value, index, arr) {
          return value.name.toLowerCase().includes(search.toLowerCase());
        });
        setDatas(newDatas);
      } else {
        // console.log("not filter");
        setDatas(initDatas);
      }
      // navigate(pathname + "/" + search.id);
    },
    [initDatas]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(0);
      setFetching(false);
      const spendings = await searchSpending(token, {
        search: item._id,
      }).then((res) => {
        if (res && res.status === 200) {
          return res.data;
        }
      });
      console.log({ spendings });
      let deletedCount = 0;
      let errorCount = 0;
      for (let i = 0; i < spendings.length; i++) {
        const spending = spendings[i];
        console.log({ spending });
        setProgress((i / spendings.length) * 100);
        const resCode = await deleteSpending(token, spending)
          .then((res) => {
            return res.status;
          })
          .catch((err) => {
            console.log({ err });
            return err.status;
          });
        if (resCode === 200) {
          deletedCount += 1;
        } else {
          errorCount += 1;
        }
      }
      let alertContent;
      if (errorCount === 0) {
        await deleteSpendingCate(token, item)
          .then(async (res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              updateDatas(null, null, null, item);
              alertContent = `Delete Cate success! And (${deletedCount}) spending(s) too!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      } else {
        alertContent = `Delete Cate failed! Success: (${deletedCount}) spending(s)! Error: (${errorCount}) spending(s)`;
      }
      setProgress(100);
      alert(alertContent);
      setFetching(false);
    },
    [token, updateDatas]
  );
  React.useEffect(() => {
    async function abc() {
      console.log("Getting data...");
      let alertContent;
      setProgress(50);
      setFetching(true);
      await getSpendingCateAll(token)
        .then((res) => {
          console.log({ res });
          if (res && res.status === 200) {
            if (!mountedRef.current) return null;
            updateDatas(res.data, null, null, null);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
    }
    abc();
  }, [token, updateDatas]);
  const action = pathArray.pop();
  console.log({ action });
  if (action === "new") {
    return <EditSpendingCate updateDatas={updateDatas} />;
  } else if (id) {
    return (
      <EditSpendingCate itemId={id} datas={datas} updateDatas={updateDatas} />
    );
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      //   if (
      //     userInfo?.role === "admin" ||
      //     (userInfo?.role === "staff" && userInfo?._id === item._id)
      //   ) {
      rows.push(
        <Grid item key={item._id}>
          <SpendingCateItem
            number={i + 1}
            item={item}
            deleteItem={deleteItem}
          />
        </Grid>
      );
      //   }
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header title="Spending Category" rightButton={rightButton} />
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <>
            <ListSearch
              value={search}
              placeholder="Name..."
              handleChange={handleSearch}
            />
            <ListHeader
              titles={[
                { width: 1, align: "center", title: "No." },
                { width: 9, align: "center", title: "Name" },
                { width: 2, align: "center", title: "Action" },
              ]}
            />
            <Grid container direction="column" spacing={0}>
              {rows}
            </Grid>
          </>
        )}
      </>
    );
  }
}
