import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { arrayToString } from "../../../../../../helpers/stringArray";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router";

export default function DiscountRulesItem({ number, rule, deleteItem }) {
  const { pathname } = useLocation();
  //   console.log({ pathname });
  // const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();
  const payAllString = arrayToString(rule.payAll, ",");
  const percent = rule.percent * 100 + "%";
  const handleEditClick = React.useCallback(
    (event) => {
      navigate(pathname + "/" + rule._id);
    },
    [navigate, pathname, rule._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(rule);
      }
    },
    [deleteItem, rule, number]
  );
  return (
    <>
      <Grid container spacing={0} direction="row" className="item-list">
        <Grid item xs={1}>
          <Typography align="center">{number + "."}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography align="center">{rule.hours}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center">{rule.continuous + ""}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{payAllString}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{percent}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(event) => handleEditClick(event)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
