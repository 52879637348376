import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { updateDiscountRules } from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
import {
  DISCOUNT_RULE_CONTINUOUS_OPTIONS,
  DISCOUNT_RULE_PAY_ALL_OPTIONS,
} from "../../../../../../constants";

export default function EditDiscountRule({ ruleId, rules, setRules }) {
  const { token } = React.useContext(AuthContext);
  const [hours, setHours] = React.useState(0);
  const [continuous, setContinuous] = React.useState(false);
  const [payAll, setPayAll] = React.useState(DISCOUNT_RULE_PAY_ALL_OPTIONS[0]);
  const [percent, setPercent] = React.useState(0.0);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (ruleId) {
      for (let i = 0; i < rules.length; i++) {
        if (rules[i]._id === ruleId) {
          const rule = rules[i];
          setHours(rule.hours);
          setContinuous(rule.continuous);
          setPayAll(rule.payAll.toString());
          setPercent(rule.percent);
          setData(rule);
          break;
        }
      }
    }
  }, [ruleId, rules]);

  const updateRuleSuccess = React.useCallback(
    (res) => {
      console.log({ res });
      if (res && res.status === 200) {
        alert(ruleId ? "Update Successfully!" : "Create Successfully!");
        setRules(res.data);
      }
    },
    [setRules, ruleId]
  );
  const handleSubmit = React.useCallback(
    (event) => {
      let newRules;
      if (ruleId) {
        const newRule = {
          _id: data?._id,
          hours: parseInt(hours),
          continuous,
          payAll: payAll.split(",").map((item) => item === "true"),
          percent: parseFloat(percent),
        };
        console.log({ newRule });
        newRules = rules.map((item) => {
          if (item._id === newRule._id) {
            return newRule;
          } else {
            return item;
          }
        });
      } else {
        const newRule = {
          hours: parseInt(hours),
          continuous: continuous === "true",
          payAll: payAll.split(",").map((item) => item === "true"),
          percent: parseFloat(percent),
        };
        console.log({ newRule });
        newRules = rules;
        newRules.push(newRule);
      }
      updateDiscountRules(token, newRules)
        .then((res) => {
          updateRuleSuccess(res);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    [
      ruleId,
      token,
      data?._id,
      hours,
      continuous,
      payAll,
      percent,
      rules,
      updateRuleSuccess,
    ]
  );
  return (
    <>
      <Header title={ruleId ? "Edit Discount Rule" : "New Discount Rule"} />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            label="Hours"
            fullWidth
            variant="outlined"
            value={hours}
            onChange={(e) => {
              setHours(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="discount-rule-continuous-options"
            options={DISCOUNT_RULE_CONTINUOUS_OPTIONS}
            value={continuous}
            getOptionLabel={(option) => option.toString()}
            onChange={(event, newValue) => {
              setContinuous(newValue);
              console.log({ newValue });
            }}
            id="discount-rule-continuous-options"
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Continuous" variant="outlined" />
            )}
          />
          {/* <TextField
            label="Continuous"
            fullWidth
            variant="outlined"
            value={continuous}
            onChange={(e) => {
              setContinuous(e.target.value);
            }}
          /> */}
        </Grid>
        <Grid item>
          <Autocomplete
            className="discount-rule-pay-all-options"
            options={DISCOUNT_RULE_PAY_ALL_OPTIONS}
            value={payAll}
            getOptionLabel={(option) => option.toString()}
            onChange={(event, newValue) => {
              setPayAll(newValue);
              // console.log({newValue});
            }}
            id="discount-rule-pay-all-options"
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Pay all" variant="outlined" />
            )}
          />
          {/* <TextField
            label="Pay All"
            fullWidth
            variant="outlined"
            value={payAll}
            onChange={(e) => {
              setPayAll(e.target.value);
            }}
          /> */}
        </Grid>
        <Grid item>
          <TextField
            label="Percent"
            fullWidth
            variant="outlined"
            value={percent}
            onChange={(e) => {
              setPercent(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
