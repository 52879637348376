import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router";
import "./EditAddonSession.css";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createAddonSession,
  getAddonAll,
  getAddonAllGroupCate,
  getAddonSessionById,
  getAddonSessionLatest,
  searchAddonSession,
  updateAddonSession,
  updateWorkingSession,
} from "../../../../../api/api";
import {
  PAYMENT_TYPE_OPTIONS,
  ADDON_SESSION_STATUS,
} from "../../../../../constants";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import { numberWithCommas } from "../../../../../helpers/stringDate";
import { generateRandomIntegerInRange } from "../../../../../helpers/general";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import { updateInventory } from "../../Pos/EditPos";

class AddonInSessionModel {
  constructor(addonId, name, quantity, price, priceIn) {
    this.addonId = addonId;
    this.name = name;
    this.quantity = quantity;
    this.price = price;
    this.priceIn = priceIn;
  }
}

function AddonItem({ item, handleItemClick }) {
  // console.log({item});
  return (
    <Button
      variant="contained"
      className="btn-addon-item"
      size="medium"
      color="primary"
      onClick={(event) => handleItemClick(event, item)}
    >
      {item?.name}
    </Button>
  );
}

function AddonInSessionItem({ item, handleReduce, handleRemove }) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        {item.name + " (" + numberWithCommas(item.priceIn, " đ)")}
      </Grid>
      <Grid item className="addon-session-quantity">
        x {item.quantity}
      </Grid>
      <Grid item xs></Grid>
      <Grid item>
        <IconButton
          aria-label="reduce"
          size="medium"
          onClick={(event) => handleReduce(event, item)}
        >
          <RemoveCircleOutlineIcon
            className={"addon-session-list-icon"}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="remove"
          size="medium"
          onClick={(event) => handleRemove(event, item)}
        >
          <DeleteIcon
            className={"addon-session-list-icon"}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default function EditAddonSession() {
  const { id, workingSessionId } = useParams();
  console.log({ id, workingSessionId });
  moment.locale("vi");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [addons, setAddons] = React.useState([]);
  const [addonsInSession, setAddonsInSession] = React.useState([]);
  const [addonsInSessionQuantity, setAddonsInSessionQuantity] =
    React.useState(0);
  const [data, setData] = React.useState(null);
  const [status, setStatus] = React.useState(ADDON_SESSION_STATUS[0].value);
  const [total, setTotal] = React.useState(0);
  const [paymentType, setPaymentType] = React.useState(
    PAYMENT_TYPE_OPTIONS[0].value
  );
  const [fetching, setFetching] = React.useState({
    addons: false,
    session: false,
  });
  const [progress, setProgress] = React.useState(0);
  const [returnParent, setReturnParent] = React.useState(false);
  // console.log({ addonsPos });
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  React.useEffect(() => {
    async function doTask() {
      setProgress(0);
      let alertContent;
      console.log("Getting addons...");
      setFetching((prevState) => ({ ...prevState, addons: true }));
      await getAddonAllGroupCate(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            const cateList = res.data;
            console.log({ cateList });
            cateList.sort(function (a, b) {
              if (a.category.name < b.category.name) {
                return -1;
              }
              if (a.category.name > b.category.name) {
                return 1;
              }
              return 0;
            });
            for (let i = 0; i < cateList.length; i++) {
              const addonList = cateList[i]?.addons;
              addonList.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            }
            setAddons(cateList);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(50);
      setFetching((prevState) => ({ ...prevState, addons: false }));
      if (id) {
        console.log("Getting addon session by id...");
        setFetching((prevState) => ({ ...prevState, session: true }));
        await getAddonSessionById(token, id)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const sessionData = res.data;
              console.log({ sessionData });
              setData(sessionData);
              setAddonsInSession(sessionData.addons);
              setStatus(sessionData.status);
              setPaymentType(sessionData.paymentType);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, session: false }));
      } else if (workingSessionId) {
        console.log("Getting addon session by working session...");
        setFetching((prevState) => ({ ...prevState, session: true }));
        await searchAddonSession(token, { search: workingSessionId })
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const sessionDatas = res.data;
              console.log({ sessionDatas });
              if (sessionDatas.length > 0) {
                setData(sessionDatas[0]);
                setAddonsInSession(sessionDatas[0].addons);
                setStatus(sessionDatas[0].status);
                setPaymentType(sessionDatas[0].paymentType);
              }
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, session: false }));
      }
      if (alertContent) {
        alert(alertContent);
      }
    }
    doTask();
  }, [id, token, workingSessionId]);

  React.useEffect(() => {
    let sum = 0;
    let quantity = 0;
    addonsInSession.forEach((item) => {
      sum += item.quantity * item.priceIn;
      quantity += item.quantity;
    });
    setTotal(sum);
    setAddonsInSessionQuantity(quantity);
  }, [addonsInSession]);

  const getAddonPrice = React.useCallback((addon) => {
    if (addon.price === 0) {
      let price = prompt(`Please enter price for "${addon.name}"`, 0);
      console.log({ price });
      if (price === null) {
        return 0;
      } else {
        return parseInt(price);
      }
    } else {
      return addon.price;
    }
  }, []);

  const handleAddonClick = React.useCallback(
    (event, addon) => {
      addon[`price`] = 0;
      console.log({ addon });
      let addonInSessionIndex = -1;
      for (let i = 0; i < addonsInSession.length; i++) {
        const item = addonsInSession[i];
        if (item.addonId._id === addon._id) {
          addonInSessionIndex = i;
          break;
        }
      }
      if (addonInSessionIndex >= 0) {
        //update addon's quantity already had in class
        //   addonsClass[addonClassIndex].quantity += 1;
        setAddonsInSession((prevState) =>
          prevState.map((item) => {
            if (item.addonId._id === addon._id) {
              return new AddonInSessionModel(
                item.addonId,
                item.name,
                item.quantity + 1,
                item.price,
                item.priceIn
              );
            } else {
              return item;
            }
          })
        );
      } else {
        //add new spending to session
        setAddonsInSession((prevState) => [
          ...prevState,
          new AddonInSessionModel(
            addon,
            addon.name,
            1,
            0,
            getAddonPrice(addon)
          ),
        ]);
      }
    },
    [addonsInSession, getAddonPrice]
  );

  const handleSubmit = React.useCallback(
    async (ev) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, session: true }));
      let alertContent = "";
      let newItem = {
        // classId: ,
        // customerId: data?.customerId?._id,
        addons: addonsInSession.map((item) => ({
          addonId: item.addonId._id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          priceIn: item.priceIn,
        })),
        total,
        status,
        paymentType,
        lastUpdatedBy: userInfo?._id,
      };
      let newSession;
      if (data) {
        newItem["_id"] = data?._id;
        // newItem["classId"] = data?.classId?._id;
        console.log({ newItem });
        newSession = await updateAddonSession(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent += `\nUpdate Addon Session [OK]`;
              return res.data;
            } else {
              alertContent += `\nUpdate Addon Session [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent += `\nUpdate Addon Session [Error: ${err}]`;
            return null;
          });
      } else {
        const addonSessionLatest = await getAddonSessionLatest(token)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              console.log({ addonSessionLatest: res.data });
              alertContent += `\nGet Addon Session Latest [OK]`;
              return res.data;
            } else {
              alertContent += `\nGet Addon Session Latest [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent += `\nGet Addon Session Latest [Error: ${err}]`;
            return null;
          });
        setProgress(50);
        // console.log({ posLatest });
        if (addonSessionLatest && addonSessionLatest.no) {
          newItem["no"] = `#${
            parseInt(addonSessionLatest.no.substring(1)) + 1
          }-${generateRandomIntegerInRange(10, 99)}`;
        } else {
          newItem["no"] = `#1-${generateRandomIntegerInRange(10, 99)}`;
        }
        newItem["workingSessionId"] = workingSessionId;
        console.log({ newItem });
        newSession = await createAddonSession(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              console.log({ newPos: res.data });
              alertContent += `\nCreate Addon Session [OK]`;
              return res.data;
            } else {
              alertContent += `\nCreate Addon Session [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent += `\nCreate Addon Session [Error: ${err}]`;
            return null;
          });
        setProgress(80);
        if (workingSessionId && newSession) {
          newItem = {
            _id: workingSessionId,
            addonSessionId: newSession._id,
            lastUpdatedBy: userInfo._id,
          };
          console.log({ newItem });
          await updateWorkingSession(token, newItem)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent += "\nUpdate Working Session [OK]!";
              } else {
                alertContent += `\nUpdate Working Session [Error: ${res.status}]`;
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent += `\nUpdate Working Session [Error: ${err}]`;
            });
        }
      }
      if (newSession) {
        alertContent +=
          "\n" +
          (await updateInventory(
            token,
            mountedRef,
            newItem?.addons,
            data?.addons.map((addon) => ({
              ...addon,
              addonId: addon.addonId._id,
            }))
          ));
      }
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, session: false }));
      if (alertContent) {
        alert(alertContent);
      }
      setReturnParent(true);
    },
    [
      addonsInSession,
      data,
      paymentType,
      status,
      token,
      total,
      userInfo._id,
      workingSessionId,
    ]
  );

  const handleRemoveAddonInSession = React.useCallback(
    (event, addonInSession) => {
      setAddonsInSession((prevState) =>
        prevState.filter((value, index, array) => {
          return value.addonId._id !== addonInSession.addonId._id;
        })
      );
    },
    []
  );

  const handleReduceQuantity = React.useCallback(
    (event, addonInSession) => {
      let remove = false;
      addonsInSession.forEach((item) => {
        if (
          item.addonId._id === addonInSession.addonId._id &&
          item.quantity === 1
        ) {
          remove = true;
          return;
        }
      });
      if (remove) {
        handleRemoveAddonInSession(event, addonInSession);
      } else {
        setAddonsInSession((prevState) =>
          prevState.map((item) => {
            if (item.addonId._id === addonInSession.addonId._id) {
              return new AddonInSessionModel(
                item.addonId,
                item.name,
                item.quantity - 1,
                item.price,
                item.priceIn
              );
            } else {
              return item;
            }
          })
        );
      }
    },
    [addonsInSession, handleRemoveAddonInSession]
  );
  return (
    <>
      <Header
        title={(data ? "Edit" : "New") + " Addon-Import"}
        triggerParentClick={returnParent}
      />
      {fetching.addons || fetching.session ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <div className="content">
          <Grid container spacing={3} direction="column">
            <Grid
              item
              className={data?.workingSessionId?._id ? "" : "no-display"}
            >
              <TextField
                label="Working Session Id"
                fullWidth
                disabled
                variant="outlined"
                value={data?.workingSessionId?._id}
              />
            </Grid>
            <Grid
              item
              className={
                data?.workingSessionId?.userId?._id ? "" : "no-display"
              }
            >
              <TextField
                label="User"
                fullWidth
                disabled
                variant="outlined"
                value={data?.workingSessionId?.userId?.username}
              />
            </Grid>
            <Grid
              item
              className={data?._id && workingSessionId ? "" : "no-display"}
            >
              <TextField
                label="Addon Session Id"
                fullWidth
                disabled
                variant="outlined"
                value={data?._id}
              />
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-addon-session-status-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="select-addon-session-status-label"
                    id="select-addon-session-status"
                    value={status}
                    fullWidth
                    label="Status"
                    onChange={(event) => {
                      const state = event.target.value;
                      setStatus(state);
                    }}
                  >
                    {ADDON_SESSION_STATUS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-addon-session-payment-type-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    labelId="select-addon-session-payment-type-label"
                    id="select-addon-session-payment"
                    value={paymentType}
                    fullWidth
                    label="Payment Type"
                    onChange={(event) => {
                      setPaymentType(event.target.value);
                    }}
                  >
                    {PAYMENT_TYPE_OPTIONS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item>
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "regular" }}
              >
                {"Total: "}
              </Box>
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "bold" }}
              >
                {numberWithCommas(total, " đ")}
              </Box>
            </Grid> */}
            <Grid item>
              <div className="addon-session-list">
                <Grid container direction="column" spacing={1}>
                  {addonsInSession.map((item) => (
                    <Grid item key={item.addonId._id}>
                      <AddonInSessionItem
                        item={item}
                        handleReduce={handleReduceQuantity}
                        handleRemove={handleRemoveAddonInSession}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
            {/* <Grid item>
              <div className="spending-list">
                <Grid container direction="row" spacing={1}>
                  {addons.map((item) => {
                    return (
                      <Grid item key={item._id}>
                        <AddonItem
                          item={item}
                          handleItemClick={handleAddonClick}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid> */}
            <Grid item className={!data ? "no-display" : ""}>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          {addons.map((cateItem) => {
            // console.log({cateItem});
            return (
              <Grid
                item
                key={cateItem?.category?._id}
                className="addon-list-wrapper"
              >
                <Typography variant="h6" component="div">
                  {cateItem?.category?.name}
                </Typography>
                <div className="addon-list">
                  <Grid container direction="row" spacing={1}>
                    {cateItem?.addons?.map((addonItem) => {
                      return (
                        <Grid item key={addonItem._id}>
                          <AddonItem
                            item={addonItem}
                            handleItemClick={handleAddonClick}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
            );
          })}
          <div className="addon-total">
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "regular" }}
            >
              {"Total: "}
            </Box>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "bold" }}
            >
              {numberWithCommas(total, " đ") +
                ` (${addonsInSessionQuantity} items)`}
            </Box>
          </div>
        </div>
      )}
    </>
  );
}
