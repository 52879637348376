import { alertTitleClasses, Grid, Typography } from "@mui/material";
import React from "react";

export default function ListHeader({ titles }) {
  let body = [];
  titles.forEach((element) => {
    body.push(
      <Grid item xs={element.width} key={element.title}>
        <Typography align={element.align}>{element.title}</Typography>
      </Grid>
    );
  });
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      className="item-list list-item-header"
    >
      {body}
    </Grid>
  );
}
