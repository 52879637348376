export const arrayToString = (arrayIn, split) => {
    let stringOut = "";
    for (let i = 0; i < arrayIn.length; i++) {
      const item = arrayIn[i];
      stringOut += item;
      if (i < arrayIn.length - 1) {
        stringOut += split;
      }
    }
    return stringOut;
}