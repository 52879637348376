import React from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../context/AuthProvider";
import moment from "moment";
import { createRoom, updateRoom } from "../../../../api/api";
import Header from "../../../Header/Header";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  COLOR_OPTIONS,
  CONTACT_OPTIONS,
  CUSTOMER_FROM_OPTIONS,
  GENDER_OPTIONS,
  LANGUAGE_OPTIONS,
} from "../../../../constants";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";

export default function EditRoom({ itemId, datas, setDatas }) {
  console.log({ itemId });
  console.log({ datas });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [pricePerHour, setPricePerHour] = React.useState(0);
  const [pricePer4HourWT, setPricePer4HourWT] = React.useState(0);
  const [pricePer8HourWT, setPricePer8HourWT] = React.useState(0);
  const [capacity, setCapacity] = React.useState(0);
  const [calendarId, setCalendarId] = React.useState("");
  const [colorCalendar, setColorCalendar] = React.useState(null);
  const [colorCalendarPending, setColorCalendarPending] = React.useState(null);
  const [colorCalendarCancel, setColorCalendarCancel] = React.useState(null);
  const [color, setColor] = React.useState(null);
  const [colorPending, setColorPending] = React.useState(null);
  const [colorCancel, setColorCancel] = React.useState(null);
  const [returnParent, setReturnParent] = React.useState(false);

  const item = React.useMemo(() => {
    if (itemId) {
      let item = null;
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setName(item.name);
        setPricePerHour(item.pricePerHour);
        setPricePer4HourWT(item.pricePer4HourWT);
        setPricePer8HourWT(item.pricePer8HourWT);
        setCapacity(item.capacity);
        setCalendarId(item.calendarId);
        setColorCalendar(item.colorCalendar);
        setColorCalendarPending(item.colorCalendarPending);
        setColorCalendarCancel(item.colorCalendarCancel);
        setColor(item.color);
        setColorPending(item.colorPending);
        setColorCancel(item.colorCancel);
      }
      console.log({ item });
      return item;
    }
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        setDatas(
          datas.map((item) => {
            if (item._id === itemId) {
              return res.data;
            } else {
              return item;
            }
          })
        );
      } else {
        //created
        setDatas([...datas, res.data]);
      }
    },
    [datas, setDatas]
  );
  const updateItemSuccess = React.useCallback(
    (res) => {
      //   console.log({ res });
      if (res && res.status === 200) {
        alert(itemId ? "Update Successfully!" : "Create Successfully!");
        setDataList(itemId, res);
      }
    },
    [setDataList, itemId]
  );
  const handleSubmit = React.useCallback(
    (event) => {
      const newItem = {
        name,
        pricePerHour,
        pricePer4HourWT,
        pricePer8HourWT,
        capacity,
        calendarId,
        colorCalendar,
        colorCalendarPending,
        colorCalendarCancel,
        color,
        colorPending,
        colorCancel,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        updateRoom(token, newItem)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        console.log({ newItem });
        createRoom(token, newItem)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
          });
      }
      setReturnParent(true);
    },
    [
      calendarId,
      capacity,
      color,
      colorCalendar,
      colorCalendarCancel,
      colorCalendarPending,
      colorCancel,
      colorPending,
      itemId,
      name,
      pricePer4HourWT,
      pricePer8HourWT,
      pricePerHour,
      token,
      updateItemSuccess,
      userInfo?._id,
    ]
  );

  return (
    <>
      <Header
        title={itemId ? "Edit Room" : "New Room"}
        triggerParentClick={returnParent}
      />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            label="Name"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Price Per Hour"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="pricePerHour"
            variant="outlined"
            value={pricePerHour}
            onChange={(e) => {
              setPricePerHour(e.target.value);
            }}
            // InputProps={{
            //   inputComponent: NumberFormatVNCurrency,
            // }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Price Per 4 Hour WT"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="pricePer4HourWT"
            variant="outlined"
            value={pricePer4HourWT}
            onChange={(e) => {
              setPricePer4HourWT(e.target.value);
            }}
            // InputProps={{
            //   inputComponent: NumberFormatVNCurrency,
            // }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Price Per 8 Hour WT"
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="pricePer8HourWT"
            variant="outlined"
            value={pricePer8HourWT}
            onChange={(e) => {
              setPricePer8HourWT(e.target.value);
            }}
            // InputProps={{
            //   inputComponent: NumberFormatVNCurrency,
            // }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Capacity"
            fullWidth
            variant="outlined"
            value={capacity}
            onChange={(e) => {
              setCapacity(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Calendar ID"
            fullWidth
            variant="outlined"
            value={calendarId}
            onChange={(e) => {
              setCalendarId(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorCalendar?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorCalendar(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Calendar Color"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorCalendarPending?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorCalendarPending(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Calendar Color Pending"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorCalendarCancel?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorCalendarCancel(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Calendar Color Cancel"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === color?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColor(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Color" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorPending?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorPending(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Color Pending" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorCancel?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorCancel(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Color Cancel" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item>
          <Typography component="div">
            {`Last updated by: ${data?.lastUpdatedBy?.username}`}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
