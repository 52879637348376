export const SPENDING_MENU = [
  {
    text: "Spending Session",
    icon: undefined,
    type: "spendingSession",
  },
  {
    text: "Spending",
    icon: undefined,
    type: "spending",
  },
  {
    text: "Spending Category",
    icon: undefined,
    type: "spendingCate",
  },
];

export const SPENDING_MENU_STAFF = [
  {
    text: "Spending Session",
    icon: undefined,
    type: "spendingSession",
  },
];
