import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  createClass,
  getBookingSummary,
  getClassById,
  getClassSummary,
  searchClass,
  updateClass,
} from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";
import moment from "moment";
import {
  BOOKING_SUMMARY_TYPE,
  CLASS_STATUS,
  CLASS_STATUS_OPTIONS,
} from "../../../../constants";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import "moment/locale/vi";
import {
  convertTimeToHourMin,
  numberWithCommas,
} from "../../../../helpers/stringDate";
import { Typography } from "@mui/material";
import "./InvoiceClass.css";
import { Box } from "@mui/system";

function InvoiceClassItem({ label, value }) {
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        {label + ": "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {value}
      </Box>
    </Grid>
  );
}

function ChangeClassItem({ oldStartTime, fee }) {
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        {moment(oldStartTime).format("DD/MM/yyyy HH:mm") + " - "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {numberWithCommas(fee, " đ")}
      </Box>
    </Grid>
  );
}

export default function InvoiceClass() {
  const { id } = useParams();
  const classId = id;
  console.log({ classId });
  moment.locale("vi");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  // const [time, setTime] = React.useState(0);
  // const [classNo, setClassNo] = React.useState("");
  // const [discount, setDiscount] = React.useState(0);
  // const [classFee, setClassFee] = React.useState(0);
  // const [bookingSummary, setBookingSummary] = React.useState(null);

  React.useEffect(() => {
    if (classId) {
      console.log("Getting Class summary...");
      getClassSummary(token, classId)
        .then((res) => {
          if (res && res.status === 200) {
            const item = res.data;
            console.log({ item });
            setData(item);
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [classId, token]);

  // const status = React.useMemo(() => {
  //   const startTime = data?.data.startTime;
  //   const endTime = data?.data.endTime;
  //   if (data?.data.status === "active" && moment().isAfter(moment(endTime))) {
  //     return "complete";
  //   } else {
  //     return data?.data.status;
  //   }
  // }, [data?.data.endTime, data?.data.startTime, data?.data.status]);

  const classSumDes = React.useCallback(() => {
    let sumDes = "";
    if (data?.isLast) {
      sumDes += "Đây là buổi cuối! Xem thông tin booking bên dưới!";
    } else {
      let tmpTotal = 0;
      if (!data?.bookingSummary?.data?.payAll) {
        //payAll === false
        tmpTotal = data?.totalWithVAT;
        // } else {
        //   sumDes +=
        //     "\nKhách cần trả: " +
        //     numberWithCommas(
        //       -data?.remain + data?.addonFee + data?.vatFee,
        //       " đ"
        //     );
        // }
      } else {
        tmpTotal = data?.addonFee + data?.remain < 0 ? -data?.remain : 0;
      }
      if (tmpTotal <= 0) {
        sumDes += "\nKhách không cần trả tiền hôm nay";
      } else {
        sumDes += "\nKhách cần trả: " + numberWithCommas(tmpTotal, " đ");
      }
    }
    return sumDes;
  }, [
    data?.addonFee,
    data?.bookingSummary?.data?.payAll,
    data?.isLast,
    data?.remain,
    data?.totalWithVAT,
  ]);
  const classInvoiceItems = [
    {
      label: "Status",
      value: CLASS_STATUS.filter((value, index, array) => {
        return value.value === data?.status;
      })[0]?.label,
    },
    {
      label: "No",
      value: data?.classNo + "/" + data?.bookingSummary.classCount.all,
    },
    {
      label: "Phòng (Room)",
      value: data?.data.roomId.name,
    },
    {
      label: "Ngày (Date)",
      value: moment(data?.data.startTime).format("dddd, DD/MM/yyyy"),
    },
    {
      label: "Bắt đầu (Start)",
      value: moment(data?.data.startTime).format("HH:mm"),
    },
    {
      label: "Kết thúc (End)",
      value: moment(data?.data.endTime).format("HH:mm"),
    },
    {
      label: "Số giờ (Used)",
      value: data?.hour,
    },
    {
      label: "Giá/Giờ (Price/Hour)",
      value: numberWithCommas(data?.data.pricePerHour, " đ"),
    },
    {
      label: "Phí sử dụng (Used fee)",
      value: numberWithCommas(data?.useFee, " đ"),
    },
    {
      label: "Phí dời đổi (Changed fee)",
      value: numberWithCommas(data?.moveFee, " đ"),
    },
    {
      label: "Phí huỷ (Canceled fee)",
      value: numberWithCommas(data?.cancelFee, " đ"),
    },
    {
      label: "Phí phòng (Sub room fee)",
      value: numberWithCommas(data?.totalFee, " đ"),
    },
    {
      label: "Giảm giá (Discount)",
      value: data?.discountPercent * 100 + "%",
    },
    {
      label: "Phí phòng đã giảm (Room fee)",
      value: numberWithCommas(data?.totalFeeWithDiscount, " đ"),
    },
    {
      label: "Phí dịch vụ (Add-on fee)",
      value: numberWithCommas(data?.addonFee, " đ"),
    },
    {
      label: "Tổng (Total fee)",
      value: numberWithCommas(data?.total, " đ"),
    },
    {
      label: "VAT (VAT fee)",
      value: numberWithCommas(data?.vatFee, " đ"),
    },
    {
      label: "Tổng + VAT (Sub-Total)",
      value: numberWithCommas(data?.totalWithVAT, " đ"),
    },
    {
      label: "Trả từng buổi",
      value: data?.bookingSummary?.data?.payAll ? "No" : "Yes",
    },
    {
      label: "Tổng kết",
      value: classSumDes(),
    },
  ];

  const bookingSummaryItems = [
    {
      label: "Số lớp (Classes)",
      value: function (key) {
        return data?.bookingSummary?.classCount[key];
      },
    },
    {
      label: "Tỷ lệ huỷ (Cancel percent)",
      value: function (key) {
        return (
          (data?.bookingSummary.canceledPercent[key] * 100).toFixed(2) + "%"
        );
      },
    },
    {
      label: "Tổng số giờ tạm dừng (Pending hours)",
      value: function (key) {
        return data?.bookingSummary.totalHoursPending[key];
      },
    },
    {
      label: "Tổng số giờ sử dụng (Total use hours)",
      value: function (key) {
        return data?.bookingSummary.totalHoursInUsed[key];
      },
    },
    {
      label: "Tổng phí sử dụng (Total use fee)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalFeeInUsed[key], " đ");
      },
    },
    // {
    //   label: "Tổng phí sử dụng đã giảm (Total fee with discount)",
    //   value: numberWithCommas(
    //     data?.bookingSummary.totalFeeWithDiscount,
    //     " đ"
    //   ),
    // },
    {
      label: "Tổng số giờ huỷ (Total cancel hours)",
      value: function (key) {
        return data?.bookingSummary.totalHoursCanceled[key].toFixed(2);
      },
    },
    {
      label: "Tổng phí huỷ (Total cancel fee)",
      value: function (key) {
        return numberWithCommas(
          data?.bookingSummary.totalFeeCanceled[key],
          " đ"
        );
      },
    },
    {
      label: "Tổng phí dời đổi (Total change fee)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalFeeMoved[key], " đ");
      },
    },
    {
      label: "Tổng phí (Sub Total)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalFee[key], " đ");
      },
    },
    {
      label: "Giảm giá (Discount)",
      value: function (key) {
        return data?.bookingSummary.discountPercent * 100 + "%";
      },
    },
    {
      label: "Tổng phí đã giảm (Total fee)",
      value: function (key) {
        return numberWithCommas(
          data?.bookingSummary.totalFeeWithDiscount[key],
          " đ"
        );
      },
    },
    {
      label: "Tổng phí dịch vụ (Total add-on fee)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalAddonFee[key], " đ");
      },
    },
    {
      label: "Tổng (Total)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.total[key], " đ");
      },
    },
    {
      label: "Tổng VAT (Total VAT fee)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalVAT[key], " đ");
      },
    },
    {
      label: "Tổng + VAT (Sub-Total)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalWithVAT[key], " đ");
      },
    },
    {
      label: "Đã thanh toán (Total payed)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.totalPayed[key], " đ");
      },
    },
    {
      label: "Đặt cọc (Deposit)",
      value: function (key) {
        return numberWithCommas(data?.bookingSummary.data.deposit, " đ");
      },
    },
    {
      label: "Tổng kết",
      value: function (key) {
        return data?.bookingSummary.remain[key] > 0
          ? "Khách cần trả thêm " +
              numberWithCommas(data?.bookingSummary.remain[key], " đ")
          : "Cần trả lại khách " +
              numberWithCommas(-data?.bookingSummary.remain[key], " đ");
      },
    },
  ];
  return (
    <>
      <Header title="Class Invoice" />
      <div className="content">
        <Grid container direction="column" spacing={1} className="invoice">
          {classInvoiceItems.map((item) => (
            <InvoiceClassItem
              key={item.label}
              label={item.label}
              value={item.value}
            />
          ))}
        </Grid>
        {data?.data.moveFees.length > 0 ? (
          <>
            <Typography
              variant="h4"
              component="div"
              className="booking-summary-header"
            >
              Class Change Summary
            </Typography>
            <Grid container direction="column" spacing={1} className="invoice">
              {data?.data.moveFees.map((item) => (
                <ChangeClassItem
                  key={item._id}
                  oldStartTime={item.oldStartTime}
                  fee={item.fee}
                />
              ))}
            </Grid>
          </>
        ) : (
          <></>
        )}
        {BOOKING_SUMMARY_TYPE.map((type) => (
          <div key={type.value}>
            <Typography
              variant="h4"
              component="div"
              className="booking-summary-header"
            >
              {`Booking ${type.name}`}
            </Typography>
            <Grid container direction="column" spacing={1} className="invoice">
              {bookingSummaryItems.map((item) => (
                <InvoiceClassItem
                  key={item.label}
                  label={item.label}
                  value={item.value(type.value)}
                />
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </>
  );
}
