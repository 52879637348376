import { Button, Chip, Grid } from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./SessionInfoHeader.css";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthProvider";

export default function SessionInfoHeader({
  title,
  no,
  calendarLink,
  parentPath,
  parentData,
}) {
  const { token, userInfo } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const handleOpenCalendar = React.useCallback(
    (event) => {
      // console.log(data?.calendarLink);
      // navigate(data?.calendarLink);
      const win = window.open(calendarLink, "_blank");
      if (win != null) {
        win.focus();
      }
    },
    [calendarLink]
  );
  const handleOpenParent = React.useCallback(
    (event) => {
      navigate(`/${parentPath}/` + parentData?.id);
    },
    [navigate, parentData?.id, parentPath]
  );

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Chip
              label={`${title} No./Total`}
              disabled={false}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Chip className="chip-no" label={no} disabled={false} />
          </Grid>
          <Grid item xs></Grid>
          {calendarLink ? (
            <Grid item>
              <Button
                variant="outlined"
                className="btn-open-calendar"
                size="medium"
                color="primary"
                startIcon={<CalendarMonthIcon />}
                onClick={handleOpenCalendar}
              >
                Calendar
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {userInfo?.role === "admin" ? (
        <Grid item>
          <Button
            variant="outlined"
            className="btn-open-calendar"
            size="medium"
            color="primary"
            fullWidth
            startIcon={<CalendarMonthIcon />}
            onClick={handleOpenParent}
          >
            {parentData?.label}
          </Button>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
