import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Popover,
  MenuItem,
  MenuList,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Chip,
} from "@mui/material";

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import "moment/locale/vi";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import MoreActionButton from "../../../../Header/MoreActionButton";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
// import { NumberFormatVNCurrency } from "../../../../../helpers/NumberFormatCustom";
import NumberField from "../../../../../helpers/NumberField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePickerField from "../../../../../helpers/DateTimePickerField";
import {
  convertTimeToHourMin,
  numberWithCommas,
} from "../../../../../helpers/stringDate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SessionInfoHeader from "../../../../../helpers/SessionInfoHeader/SessionInfoHeader";
import ConfirmUpdateDialog from "../../../../../helpers/ConfirmUpdateDialog";
import LinearProgress from "@mui/material/LinearProgress";

import "./StatisticalStaff.css";

export default function StatisticalStaff() {
  moment.locale("vi");
  // moment.utcOffset(0);
  // const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token, userInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [fetching, setFetching] = React.useState({
    data: false,
  });
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleSubmit = React.useCallback((ev) => {}, []);

  return (
    <>
      <Header title="Statistical Staff" />
      {fetching.data ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <DateTimePickerField
                label="Start Time"
                value={startTime}
                onChange={(newValue) => {
                  // console.log({newValue});
                  if (newValue) {
                    newValue.set({ second: 0, millisecond: 0 });
                    setStartTime(newValue.toISOString());
                  }
                }}
              />
            </Grid>
            <Grid item>
              <DateTimePickerField
                disabled={userInfo?.role === "staff"}
                label="End Time"
                value={endTime}
                onChange={(newValue) => {
                  // console.log({newValue});
                  if (newValue) {
                    newValue.set({ second: 0, millisecond: 0 });
                    setEndTime(newValue.toISOString());
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
