import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  createBooking,
  getBookingById,
  getBookingDiscountPercentById,
  getCustomerAll,
  getDiscountFixeds,
  searchClass,
  sendSMSClasses,
  sendSMSConfirm,
  sendSMSParking,
  updateBooking,
} from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
import "./EditBooking.css";
import {
  BOOKING_STATUS,
  DISCOUNT_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  PAY_ALL_OPTIONS,
} from "../../../../constants";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import {
  updateItemClass,
  updateOtherClassNoCalendar,
} from "../Class/EditClass";
import MoreActionButton from "../../../Header/MoreActionButton";
import NumberField from "../../../../helpers/NumberField";

export default function EditBooking() {
  // console.log({ itemId });
  // console.log({ datas });
  // console.log({ discountFixeds });
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [customers, setCustomers] = React.useState([]);
  const [customerId, setCustomerId] = React.useState("");
  const [payAll, setPayAll] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(
    PAYMENT_TYPE_OPTIONS[0].value
  );
  const [deposit, setDeposit] = React.useState(0);
  const [discountFixeds, setDiscountFixeds] = React.useState([]);
  const [discountPercent, setDiscountPercent] = React.useState(0.0);
  const [discountType, setDiscountType] = React.useState(
    DISCOUNT_TYPE_OPTIONS[0]
  );
  const [parkingName, setParkingName] = React.useState("");
  const [pax, setPax] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [bookingName, setBookingName] = React.useState("");
  const [createdAt, setCreatedAt] = React.useState("");
  const [status, setStatus] = React.useState(BOOKING_STATUS[0].value);
  const [progress, setProgress] = React.useState(0);
  const [fetching, setFetching] = React.useState({
    setting: false,
    customers: false,
    data: false,
  });
  const [returnParent, setReturnParent] = React.useState(false);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // const customerOptions = React.useMemo(() => {
  //   return customers.map((item) => ({
  //     label: item.name + " (" + item.phone + ")",
  //     id: item._id,
  //   }));
  // }, [customers]);
  // console.log({ customerOptions });

  React.useEffect(() => {
    async function doTask() {
      setProgress(0);
      let alertContent;
      console.log("Getting setting...");
      setFetching((prevState) => ({ ...prevState, setting: true }));
      await getDiscountFixeds(token)
        .then((res) => {
          if (res && res.status === 200) {
            if (!mountedRef.current) return null;
            setDiscountFixeds(res.data);
          }
        })
        .catch((err) => {
          console.log({ err });
          alertContent = err;
          if (!mountedRef.current) return null;
        });
      setProgress(30);
      setFetching((prevState) => ({ ...prevState, setting: false }));
      if (id) {
        console.log("Getting data...");
        // setProgress(30);
        setFetching((prevState) => ({ ...prevState, data: true }));
        await getBookingById(token, id)
          .then(async (res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const item = res.data;
              console.log({ item });
              if (item) {
                setData(item);
                setCustomers([item.customerId]);
                setCustomerId(item.customerId._id);
                setPayAll(item.payAll);
                setPaymentType(item.paymentType);
                setDeposit(item.deposit);
                setDiscountPercent(item.discountPercent);
                setDiscountType(item.discountType);
                setParkingName(item.parkingName);
                setPax(item.pax);
                setNote(item.note);
                setCreatedAt(item.createdAt);
                setStatus(item.status);
                setBookingName(item.bookingName);
              } else {
                alertContent = `No Booking with ID: ${id} found!`;
              }
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, data: false }));
      } else {
        console.log("Getting customers...");
        // setProgress(30);
        setFetching((prevState) => ({ ...prevState, customers: true }));
        await getCustomerAll(token)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const customersData = res.data;
              console.log({ customersData });
              customersData.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
              setCustomers(customersData);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, customers: false }));
      }
      if (alertContent) {
        alert(alertContent);
      }
    }
    doTask();
  }, [id, token]);

  const updateClasses = React.useCallback(
    async (startProgress, endProgress, field = "") => {
      // setProgress(startProgress);
      // const { note, status } = field;
      const bookingClasses = await searchClass(token, {
        search: id,
      }).then((res) => {
        if (res && res.status === 200) {
          return res.data;
        }
      });
      bookingClasses.sort(function (a, b) {
        return new Date(a.startTime) - new Date(b.startTime);
      });
      console.log({ bookingClasses });
      let successCount = 0;
      let errorCount = 0;
      for (let i = 0; i < bookingClasses.length; i++) {
        let updateDB = true;
        const classItem = bookingClasses[i];
        console.log({ field });
        switch (field) {
          case "deposit":
            updateDB = false;
            // const booking = classItem.bookingId;
            // booking["deposit"] = deposit;
            // classItem["bookingId"] = booking;
            classItem.bookingId.deposit = deposit;
            break;
          case "note":
            classItem["note"] = note;
            break;
          case "pax":
            classItem["pax"] = pax;
            break;
          case "parkingName":
            classItem["parkingName"] = parkingName;
            break;
          default:
            classItem["note"] = note;
            classItem["pax"] = pax;
            classItem["parkingName"] = parkingName;
            classItem.bookingId.deposit = deposit;
        }
        classItem["no"] = i + 1 + "/" + bookingClasses.length;
        console.log({ classItem });
        setProgress(
          (i / bookingClasses.length) * (endProgress - startProgress) +
            startProgress
        );
        const { calendarCode, serverCode } = await updateItemClass(
          token,
          classItem,
          updateDB,
          userInfo
        );
        if (serverCode === 200) {
          successCount += 1;
        } else {
          errorCount += 1;
        }
      }
      return { totalCount: bookingClasses.length, successCount, errorCount };
    },
    [deposit, id, note, parkingName, pax, token, userInfo]
  );

  const handleUpdateChildren = React.useCallback(
    async (event, field) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, data: true }));
      let alertContent;
      if (id) {
        let successSessionCount = 0;
        let errorSessionCount = 0;

        const result = window.confirm(
          "Do you want update to all child class(es)?"
        );
        if (result) {
          const { successCount, errorCount } = await updateClasses(
            10,
            70,
            field
          );
          // console.log({res});
          successSessionCount = successCount;
          errorSessionCount = errorCount;
        }
        if (errorSessionCount === 0) {
          alertContent = `Update ${successSessionCount} Class(es) success!`;
        } else {
          alertContent = `Update ${errorSessionCount} Class(es) failed!`;
        }
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
    },
    [id, updateClasses]
  );

  const handleUpdateDiscountPercent = React.useCallback(
    async (event, payAll, discountType) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, data: true }));
      let alertContent;
      if (id) {
        await getBookingDiscountPercentById(token, { id, payAll, discountType })
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              console.log({ res });
              setDiscountPercent(res.data.discountPercent);
            } else {
              alertContent = `Get Discount Percent by Rule failed!`;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = `Get Discount Percent by Rule failed!`;
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
    },
    [id, token]
  );

  const handleSubmit = React.useCallback(
    async (event) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, data: true }));
      let alertContent;
      const newItem = {
        bookingName,
        customerId,
        payAll,
        deposit,
        discountPercent,
        discountType,
        parkingName,
        pax,
        note,
        status,
        lastUpdatedBy: userInfo?._id,
      };
      if (id) {
        newItem["_id"] = id;
        console.log({ newItem });
        let successSessionCount = 0;
        let errorSessionCount = 0;
        let flagUpdateDeposit = false;
        if (deposit !== data?.deposit) {
          flagUpdateDeposit = true;
        }
        if (
          note !== data?.note ||
          pax !== data?.pax ||
          parkingName !== data?.parkingName
        ) {
          const result = window.confirm(
            "Note, Pax, Parking Name are changed. Do you want to update all child session(s)"
          );
          if (result) {
            const { successCount, errorCount } = await updateClasses(
              10,
              70,
              ""
            );
            // console.log({res});
            successSessionCount = successCount;
            errorSessionCount = errorCount;
            flagUpdateDeposit = false;
          }
        }
        if (flagUpdateDeposit) {
          const { successCount, errorCount } = await updateClasses(
            10,
            70,
            "deposit"
          );
          // console.log({res});
          successSessionCount = successCount;
          errorSessionCount = errorCount;
        }
        if (errorSessionCount === 0) {
          await updateBooking(token, newItem)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent = `Update Booking and ${successSessionCount} Class(es) SUCCESS!`;
              } else {
                alertContent = `Update Booking FAILED and ${successSessionCount} Class(es) SUCCESS! Error: ${res.status}`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = `Update Booking FAILED and Update ${successSessionCount} Class(es) SUCCESS! Error: ${err}`;
            });
        } else {
          alertContent = `Update Booking FAILED! Error class(es): ${errorSessionCount}`;
        }
      } else {
        console.log({ newItem });
        await createBooking(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent = `Create success!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
      setReturnParent(true);
    },
    [
      bookingName,
      customerId,
      payAll,
      deposit,
      discountPercent,
      discountType,
      parkingName,
      pax,
      note,
      status,
      userInfo?._id,
      id,
      data?.deposit,
      data?.note,
      data?.pax,
      data?.parkingName,
      updateClasses,
      token,
    ]
  );

  const customerOptions = React.useMemo(() => {
    return customers.map((item) => ({
      label: item.name + " (" + item.phone + ")",
      id: item._id,
      data: item,
    }));
  }, [customers]);

  const customerChoosen = React.useMemo(() => {
    for (let i = 0; i < customerOptions.length; i++) {
      if (customerOptions[i].id === customerId) {
        return customerOptions[i];
      }
    }
    return null;
  }, [customerId, customerOptions]);

  const actions = [
    "Summary",
    "Update Class No.",
    "Send SMS Confirm",
    "Send SMS Classes",
    "Send SMS Parking Motor",
    "Send SMS Parking Car",
    "VAT",
  ];
  const handleClickAction = React.useCallback(
    async (event, action) => {
      let alertContent;
      switch (action) {
        case "Summary":
          navigate("summary");
          break;
        case "Update Class No.":
          setFetching((prevState) => ({ ...prevState, data: true }));
          const { update, updatedCount, errorCount } =
            await updateOtherClassNoCalendar(
              token,
              id,
              true,
              [],
              [],
              setProgress,
              10,
              50
            );
          if (update) {
            alertContent = `Calendar: [${updatedCount} OK] - [${errorCount} ERROR]`;
          }
          break;
        case "Send SMS Confirm":
          setFetching((prevState) => ({ ...prevState, data: true }));
          await sendSMSConfirm(token, { bookingId: id })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent = `Send SMS Confirm success!`;
              } else {
                alertContent = `Error: ${res.status}`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
          break;
        case "Send SMS Classes":
          setFetching((prevState) => ({ ...prevState, data: true }));
          await sendSMSClasses(token, { bookingId: id })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent = `Send SMS Classes success!`;
              } else {
                alertContent = `Error: ${res.status}`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
          break;
        case "Send SMS Parking Motor":
          setFetching((prevState) => ({ ...prevState, data: true }));
          await sendSMSParking(token, { bookingId: id, vehicle: "motor" })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent = `Send SMS Parking Motor success!`;
              } else {
                alertContent = `Error: ${res.status}`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
          break;
        case "Send SMS Parking Car":
          setFetching((prevState) => ({ ...prevState, data: true }));
          await sendSMSParking(token, { bookingId: id, vehicle: "car" })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent = `Send SMS Parking Car success!`;
              } else {
                alertContent = `Error: ${res.status}`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
          break;
        case "VAT":
          navigate("vat");
          break;
        default:
          return;
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
      if (alertContent) {
        alert(alertContent);
      }
    },
    [id, navigate, token]
  );
  const rightButton = (
    <MoreActionButton actions={actions} handleClickAction={handleClickAction} />
  );

  // React.useEffect(() => {
  //   if (discountType === "rule") {
  //     handleUpdateDiscountPercent();
  //   }
  // }, [discountType, handleUpdateDiscountPercent]);
  return (
    <>
      <Header
        title={(id ? "Edit" : "New") + " Booking"}
        rightElement={id ? rightButton : null}
        triggerParentClick={returnParent}
      />
      {fetching.data || fetching.customers || fetching.setting ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item className={!id ? "no-display" : ""}>
            <TextField
              label="Date"
              fullWidth
              disabled
              variant="outlined"
              value={moment(createdAt).format("DD/MM/yyyy")}
            />
          </Grid>
          <Grid item className={!id ? "no-display" : ""}>
            <TextField
              label="Booking Id"
              fullWidth
              disabled
              variant="outlined"
              value={id}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              disabled={id ? true : false}
              className="customer-options"
              options={customerOptions}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={customerChoosen}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              onChange={(event, newValue) => {
                if (!newValue) {
                  newValue = customerOptions[0];
                }
                setCustomerId(newValue?.id);
              }}
              id="customer-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Customer" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Booking Name"
              fullWidth
              variant="outlined"
              value={bookingName}
              onChange={(e) => {
                setBookingName(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              className="pay-all-options"
              options={PAY_ALL_OPTIONS}
              value={payAll}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                setPayAll(newValue);
                if (discountType === "rule") {
                  handleUpdateDiscountPercent(event, newValue, discountType);
                }
                // console.log({newValue});
              }}
              id="pay-all-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Pay All" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="select-booking-payment-type-label">
                  Payment Type (Deposit)
                </InputLabel>
                <Select
                  labelId="select-booking-payment-type-label"
                  id="select-booking-payment"
                  value={paymentType}
                  fullWidth
                  label="Payment Type (Deposit)"
                  onChange={(event) => {
                    setPaymentType(event.target.value);
                  }}
                >
                  {PAYMENT_TYPE_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <Autocomplete
              className="payment-type-options"
              options={PAYMENT_TYPE_OPTIONS}
              value={paymentType}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                setPaymentType(newValue);
                // console.log({newValue});
              }}
              id="payment-type-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Type"
                  variant="outlined"
                />
              )}
            /> */}
          </Grid>
          <Grid item>
            <NumberField
              error
              label="Deposit"
              fullWidth
              name="deposit"
              suffix=" đ"
              isThousandSeparator={true}
              myValue={deposit}
              myOnChange={(e, newValue) => {
                setDeposit(newValue);
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              className="discount-type-options"
              options={DISCOUNT_TYPE_OPTIONS}
              value={discountType}
              onChange={(event, newValue) => {
                setDiscountType(newValue);
                if (newValue === "rule") {
                  handleUpdateDiscountPercent(event, payAll, newValue);
                }
                // console.log({newValue});
              }}
              id="discount-type-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Discount Type"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              className="discount-fixed-options"
              disabled={discountType === "rule"}
              options={discountFixeds}
              value={discountPercent}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                setDiscountPercent(newValue);
                // console.log({newValue});
              }}
              id="discount-fixed-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Discount Percent (%)"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Parking Name"
              fullWidth
              variant="outlined"
              value={parkingName}
              onChange={(e) => {
                setParkingName(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleUpdateChildren(event, "parkingName")}
            >
              Update Parking Name to Children
            </Button>
          </Grid>
          <Grid item>
            <NumberField
              label="Pax"
              fullWidth
              name="pax"
              myValue={pax}
              myOnChange={(e, newValue) => {
                setPax(newValue);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleUpdateChildren(event, "pax")}
            >
              Update Pax to Children
            </Button>
          </Grid>
          <Grid item>
            <TextField
              label="Note"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleUpdateChildren(event, "note")}
            >
              Update Note to Children
            </Button>
          </Grid>
          <Grid item>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="select-booking-status-label">Status</InputLabel>
                <Select
                  labelId="select-booking-status-label"
                  id="select-booking-status"
                  value={status}
                  fullWidth
                  label="Status"
                  onChange={(event) => {
                    const state = event.target.value;
                    setStatus(state);
                  }}
                >
                  {BOOKING_STATUS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item className={!data ? "no-display" : ""}>
            <Typography component="div">
              {`Last updated by: ${data?.lastUpdatedBy?.username}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleSubmit(event)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
