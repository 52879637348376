import { Grid, IconButton, ListItem, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default function SMSContentItem({ item, number, deleteItem }) {
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();
  //   const payAllString = arrayToString(rule.payAll, ",");
  //   const percent = rule.percent * 100 + "%";
  const handleEditClick = React.useCallback(
    (event) => {
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  return (
    <>
      <Grid container spacing={0} direction="row" className="item-list">
        <Grid item xs={1}>
          <Typography align="center">{number + "."}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography align="left">{item.language}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography align="left">{item.content}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(event) => handleEditClick(event)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
