import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { createUser, updateUser } from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import moment from "moment";
import { useNavigate } from "react-router";
import UpdatePassword from "./UpdatePassword";
import { USER_ROLE_OPTIONS } from "../../../../constants";
import Autocomplete from "@mui/material/Autocomplete";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";

export default function EditUser({ itemId, datas, setDatas, updatePassword }) {
  console.log({ itemId });
  console.log({ updatePassword });
  console.log({ datas });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [salaryPerHour, setSalaryPerHour] = React.useState(0);
  const [returnParent, setReturnParent] = React.useState(false);
  const navigate = useNavigate();
  //   const getItem = React.useCallback(
  //     (id) => {
  //       for (let i = 0; i < datas.length; i++) {
  //         if (datas[i]._id === id) {
  //           return datas[i];
  //         }
  //       }
  //     },
  //     [datas]
  //   );
  React.useEffect(() => {
    if (itemId) {
      let item = null;
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setUsername(item.username);
        setName(item.name);
        setBirthday(moment(item.birthday).format("DD/MM/yyyy"));
        setPhone(item.phone);
        setEmail(item.email);
        setRole(item.role);
        setSalaryPerHour(item.salaryPerHour);
      }
      console.log({ item });
    }
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //update current user loggined
        if (userInfo._id === itemId) {
          setUserInfo(res.data);
        }
        setDatas(
          datas.map((item) => {
            if (item._id === itemId) {
              return res.data;
            } else {
              return item;
            }
          })
        );
      } else {
        //created
        setDatas([...datas, res.data]);
      }
    },
    [datas, setDatas, setUserInfo, userInfo?._id]
  );
  const updateItemSuccess = React.useCallback(
    (res) => {
      //   console.log({ res });
      if (res && res.status === 200) {
        alert(itemId ? "Update Successfully!" : "Create Successfully!");
        setDataList(itemId, res);
      }
    },
    [setDataList, itemId]
  );
  const handleSubmit = React.useCallback(
    (event) => {
      const newItem = {
        username,
        name,
        birthday: moment(birthday, "DD/MM/YYYY").toDate(),
        phone,
        email,
        role,
        salaryPerHour,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        updateUser(token, newItem)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        newItem["password"] = password;
        console.log({ newItem });
        createUser(token, newItem)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
          });
      }
      setReturnParent(true);
    },
    [
      username,
      name,
      birthday,
      phone,
      email,
      role,
      salaryPerHour,
      userInfo?._id,
      itemId,
      token,
      updateItemSuccess,
      password,
    ]
  );

  const handleUpdatePassword = React.useCallback(
    (event) => {
      navigate(`/user/${itemId}/updatePassword`);
    },
    [navigate, itemId]
  );
  if (updatePassword?.value) {
    // console.log("update password");
    return <UpdatePassword itemId={itemId} setDataList={setDataList} />;
  } else {
    return (
      <>
        <Header title={itemId ? "Edit User" : "New User"} triggerParentClick={returnParent} />
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              disabled={userInfo?.role !== "admin"}
              label="Username"
              fullWidth
              variant="outlined"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            {itemId ? (
              <Button
                variant="contained"
                fullWidth
                className="btn-update-password"
                size="medium"
                color="secondary"
                onClick={(event) => handleUpdatePassword(event)}
              >
                Change Password
              </Button>
            ) : (
              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item>
            <TextField
              disabled={userInfo?.role !== "admin"}
              label="Name"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={userInfo?.role !== "admin"}
              label="Birthday (Ex:20/03/1991)"
              fullWidth
              variant="outlined"
              value={birthday}
              onChange={(e) => {
                setBirthday(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={userInfo?.role !== "admin"}
              label="Phone"
              fullWidth
              variant="outlined"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled={userInfo?.role !== "admin"}
              label="Email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              disabled={userInfo?.role !== "admin"}
              className="user-role-options"
              options={USER_ROLE_OPTIONS}
              value={role}
              onChange={(event, newValue) => {
                setRole(newValue);
                // console.log({newValue});
              }}
              id="user-role-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Role" variant="outlined" />
              )}
            />
            {/* <TextField
              label="Role (staff | admin)"
              fullWidth
              variant="outlined"
              disabled={userInfo?.role === "staff"}
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
            /> */}
          </Grid>
          <Grid item>
            <TextField
              disabled={userInfo?.role !== "admin"}
              label="Salary/Hour"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="payed"
              variant="outlined"
              value={salaryPerHour}
              onChange={(e) => {
                setSalaryPerHour(e.target.value === "" ? 0 : e.target.value);
              }}
              // InputProps={{
              //   inputComponent: NumberFormatVNCurrency,
              // }}
            />
          </Grid>
          <Grid item>
            <Typography component="div">
              {`Last updated by: ${data?.lastUpdatedBy?.username}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleSubmit(event)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}
