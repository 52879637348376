import { Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import AddIcon from "@mui/icons-material/Add";
import UserItem from "./UserItem";
import EditUser from "./EditUser";
import { deleteUser, getUserAll } from "../../../../api/api";
import ListSearch from "../../../Header/ListSearch";
import ListHeader from "../../../Header/ListHeader";

export default function User() {
  //   console.log("User");
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  //   console.log("re-render");
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateDatas = React.useCallback(
    (newDatas, setInit = true) => {
      // console.log({ res });
      if (userInfo?.role !== "admin") {
        newDatas = newDatas.filter((value, index, array) => {
          return value._id === userInfo?._id;
        });
      }
      newDatas.sort(function (a, b) {
        return new Date(a.startTime) - new Date(b.startTime);
      });
      console.log({ newDatas });
      if (setInit) {
        setInitDatas(newDatas);
      }
      setDatas(newDatas);
      // console.log({ newDatas });
    },
    [userInfo?._id, userInfo?.role]
  );
  const handleSearch = React.useCallback(
    (search) => {
      console.log({ search });
      if (search) {
        // console.log("filter");
        const newDatas = initDatas.filter(function (value, index, arr) {
          return (
            value.name.toLowerCase().includes(search.toLowerCase()) ||
            value.username.toLowerCase().includes(search.toLowerCase())
          );
        });
        setDatas(newDatas);
      } else {
        // console.log("not filter");
        setDatas(initDatas);
      }
      // navigate(pathname + "/" + search.id);
    },
    [initDatas]
  );
  const deleteItem = React.useCallback(
    (item) => {
      console.log({ token });
      console.log({ item });
      deleteUser(token, item)
        .then((res) => {
          alert("Delete Successfully!");
          console.log({ res });
          const newDatas = datas.filter(function (value, index, arr) {
            return value._id !== res.data._id;
          });
          setDatas(newDatas);
        })
        .catch((err) => {
          console.log({ err });
        });
      //   setRules(newRules);
    },
    [token, datas]
  );
  React.useEffect(() => {
    console.log("Getting new data...");
    getUserAll(token)
      .then((res) => {
        if (!mountedRef.current) return null;
        if (res && res.status === 200) {
          updateDatas(res.data);
        }
      })
      .catch((err) => {
        if (!mountedRef.current) return null;
        console.log({ err });
      });
  }, [token, updateDatas]);
  const action = pathArray.pop();
  // console.log({ action });
  if (action === "new") {
    return <EditUser datas={datas} setDatas={setDatas} />;
  } else if (action === "updatePassword") {
    console.log("updatePassword");
    return (
      //   <UpdatePassword />
      <EditUser
        itemId={id}
        datas={datas}
        setDatas={setDatas}
        updatePassword={{ value: true }}
      />
    );
  } else if (id) {
    console.log("id");
    return <EditUser itemId={id} datas={datas} setDatas={setDatas} />;
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      rows.push(
        <Grid item key={item._id}>
          <UserItem number={i + 1} item={item} deleteItem={deleteItem} />
        </Grid>
      );
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header
          title="Users"
          rightButton={userInfo?.role === "admin" ? rightButton : null}
        />
        <ListSearch handleChange={handleSearch} />
        <ListHeader
          titles={[
            { width: 1, align: "center", title: "No." },
            { width: 3, align: "left", title: "Username" },
            { width: 4, align: "center", title: "Name" },
            { width: 2, align: "center", title: "Role" },
            { width: 2, align: "center", title: "Action" },
          ]}
        />
        <Grid container direction="column" spacing={0}>
          {rows}
        </Grid>
      </>
    );
  }
}
