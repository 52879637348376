import { Box, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  deleteCustomer,
  getCustomerAll,
  searchBooking,
} from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import CustomerItem from "./CustomerItem";
import EditCustomer from "./EditCustomer";
import AddIcon from "@mui/icons-material/Add";
import ListSearch from "../../../Header/ListSearch";
import ListHeader from "../../../Header/ListHeader";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";

export default function Customer() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  console.log({ pathArray });
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const [progress, setProgress] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (search) => {
      console.log({ search });
      if (search) {
        // console.log("filter");
        const newDatas = initDatas.filter(function (value, index, arr) {
          return (
            value.name.toLowerCase().includes(search.toLowerCase()) ||
            value.phone.includes(search)
          );
        });
        setDatas(newDatas);
      } else {
        // console.log("not filter");
        setDatas(initDatas);
      }
      // navigate(pathname + "/" + search.id);
    },
    [initDatas]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching(true);
      let alertContent;
      await searchBooking(token, { search: item._id })
        .then(async (res) => {
          setProgress(50);
          if (res && res.status === 200) {
            if (res.data.length > 0) {
              alertContent = `This customer has ${res.data.length} booking(s) already. Please delete these booking(s) first!`;
            } else {
              await deleteCustomer(token, item)
                .then((res) => {
                  // alert("Delete Successfully!");
                  console.log({ res });
                  const newDatas = datas.filter(function (value, index, arr) {
                    return value._id !== res.data._id;
                  });
                  setDatas(newDatas);
                  alertContent = "Delete success!";
                })
                .catch((err) => {
                  console.log({ err });
                  alertContent = err;
                });
              setProgress(70);
            }
          } else {
            alertContent = `Error: ${res.status}`;
          }
        })
        .catch((err) => {
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      setFetching(false);
      if (alertContent) {
        alert(alertContent);
      }
      //   setRules(newRules);
    },
    [token, datas]
  );
  React.useEffect(() => {
    async function abc() {
      setProgress(10);
      setFetching(true);
      console.log("Getting customers");
      await getCustomerAll(token)
        .then((res) => {
          if (res && res.status === 200) {
            const newDatas = res.data;
            setInitDatas(newDatas);
            setDatas(newDatas);
          }
        })
        .catch((err) => {
          console.log({ err });
        });
      setProgress(100);
      setFetching(false);
    }
    abc();
  }, [token]);
  const action = pathArray.pop();
  console.log({ action });
  if (action === "new") {
    return <EditCustomer datas={datas} setDatas={setDatas} />;
  } else if (id || action === "identityPhotoView") {
    return <EditCustomer itemId={id} datas={datas} setDatas={setDatas} />;
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      //   if (
      //     userInfo?.role === "admin" ||
      //     (userInfo?.role === "staff" && userInfo?._id === item._id)
      //   ) {
      rows.push(
        <Grid item key={item._id}>
          <CustomerItem number={i + 1} item={item} deleteItem={deleteItem} />
        </Grid>
      );
      //   }
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header title="Customers" rightButton={rightButton} />
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <>
            <ListSearch handleChange={handleSearch} />
            <ListHeader
              titles={[
                { width: 1, align: "center", title: "No." },
                { width: 5, align: "left", title: "Name" },
                { width: 4, align: "left", title: "Phone" },
                { width: 2, align: "center", title: "Action" },
              ]}
            />
            <Grid container direction="column" spacing={0}>
              {rows}
            </Grid>
          </>
        )}
      </>
    );
  }
}
