import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../context/AuthProvider";
import moment from "moment";
import {
  createVat,
  getClassById,
  getOperationPercent,
  updateClass,
  updateVat,
  getVatById,
  getClassSummary,
  getBookingById,
  updateBooking,
} from "../../../../api/api";
import Header from "../../../Header/Header";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { VAT_KIND, VAT_STATUS } from "../../../../constants";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";
import { Box } from "@mui/system";
import MoreActionButton from "../../../Header/MoreActionButton";
import { AppContext } from "../../../../context/AppProvider";

export const updateOwnerObject = async (
  classId,
  bookingId,
  vatId,
  userId,
  token
) => {
  const newItem = {
    _id: classId ? classId : bookingId,
    vatId,
    lastUpdatedBy: userId,
  };
  console.log({ newItem });
  try {
    let updatedContent;
    if (classId) {
      updatedContent = await updateClass(token, newItem);
    } else {
      updatedContent = await updateBooking(token, newItem);
    }
    if (updatedContent) {
      console.log(
        "Update " + (classId ? "class" : "booking") + " Successfully!"
      );
    }
  } catch (err) {
    console.log({ err });
  }
};

export default function EditVat({ itemId, datas, updateDatas }) {
  // console.log({ itemId });
  // console.log({ datas });
  const { classId } = useParams();
  // console.log({ classId });
  const { bookingId } = useParams();
  // console.log({ bookingId });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // console.log({ pathname });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const { setParentPathMem, setCurrentPathMem } = React.useContext(AppContext);
  const [data, setData] = React.useState(null);
  const [status, setStatus] = React.useState(VAT_STATUS[0].value);
  const [kind, setKind] = React.useState(VAT_KIND[0].value);
  const [value, setValue] = React.useState(0);
  const [fee, setFee] = React.useState(0);
  const [company, setCompany] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [taxCode, setTaxCode] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [buyerName, setBuyerName] = React.useState("");
  const [operationPercent, setOperationPercent] = React.useState(null);
  const [returnParent, setReturnParent] = React.useState(false);
  // const [classSummary, setClassSummary] = React.useState(null);
  const [fetching, setFetching] = React.useState({
    setting: false,
    class: false,
    booking: false,
    // classSummary: false,
  });

  React.useEffect(() => {
    async function abc() {
      console.log("Getting Setting...");
      setFetching((prevState) => ({ ...prevState, setting: true }));
      getOperationPercent(token)
        .then((res) => {
          if (res && res.status === 200) {
            setOperationPercent(res.data);
            setFetching((prevState) => ({ ...prevState, setting: false }));
          }
        })
        .catch((err) => {
          console.log({ err });
          setFetching((prevState) => ({ ...prevState, setting: false }));
        });
      let item = null;
      if (itemId) {
        //query vat with vatID
        for (let i = 0; i < datas.length; i++) {
          if (datas[i]._id === itemId) {
            item = datas[i];
          }
        }
        // const item = getItem(itemId);
      } else {
        if (classId) {
          //query vat with classID
          console.log("Getting class...");
          setFetching((prevState) => ({ ...prevState, class: true }));
          try {
            const res = await getClassById(token, classId);
            console.log({ res });
            // item = res.data?.vatId;
            if (res.data.vatId) {
              //update
              // const vatRes = await getVatById(token, res.data.vatId._id);
              // console.log({ vatRes });
              item = res.data.vatId;
            } else {
              //new one
              setData({
                classId: res.data,
                // bookingId: res.data.bookingId,
                customerId: res.data.customerId,
              });
              setCompany(res.data.customerId.companyName);
              setAddress(res.data.customerId.companyAddress);
              setTaxCode(res.data.customerId.companyTaxCode);
              setEmail(res.data.customerId.companyEmail);
              setPhone(res.data.customerId.companyPhone);
              setBuyerName(res.data.customerId.name);
            }
          } catch (err) {
            console.log({ err });
          }
          setFetching((prevState) => ({ ...prevState, class: false }));
        } else {
          //query vat with bookingID
          console.log("Getting booking...");
          setFetching((prevState) => ({ ...prevState, booking: true }));
          try {
            const res = await getBookingById(token, bookingId);
            console.log({ res });
            if (res.data.vatId) {
              //update
              // const vatRes = await getVatById(token, res.data.vatId._id);
              // console.log({ vatRes });
              item = res.data.vatId;
            } else {
              //new one
              setData({
                // classId: res.data,
                bookingId: res.data,
                customerId: res.data.customerId,
              });
              setCompany(res.data.customerId.companyName);
              setAddress(res.data.customerId.companyAddress);
              setTaxCode(res.data.customerId.companyTaxCode);
              setEmail(res.data.customerId.companyEmail);
              setPhone(res.data.customerId.companyPhone);
              setBuyerName(res.data.customerId.name);
            }
          } catch (err) {
            console.log({ err });
          }
          setFetching((prevState) => ({ ...prevState, booking: false }));
        }
      }
      if (item) {
        setData(item);
        setStatus(item.status);
        setKind(item.kind);
        setValue(item.value);
        setFee(item.fee);
        setCompany(item.company);
        setAddress(item.address);
        setTaxCode(item.taxCode);
        setEmail(item.email);
        setPhone(item.phone);
        setBuyerName(item.buyerName);
        // console.log("Getting Class summary...");
        // setFetching((prevState) => ({ ...prevState, classSummary: true }));
        // getClassSummary(token, item.classId)
        //   .then((res) => {
        //     if (res && res.status === 200) {
        //       const summary = res.data;
        //       console.log({ summary });
        //       setClassSummary(summary);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log({ err });
        //   });
        // setFetching((prevState) => ({ ...prevState, classSummary: false }));
      }
      console.log({ item });
    }
    abc();
  }, [bookingId, classId, datas, itemId, token]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        updateDatas(null, null, res.data, null);
      } else {
        //created
        updateDatas(null, res.data, null, null);
      }
    },
    [updateDatas]
  );
  const updateItemSuccess = React.useCallback(
    (res) => {
      //   console.log({ res });
      // if (res && res.status === 200) {
      alert(data?._id ? "Update Successfully!" : "Create Successfully!");
      if (itemId) {
        setDataList(itemId, res);
      }
      // }
    },
    [data?._id, setDataList, itemId]
  );

  const handleSubmit = React.useCallback(
    (event) => {
      setFetching(true);
      const newItem = {
        status,
        classId: data?.classId?._id,
        bookingId: data?.bookingId?._id,
        customerId: data?.customerId?._id,
        kind,
        value,
        fee,
        company,
        address,
        taxCode,
        email,
        phone,
        buyerName,
        lastUpdatedBy: userInfo?._id,
      };
      if (data?._id) {
        newItem["_id"] = data?._id;
        console.log({ newItem });
        updateVat(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              // updateVATClass(res.data._id);
              updateItemSuccess(res);
              setFetching(false);
            }
          })
          .catch((err) => {
            console.log({ err });
            setFetching(false);
          });
      } else {
        console.log({ newItem });
        createVat(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              updateOwnerObject(
                data?.classId?._id,
                data?.bookingId?._id,
                res.data._id,
                userInfo?._id,
                token
              );
              updateItemSuccess(res);
              setFetching(false);
            }
          })
          .catch((err) => {
            console.log({ err });
            setFetching(false);
          });
      }
      setReturnParent(true);
    },
    [
      address,
      buyerName,
      company,
      data?._id,
      data?.bookingId?._id,
      data?.classId?._id,
      data?.customerId?._id,
      email,
      fee,
      kind,
      phone,
      status,
      taxCode,
      token,
      updateItemSuccess,
      userInfo?._id,
      value,
    ]
  );

  const statusChoosen = React.useMemo(() => {
    const state = VAT_STATUS.filter((value, index, array) => {
      return value.value === status;
    });
    // console.log({ cate });
    return state === undefined ? null : state[0];
  }, [status]);

  const kindChoosen = React.useMemo(() => {
    const state = VAT_STATUS.filter((value, index, array) => {
      return value.value === status;
    });
    // console.log({ cate });
    return state === undefined ? null : state[0];
  }, [status]);

  const actionsClass = ["Class", "Class's Invoice"];
  const actionsBooking = ["Booking", "Booking's Summary"];

  const handleClickAction = React.useCallback(
    (event, action) => {
      let targetPath;
      switch (action) {
        case "Class":
          setParentPathMem(pathname);
          targetPath = `/class/${data?.classId?._id}`;
          setCurrentPathMem(targetPath);
          navigate(targetPath);
          break;
        case "Class's Invoice":
          // console.log({ pathname });
          setParentPathMem(pathname);
          targetPath = `/class/${data?.classId?._id}/invoice`;
          setCurrentPathMem(targetPath);
          navigate(targetPath);
          break;
        case "Booking":
          setParentPathMem(pathname);
          targetPath = `/booking/${data?.bookingId?._id}`;
          setCurrentPathMem(targetPath);
          navigate(targetPath);
          break;
        case "Booking's Summary":
          // console.log({ pathname });
          setParentPathMem(pathname);
          targetPath = `/booking/${data?.bookingId?._id}/summary`;
          setCurrentPathMem(targetPath);
          navigate(targetPath);
          break;
        default:
          return;
      }
    },
    [
      data?.bookingId?._id,
      data?.classId?._id,
      navigate,
      pathname,
      setCurrentPathMem,
      setParentPathMem,
    ]
  );
  const rightButton = (
    <MoreActionButton
      actions={data?.classId ? actionsClass : actionsBooking}
      handleClickAction={handleClickAction}
    />
  );

  return (
    <>
      <Header
        title={data?._id ? "Edit VAT" : "New VAT"}
        rightElement={itemId || classId ? rightButton : null}
        triggerParentClick={returnParent}
      />
      {fetching.setting || fetching.class ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container direction="column" spacing={3}>
            {data?.bookingId ? (
              <Grid item>
                <TextField
                  label="Booking ID"
                  fullWidth
                  variant="outlined"
                  value={data?.bookingId ? data?.bookingId?._id : ""}
                  disabled
                />
              </Grid>
            ) : (
              <Grid item>
                <TextField
                  label="Class ID"
                  fullWidth
                  variant="outlined"
                  value={data?.classId ? data?.classId?._id : ""}
                  disabled
                />
              </Grid>
            )}
            <Grid item>
              <TextField
                label="Company"
                // InputLabelProps={{ shrink: true }}
                fullWidth
                name="company"
                variant="outlined"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Address"
                // InputLabelProps={{ shrink: true }}
                fullWidth
                name="address"
                variant="outlined"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Tax Code"
                // InputLabelProps={{ shrink: true }}
                fullWidth
                name="taxCode"
                variant="outlined"
                value={taxCode}
                onChange={(e) => {
                  setTaxCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Email"
                // InputLabelProps={{ shrink: true }}
                fullWidth
                name="email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Phone"
                // InputLabelProps={{ shrink: true }}
                fullWidth
                name="phone"
                variant="outlined"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Buyer Name"
                // InputLabelProps={{ shrink: true }}
                fullWidth
                name="buyerName"
                variant="outlined"
                value={buyerName}
                onChange={(e) => {
                  setBuyerName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-vat-kind-label">VAT Kind</InputLabel>
                  <Select
                    labelId="select-vat-kind-label"
                    id="select-vat-kind"
                    value={kind}
                    fullWidth
                    label="VAT Kind"
                    onChange={(event) => {
                      const kindSelect = event.target.value;
                      setKind(kindSelect);
                      if (kindSelect !== "manual") {
                        setValue(0);
                      }
                    }}
                  >
                    {VAT_KIND.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                label={"Fee"}
                InputLabelProps={{ shrink: true }}
                disabled={kind !== "manual"}
                fullWidth
                name="vatFee"
                variant="outlined"
                value={fee}
                onChange={(e) => {
                  const feeInput = e.target.value === "" ? 0 : e.target.value;
                  setFee(feeInput);
                  setValue(feeInput * operationPercent?.vat);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              />
            </Grid>
            <Grid item>
              <TextField
                label={"VAT (" + operationPercent?.vat * 100 + "%)"}
                InputLabelProps={{ shrink: true }}
                disabled={true}
                fullWidth
                name="vatFee"
                variant="outlined"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value === "" ? 0 : e.target.value);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              />
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-vat-status-label">Status</InputLabel>
                  <Select
                    labelId="select-vat-status-label"
                    id="select-vat-status"
                    value={status}
                    fullWidth
                    label="Status"
                    onChange={(event) => {
                      setStatus(event.target.value);
                    }}
                  >
                    {VAT_STATUS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
