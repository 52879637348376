import React from "react";
import CountUp from "react-countup";
import { Card, CardContent, Typography } from "@mui/material";
import "./HighlightCard.css";

export default function HighlightCard({ title, value, type }) {
  //   const type = data.type;
  //   const classes = useStyles({ type });
  // console.log("[data]", data.percent);
  return (
    <Card className={"card_" + type}>
      <CardContent>
        <Typography component="p" variant="body2" className="title">
          {title}
        </Typography>
        <Typography component="span" className="value">
          <CountUp end={value || 0} duration={2} separator=" " />
        </Typography>
      </CardContent>
    </Card>
  );
}
