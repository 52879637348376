import React from "react";
import { useNavigate } from "react-router-dom";
import useRole from "../components/Login/useUserInfo";
import useToken from "../components/Login/useToken";
import useUserInfo from "../components/Login/useUserInfo";

export const AuthContext = React.createContext();

export default function AuthProvider({ children }) {
  const { token, setToken } = useToken();
  const { userInfo, setUserInfo } = useUserInfo();
  const navigate = useNavigate();
  //   console.log("auth", { token });
  React.useEffect(() => {
    if (!token || !userInfo) {
      // alert('hi');
      navigate("/login");
    }
  }, [navigate, token, userInfo]);
  return (
    <AuthContext.Provider value={{ token, setToken, userInfo, setUserInfo }}>
      {children}
    </AuthContext.Provider>
  );
}
