export const ADDON_MENU = [
  {
    text: "Addon Import",
    icon: undefined,
    type: "addonSession",
  },
  {
    text: "Addon",
    icon: undefined,
    type: "addon",
  },
  {
    text: "Addon Category",
    icon: undefined,
    type: "addonCate",
  },
];

export const ADDON_MENU_STAFF = [
  {
    text: "Addon Import",
    icon: undefined,
    type: "addonSession",
  },
];
