import React from "react";
import { LOCAL_STORAGE_KEY_USER_INFO } from "../../constants";

export default function useUserInfo() {
  const getUserInfo = () => {
    const userInfoString = localStorage.getItem(LOCAL_STORAGE_KEY_USER_INFO); //sessionStorage | localStorage
    const userInfo = JSON.parse(userInfoString);
    return userInfo;
  };
  const [userInfo, setUserInfo] = React.useState(getUserInfo());
  const saveUserInfo = (userInfo) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_INFO, JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };

  return {
    setUserInfo: saveUserInfo,
    userInfo,
  };
}
