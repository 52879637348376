import { IconButton, MenuItem, MenuList, Popover } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function MoreActionButton({ ...props }) {
  const { actions, handleClickAction } = props;
  const [anchorElAction, setAnchorElAction] = React.useState(null);
  const openAction = Boolean(anchorElAction);
  const popoverId = openAction ? "popover-actions" : undefined;
  // const actions = ["Summary"];
  const handleClickActions = React.useCallback((event) => {
    setAnchorElAction(event.currentTarget);
  }, []);
  const handleCloseActions = React.useCallback(() => {
    setAnchorElAction(null);
  }, []);
  // const handleClickAction = React.useCallback(
  //   (event, action) => {
  //     switch (action) {
  //       case "Summary":
  //         navigate("summary");
  //         break;
  //       default:
  //         return;
  //     }
  //     handleCloseActions();
  //   },
  //   [handleCloseActions, navigate]
  // );
  return (
    <div>
      <IconButton onClick={handleClickActions}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={popoverId}
        open={openAction}
        anchorEl={anchorElAction}
        onClose={handleCloseActions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList id="list-action-menu">
          {actions.map((action, index) => (
            <MenuItem
              key={action}
              disabled={false}
              selected={false}
              onClick={(event) => {
                handleClickAction(event, action);
                handleCloseActions();
              }}
            >
              {action}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </div>
  );
}
