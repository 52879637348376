import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import {
  getDiscountFixeds,
  updateDiscountFixeds,
} from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import { arrayToString } from "../../../../../../helpers/stringArray";
import Header from "../../../../../Header/Header";

export default function DiscountFixed() {
  const [discounts, setDiscounts] = React.useState("");
  const { token } = React.useContext(AuthContext);
  const updateDiscountText = React.useCallback((res) => {
    console.log({ res });
    if (res && res.status === 200) {
      const discountString = arrayToString(res.data, ",");
      setDiscounts(discountString);
    }
  }, []);
  React.useEffect(() => {
    getDiscountFixeds(token)
      .then((res) => {
        updateDiscountText(res);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token, updateDiscountText]);
  const handleSubmit = React.useCallback(
    (event) => {
      const discountArray = discounts.split(",");
      console.log({ discountArray });
      updateDiscountFixeds(token, discountArray)
        .then((res) => {
          alert("Update Successfully!");
          updateDiscountText(res);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    [discounts, token, updateDiscountText]
  );
  return (
    <>
      <Header title="Discount Fixed" />
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Value"
            fullWidth
            variant="outlined"
            value={discounts}
            onChange={(e) => setDiscounts(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
