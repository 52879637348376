import React from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../../context/AuthProvider";
import { createSpending, updateSpending } from "../../../../../api/api";
import Header from "../../../../Header/Header";
import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// import { NumberFormatVNCurrency } from "../../../../../helpers/NumberFormatCustom";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";

export default function EditSpending({ itemId, datas, updateDatas, cates }) {
  console.log({ itemId });
  console.log({ datas });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [categoryId, setCategoryId] = React.useState(cates[0]?._id);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [returnParent, setReturnParent] = React.useState(false);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const item = React.useMemo(() => {
    if (itemId) {
      let item = null;
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setName(item.name);
        setPrice(item.price);
        setCategoryId(item.categoryId._id);
      }
      console.log({ item });
      return item;
    }
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        updateDatas(null, null, res.data);
      } else {
        //created
        updateDatas(null, res.data, null);
      }
    },
    [updateDatas]
  );
  const handleSubmit = React.useCallback(
    async (event) => {
      let alertContent;
      setProgress(50);
      setFetching(true);
      const newItem = {
        name,
        price,
        categoryId,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        await updateSpending(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              alertContent = `Update success!`;
              setDataList(itemId, res);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      } else {
        console.log({ newItem });
        await createSpending(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              alertContent = `Create success!`;
              setDataList(itemId, res);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
      setReturnParent(true);
    },
    [categoryId, itemId, name, price, setDataList, token, userInfo?._id]
  );
  const categoryChoosen = React.useMemo(() => {
    const cate = cates.filter((value, index, array) => {
      return value._id === categoryId;
    });
    // console.log({ cate });
    return cate === undefined ? null : cate[0];
  }, [categoryId, cates]);

  return (
    <>
      <Header
        title={(itemId ? "Edit" : "New") + " Spending"}
        triggerParentClick={returnParent}
      />
      {fetching ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Autocomplete
              className="spending-category-options"
              options={cates}
              // isOptionEqualToValue={(option, value) => option._id === value}
              defaultValue={cates[0]}
              value={categoryChoosen}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                console.log({ newValue });
                if (!newValue) {
                  newValue = cates[0];
                }
                setCategoryId(newValue._id);
              }}
              id="spending-category-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Price"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="price"
              variant="outlined"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              // InputProps={{
              //   inputComponent: NumberFormatVNCurrency,
              // }}
            />
          </Grid>
          <Grid item>
            <Typography component="div">
              {`Last updated by: ${data?.lastUpdatedBy?.username}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleSubmit(event)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
