import React from "react";
import {
  BOOKING_DATE_RANGES,
  CLASS_DATE_RANGES,
  POS_DATE_RANGES,
  SPENDING_SESSION_DATE_RANGES,
  ADDON_SESSION_DATE_RANGES,
  WORKING_DATE_RANGES,
  WORKING_SESSION_DATE_RANGES,
} from "../constants";
import moment from "moment";
// import {version} from '../../package.json';
import packageInfo from "../../package.json";

export const AppContext = React.createContext();

export const DEBUG = process.env.NODE_ENV === "development";
export const APP_VERSION = packageInfo.version;

export default function AppProvider({ children }) {
  const [searchMem, setSearchMem] = React.useState("");
  const [dateRangeMem, setDateRangeMem] = React.useState(
    CLASS_DATE_RANGES[0].value
  );
  const [searchBookingMem, setSearchBookingMem] = React.useState("");
  const [dateRangeBookingMem, setDateRangeBookingMem] = React.useState(
    BOOKING_DATE_RANGES[0].value
  );
  const [searchPosMem, setSearchPosMem] = React.useState("");
  const [dateRangePosMem, setDateRangePosMem] = React.useState(
    POS_DATE_RANGES[0].value
  );
  const [searchSpendingSessionMem, setSearchSpendingSessionMem] =
    React.useState("");
  const [dateRangeSpendingSessionMem, setDateRangeSpendingSessionMem] =
    React.useState(SPENDING_SESSION_DATE_RANGES[0].value);
  const [searchAddonSessionMem, setSearchAddonSessionMem] = React.useState("");
  const [dateRangeAddonSessionMem, setDateRangeAddonSessionMem] =
    React.useState(ADDON_SESSION_DATE_RANGES[0].value);
  const [searchWorkingMem, setSearchWorkingMem] = React.useState("");
  const [dateRangeWorkingMem, setDateRangeWorkingMem] = React.useState(
    WORKING_DATE_RANGES[0].value
  );
  const [searchWSessionMem, setSearchWSessionMem] = React.useState("");
  const [dateRangeWSessionMem, setDateRangeWSessionMem] = React.useState(
    WORKING_SESSION_DATE_RANGES[0].value
  );
  const [parentPathMem, setParentPathMem] = React.useState("");
  const [currentPathMem, setCurrentPathMem] = React.useState("");
  return (
    <AppContext.Provider
      value={{
        parentPathMem,
        setParentPathMem,
        currentPathMem,
        setCurrentPathMem,
        searchMem,
        setSearchMem,
        dateRangeMem,
        setDateRangeMem,
        searchPosMem,
        setSearchPosMem,
        dateRangePosMem,
        setDateRangePosMem,
        searchSpendingSessionMem,
        setSearchSpendingSessionMem,
        dateRangeSpendingSessionMem,
        setDateRangeSpendingSessionMem,
        searchAddonSessionMem,
        setSearchAddonSessionMem,
        dateRangeAddonSessionMem,
        setDateRangeAddonSessionMem,
        searchWorkingMem,
        setSearchWorkingMem,
        searchBookingMem,
        setSearchBookingMem,
        dateRangeBookingMem,
        setDateRangeBookingMem,
        dateRangeWorkingMem,
        setDateRangeWorkingMem,
        dateRangeWSessionMem,
        setDateRangeWSessionMem,
        searchWSessionMem,
        setSearchWSessionMem,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
