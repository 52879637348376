import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";
import AddIcon from "@mui/icons-material/Add";
import { Grid, MenuItem, Select } from "@mui/material";
import ListHeader from "../../../../Header/ListHeader";
import ListSearch from "../../../../Header/ListSearch";
import moment from "moment";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import { AppContext } from "../../../../../context/AppProvider";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import {
  deleteWorking,
  getWorkingAll,
  getWorkingByDateRange,
  searchWorking,
  searchWorkingSession,
} from "../../../../../api/api";
import WorkingItem from "./WorkingItem";
import { WORKING_DATE_RANGES } from "../../../../../constants";
import { deleteItemSession } from "../WorkingSession/WorkingSession";

export default function Working() {
  const { pathname } = useLocation();
  // const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const { searchWorkingMem, setSearchWorkingMem } =
    React.useContext(AppContext);
  const { dateRangeWorkingMem, setDateRangeWorkingMem } =
    React.useContext(AppContext);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);
  // console.log({mountedRef});
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateDatas = React.useCallback(
    (newDatas, setInit = true) => {
      // console.log({ newDatas });
      if (userInfo?.role !== "admin") {
        newDatas = newDatas.filter((value, index, array) => {
          return value.userId?._id === userInfo?._id;
        });
      }
      newDatas.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      console.log({ newDatas });
      if (setInit) {
        setInitDatas(newDatas);
      }
      setDatas(newDatas);
      // console.log({ newDatas });
    },
    [userInfo?._id, userInfo?.role]
  );

  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchWorking(token, { search: value })
        .then((res) => {
          // console.log({ res });
          // console.log({mountedRef});
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      debounceFetcher(searchText);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching(true);
      const workingSessions = await searchWorkingSession(token, {
        search: item._id,
      }).then((res) => {
        if (res && res.status === 200) {
          return res.data;
        }
      });
      console.log({ workingSessions });
      let deletedCount = 0;
      let errorCount = 0;
      for (let i = 0; i < workingSessions.length; i++) {
        const sessionItem = workingSessions[i];
        console.log({ sessionItem });
        setProgress((i / workingSessions.length) * 100);
        const { calendarCode, serverCode } = await deleteItemSession(
          token,
          sessionItem
        );
        if (serverCode === 200) {
          deletedCount += 1;
        } else {
          errorCount += 1;
        }
        // await sleep(1, 100);
      }
      let alertContent;
      if (errorCount === 0) {
        await deleteWorking(token, item)
          .then(async (res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              updateDatas(null, null, null, item);
              alertContent = `Delete Working success! And (${deletedCount}) session(s) too!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      } else {
        alertContent = `Delete Working failed! Success: (${deletedCount}) session(s)! Error: (${errorCount}) session(s)`;
      }
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, bookings: false }));
      alert(alertContent);
      //   setRules(newRules);
    },
    [token, updateDatas]
  );
  React.useEffect(() => {
    async function doTask() {
      if (searchWorkingMem) {
        handleSearch(searchWorkingMem);
      } else {
        let alertContent;
        console.log("Getting workings...");
        setProgress(50);
        setFetching(true);
        if (dateRangeWorkingMem >= 0) {
          const startDate = moment()
            .subtract(dateRangeWorkingMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangeWorkingMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getWorkingByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getWorkingAll(token)
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    doTask();
  }, [dateRangeWorkingMem, handleSearch, searchWorkingMem, token, updateDatas]);
  let rows = [];
  for (var i = 0; i < datas.length; i++) {
    const item = datas[i];
    rows.push(
      <Grid item key={item._id}>
        <WorkingItem
          key={item._id}
          number={i + 1}
          item={item}
          deleteItem={deleteItem}
        />
      </Grid>
    );
  }
  const rightButton = {
    icon: <AddIcon fontSize="inherit" />,
    callback: function (event) {
      navigate(pathname + "/new");
    },
  };
  const selectBox = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={dateRangeWorkingMem}
      // label="Date Range"
      fullWidth
      onChange={(event) => {
        setDateRangeWorkingMem(event.target.value);
      }}
    >
      {WORKING_DATE_RANGES.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      <Header
        title="Working"
        rightButton={userInfo?.role === "admin" ? rightButton : null}
      />
      <ListSearch
        handleChange={(searchText) => {
          if (searchText) {
            handleSearch(searchText);
          } else {
            setDatas(initDatas);
          }
          setSearchWorkingMem(searchText);
        }}
        selectBox={selectBox}
        placeholder="Name | User Name | User Phone"
        value={searchWorkingMem}
      />
      <ListHeader
        titles={[
          { width: 1, align: "center", title: "No." },
          { width: 5, align: "left", title: "Title" },
          { width: 4, align: "center", title: "User Name" },
          { width: 2, align: "center", title: "Action" },
        ]}
      />
      <div className="list-item">
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <Grid container direction="column" spacing={0}>
            {rows?.length > 0 ? (
              rows
            ) : (
              <Grid item>
                <Box component="div" className="center-text">
                  No Working found!
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
