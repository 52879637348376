import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router";
import { arrayToString } from "../../helpers/stringArray";
import "./Header.css";
import { AppContext } from "../../context/AppProvider";

function getParent(pathname) {
  try {
    if (pathname === "/") {
      return null;
    }
    const paths = pathname.split("/");
    // console.log({ paths });
    if (paths.length > 1) {
      paths.pop();
      //   console.log({ newPaths: paths });
      let newPath = arrayToString(paths, "/");
      if (!newPath) {
        newPath += "/";
      }
      //   console.log({ newPath });
      return newPath;
    } else {
      return null;
    }
  } catch (err) {
    console.log({ err });
    return null;
  }
}

export default function Header({
  title,
  parentPath,
  rightButton,
  rightElement,
  triggerParentClick = false,
}) {
  const navigate = useNavigate();
  const { parentPathMem, setParentPathMem, currentPathMem, setCurrentPathMem } =
    React.useContext(AppContext);
  const { pathname } = useLocation();
  // console.log({ pathname });
  const handleParentClick = React.useCallback(
    (event) => {
      if (parentPath) {
        navigate(parentPath);
      } else if (parentPathMem && currentPathMem === pathname) {
        // console.log({ parentPathMem });
        navigate(parentPathMem);
        setParentPathMem("");
        setCurrentPathMem("");
      } else {
        navigate(getParent(pathname));
      }
    },
    [
      currentPathMem,
      navigate,
      parentPath,
      parentPathMem,
      pathname,
      setCurrentPathMem,
      setParentPathMem,
    ]
  );
  const handleHomeClick = React.useCallback(
    (event) => {
      navigate("/");
    },
    [navigate]
  );
  if (triggerParentClick) {
    handleParentClick();
  }
  let rightButtonUI;
  if (rightButton) {
    rightButtonUI = (
      <IconButton
        aria-label="back"
        size="medium"
        onClick={(event) => rightButton.callback(event)}
      >
        {rightButton.icon}
      </IconButton>
    );
  } else if (rightElement) {
    rightButtonUI = rightElement;
  }
  return (
    <Grid container direction="row" spacing={0} className="section-header">
      <Grid item xs={1}>
        {getParent(pathname) ? (
          <IconButton
            aria-label="back"
            size="medium"
            onClick={(event) => handleParentClick(event)}
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs>
        <Grid container direction="row" spacing={1} justifyContent="center">
          <Grid item xs={1}>
            {getParent(pathname) ? (
              <IconButton
                aria-label="back"
                size="medium"
                onClick={(event) => handleHomeClick(event)}
              >
                <HomeIcon fontSize="inherit" />
              </IconButton>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h4" className="section-name">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {rightButtonUI}
      </Grid>
    </Grid>
  );
}
