import "./SpendingMenu.css";
import React from "react";
import { Button, Grid } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../../Header/Header";
import { SPENDING_MENU, SPENDING_MENU_STAFF } from "./constants";
import Spending from "./Spending/Spending";
import SpendingCate from "./SpendingCate/SpendingCate";
import SpendingSession from "./SpendingSession/SpendingSession";
import EditSpendingSession from "./SpendingSession/EditSpendingSession";
import { AuthContext } from "../../../../context/AuthProvider";

export default function SpendingMenu() {
  const { token, userInfo } = React.useContext(AuthContext);
  //   const { path } = useMatch();
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();

  const handleItemClick = React.useCallback(
    (event, type) => {
      console.log({ type });
      navigate("/" + paths[1] + "/" + type);
    },
    [navigate, paths]
  );

  if (paths.length === 2) {
    let menu = SPENDING_MENU;
    if (userInfo?.role === "staff") {
      menu = SPENDING_MENU_STAFF;
    }
    return (
      <>
        <Header title="Spending Menu" />
        <Grid container direction="column" spacing={0}>
          {menu.map((item) => (
            <Grid item key={item.type}>
              <Button
                variant="outlined"
                startIcon={item.icon}
                fullWidth
                className="btn-item-settings"
                size="large"
                color="secondary"
                onClick={(event) => handleItemClick(event, item.type)}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <Routes>
        <Route element={<Spending />} exact path="/spending" />
        <Route element={<Spending />} path="/spending/:id" />
        <Route element={<Spending />} exact path="/spending/new" />
        <Route element={<SpendingCate />} exact path="/spendingCate" />
        <Route element={<SpendingCate />} path="/spendingCate/:id" />
        <Route element={<SpendingCate />} exact path="/spendingCate/new" />
        <Route element={<SpendingSession />} exact path="/spendingSession" />
        <Route element={<EditSpendingSession />} path="/spendingSession/:id" />
        <Route
          element={<EditSpendingSession />}
          exact
          path="/spendingSession/new"
        />
      </Routes>
    );
  }
}
