export const STATISTICAL_MENU = [
  {
    text: "Room",
    icon: undefined,
    type: "room",
  },
  {
    text: "POS",
    icon: undefined,
    type: "pos",
  },
  {
    text: "Spending",
    icon: undefined,
    type: "spending",
  },
  {
    text: "Staff",
    icon: undefined,
    type: "staff",
  },
  {
    text: "Overview",
    icon: undefined,
    type: "overview",
  },
];
