import React from "react";
import { LOCAL_STORAGE_KEY_TOKEN } from "../../constants";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN); //sessionStorage | localStorage
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };
  const [token, setToken] = React.useState(getToken());
  const saveToken = (userToken) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
