import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  createClass,
  getBookingSummary,
  getClassById,
  getClassSummary,
  searchClass,
  updateClass,
} from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";
import moment from "moment";
import {
  BOOKING_STATUS,
  BOOKING_SUMMARY_TYPE_NOW_ALL,
  CLASS_STATUS,
  CLASS_STATUS_OPTIONS,
} from "../../../../constants";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import "moment/locale/vi";
import {
  convertTimeToHourMin,
  numberWithCommas,
} from "../../../../helpers/stringDate";
import { Typography, Link } from "@mui/material";
import "./BookingSummary.css";
import { Box } from "@mui/system";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";

function BookingClassItem({ data }) {
  const status = CLASS_STATUS.filter((value, index, array) => {
    return value.value === data?.status;
  })[0];
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {data?.classNo + ". "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        <Link
          href={
            window.location.protocol +
            "//" +
            window.location.host +
            "/class/" +
            data?.data._id
          }
        >
          {moment(data?.data.startTime).format("DD/MM/yyyy HH:mm") +
            "-" +
            moment(data?.data.endTime).format("HH:mm")}
        </Link>
      </Box>
      <Box component="div" sx={{ fontWeight: "regular" }}>
        {`(${data?.hour} hours - ${status.label} - Room: ${
          data?.data.roomId.name
        } - Price/Hour: ${numberWithCommas(
          data?.data?.pricePerHour,
          " đ"
        )} - Addon: ${numberWithCommas(
          data?.addonFee,
          " đ"
        )} - Move: ${numberWithCommas(
          data?.moveFee,
          " đ"
        )} - Cancel: ${numberWithCommas(
          data?.cancelFee,
          " đ"
        )} - Payed: ${numberWithCommas(data?.data.payed, " đ")}${
          data?.data.note ? " - " + data?.data.note : ""
        })`}
      </Box>
    </Grid>
  );
}

function InvoiceClassItem({ label, value }) {
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        {label + ": "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {value}
      </Box>
    </Grid>
  );
}

export default function BookingSummary() {
  const { id } = useParams();
  const bookingId = id;
  console.log({ bookingId });
  moment.locale("vi");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [fetching, setFetching] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    async function doTask() {
      let alertContent;
      setProgress(50);
      setFetching(true);
      if (bookingId) {
        console.log("Getting booking summary...");
        setFetching(true);
        await getBookingSummary(token, bookingId)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const item = res.data;
              console.log({ item });
              setData(item);
              // setFetching(false);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
            // setFetching(false);
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
    }
    doTask();
  }, [bookingId, token]);

  const status = BOOKING_STATUS.filter((value, index, array) => {
    return data?.data.status === value.value;
  })[0];

  const bookingSummaryItems = [
    {
      label: "Tình trạng (Status)",
      value: function (key) {
        return status?.label;
      },
    },
    {
      label: "Tổng số lớp (Total Classes)",
      value: function (key) {
        return data?.classCount[key];
      },
    },
    {
      label: "Tỷ lệ huỷ (Cancel percent)",
      value: function (key) {
        return (data?.canceledPercent[key] * 100).toFixed(2) + "%";
      },
    },
    {
      label: "Tổng số giờ tạm dừng (Pending hours)",
      value: function (key) {
        return data?.totalHoursPending[key];
      },
    },
    {
      label: "Tổng số giờ sử dụng (Total use hours)",
      value: function (key) {
        return data?.totalHoursInUsed[key];
      },
    },
    {
      label: "Tổng phí sử dụng (Total use fee)",
      value: function (key) {
        return numberWithCommas(data?.totalFeeInUsed[key], " đ");
      },
    },
    // {
    //   label: "Tổng phí sử dụng đã giảm (Total fee with discount)",
    //   value: numberWithCommas(
    //     classSummary?.bookingSummary.totalFeeWithDiscount,
    //     " đ"
    //   ),
    // },
    {
      label: "Tổng số giờ huỷ (Total cancel hours)",
      value: function (key) {
        return data?.totalHoursCanceled[key].toFixed(2);
      },
    },
    {
      label: "Tổng phí huỷ (Total cancel fee)",
      value: function (key) {
        return numberWithCommas(data?.totalFeeCanceled[key], " đ");
      },
    },
    {
      label: "Tổng phí dời đổi (Total change fee)",
      value: function (key) {
        return numberWithCommas(data?.totalFeeMoved[key], " đ");
      },
    },
    {
      label: "Tổng phí (Sub Total)",
      value: function (key) {
        return numberWithCommas(data?.totalFee[key], " đ");
      },
    },
    {
      label: "Giảm giá (Discount)",
      value: function (key) {
        return data?.discountPercent * 100 + "%";
      },
    },
    {
      label: "Tổng phí đã giảm (Total fee)",
      value: function (key) {
        return numberWithCommas(data?.totalFeeWithDiscount[key], " đ");
      },
    },
    {
      label: "Tổng phí dịch vụ (Total add-on fee)",
      value: function (key) {
        return numberWithCommas(data?.totalAddonFee[key], " đ");
      },
    },
    {
      label: "Tổng (Total)",
      value: function (key) {
        return numberWithCommas(data?.total[key], " đ");
      },
    },
    {
      label: "Tổng VAT (Total VAT fee)",
      value: function (key) {
        return numberWithCommas(data?.totalVAT[key], " đ");
      },
    },
    {
      label: "Tổng + VAT (Sub-Total)",
      value: function (key) {
        return numberWithCommas(data?.totalWithVAT[key], " đ");
      },
    },
    {
      label: "Đã thanh toán (Total payed)",
      value: function (key) {
        return numberWithCommas(data?.totalPayed[key], " đ");
      },
    },
    {
      label: "Đặt cọc (Deposit)",
      value: function (key) {
        return numberWithCommas(data?.data.deposit, " đ");
      },
    },
    {
      label: "Tổng kết",
      value: function (key) {
        return data?.remain[key] > 0
          ? "Khách cần trả thêm " + numberWithCommas(data?.remain[key], " đ")
          : "Cần trả lại khách " + numberWithCommas(-data?.remain[key], " đ");
      },
    },
  ];
  return (
    <>
      <Header title="Booking Summary" />
      {fetching ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <div className="content">
          {BOOKING_SUMMARY_TYPE_NOW_ALL.map((type) => (
            <div key={type.value}>
              <Typography
                variant="h4"
                component="div"
                className="booking-summary-header"
              >
                {`${type.name}`}
              </Typography>
              <Grid
                container
                direction="column"
                spacing={1}
                className="invoice"
              >
                {bookingSummaryItems.map((item) => (
                  <InvoiceClassItem
                    key={item.label}
                    label={item.label}
                    value={item.value(type.value)}
                  />
                ))}
              </Grid>
            </div>
          ))}
          <Typography
            variant="h4"
            component="div"
            className="booking-summary-header"
          >
            Classes
          </Typography>
          <Grid container direction="column" spacing={1} className="invoice">
            {data?.classSummaries.all.length > 0 ? (
              data?.classSummaries.all.map((item) => (
                <BookingClassItem key={item.data._id} data={item} />
              ))
            ) : (
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "bold" }}
              >
                No class in this booking!
              </Box>
            )}
          </Grid>
        </div>
      )}
    </>
  );
}
