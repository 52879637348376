export const sleep = (x, milliseconds) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(x);
    }, milliseconds)
  );
};

// Generate a random number between 2 and 10, including both 2 and 10
export function generateRandomIntegerInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Generate a random number between 2.5 and 10.75, including both 2.5 and 10.75
export function generateRandomFloatInRange(min, max) {
  return (Math.random() * (max - min + 1)) + min;
}