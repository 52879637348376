export const WORKING_MENU = [
  {
    text: "Working Session",
    icon: undefined,
    type: "workingSession",
  },
  {
    text: "Working",
    icon: undefined,
    type: "working",
  },
  {
    text: "Working Room",
    icon: undefined,
    type: "workingRoom",
  },
  {
    text: "Allowance",
    icon: undefined,
    type: "allowance",
  },
];

export const WORKING_MENU_STAFF = [
  {
    text: "Working Session",
    icon: undefined,
    type: "workingSession",
  },
  {
    text: "Working",
    icon: undefined,
    type: "working",
  },
];
