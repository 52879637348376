import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { updatePassword } from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";

export default function UpdatePassword({ itemId, setDataList }) {
  //   console.log({ datas });
  const { token, userInfo } = React.useContext(AuthContext);
  const [oldPassword, setOldPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const showOldPasswordField = React.useMemo(() => {
    if (userInfo?._id === itemId) {
      return true;
    } else {
      return false;
    }
  }, [itemId, userInfo?._id]);
  const updateItemSuccess = React.useCallback(
    (res) => {
      console.log({ res });
      if (res && res.status === 200) {
        alert("Update Successfully!");
        setDataList(itemId, res);
      }
    },
    [itemId, setDataList]
  );

  const handleSubmit = React.useCallback(
    (event) => {
      if (password !== confirmPassword) {
        alert("Password & Confirm Password must be same!");
      } else {
        const requestBody = {
          _id: itemId,
          oldPassword,
          password,
        };
        console.log({ requestBody });
        updatePassword(token, requestBody)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
            alert(err.response?.data?.error);
          });
      }
    },
    [token, itemId, oldPassword, password, confirmPassword, updateItemSuccess]
  );

  return (
    <>
      <Header title={"Update Password"} />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            label="Old Password"
            fullWidth
            variant="outlined"
            value={oldPassword}
            disabled={!showOldPasswordField}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Confirm Password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
