import "./WorkingMenu.css";
import React from "react";
import { Button, Grid } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../../Header/Header";
import { WORKING_MENU, WORKING_MENU_STAFF } from "./constants";
import Working from "./Working/Working";
import EditWorking from "./Working/EditWorking";
import WorkingSummary from "./Working/WorkingSummary";
import WorkingRoom from "./WorkingRoom/WorkingRoom";
import WorkingSession from "./WorkingSession/WorkingSession";
import EditWorkingSession from "./WorkingSession/EditWorkingSession";
import WorkingSessionSummary from "./WorkingSession/WorkingSessionSummary";
import EditSpendingSession from "../Spending/SpendingSession/EditSpendingSession";
import WorkingSessionAllowance from "./WorkingSession/WorkingSessionAllowance";
import Allowance from "./Allowance/Allowance";
import { AuthContext } from "../../../../context/AuthProvider";
import EditAddonSession from "../Addon/AddonSession/EditAddonSession";

export default function WorkingMenu() {
  //   const { path } = useMatch();
  const { token, userInfo } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();

  const handleItemClick = React.useCallback(
    (event, type) => {
      console.log({ type });
      navigate("/" + paths[1] + "/" + type);
    },
    [navigate, paths]
  );

  if (paths.length === 2) {
    let menu = WORKING_MENU;
    if (userInfo?.role === "staff") {
      menu = WORKING_MENU_STAFF;
    }
    return (
      <>
        <Header title="Working Menu" />
        <Grid container direction="column" spacing={0}>
          {menu.map((item) => (
            <Grid item key={item.type}>
              <Button
                variant="outlined"
                startIcon={item.icon}
                fullWidth
                className="btn-item-settings"
                size="large"
                color="secondary"
                onClick={(event) => handleItemClick(event, item.type)}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <Routes>
        <Route element={<Allowance />} exact path="/allowance" />
        <Route element={<Allowance />} path="/allowance/:id" />
        <Route element={<Allowance />} exact path="/allowance/new" />
        <Route element={<WorkingRoom />} exact path="/workingRoom" />
        <Route element={<WorkingRoom />} path="/workingRoom/:id" />
        <Route element={<WorkingRoom />} exact path="/workingRoom/new" />
        <Route element={<Working />} exact path="/working" />
        <Route element={<EditWorking />} path="/working/:id" />
        <Route element={<EditWorking />} exact path="/working/new" />
        <Route element={<WorkingSummary />} path="/working/:id/summary" />
        <Route element={<WorkingSession />} exact path="/workingSession" />
        <Route element={<EditWorkingSession />} path="/workingSession/:id" />
        <Route
          element={<EditWorkingSession />}
          exact
          path="/workingSession/new"
        />
        <Route
          element={<WorkingSessionSummary />}
          path="/workingSession/:id/summary"
        />
        <Route
          element={<WorkingSessionAllowance />}
          path="/workingSession/:id/allowance"
        />
        <Route
          element={<EditSpendingSession />}
          path="/workingSession/:workingSessionId/spending"
        />
        <Route
          element={<EditAddonSession />}
          path="/workingSession/:workingSessionId/addon"
        />
      </Routes>
    );
  }
}
