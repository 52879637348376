import HighchartsReact from "highcharts-react-official";
import Highchart from "highcharts";
import React from "react";
import moment from "moment";
// import { Button, ButtonGroup, Grid } from "@material-ui/core";
// import useStyles from "./styles";
import { Button, ButtonGroup, Grid } from "@mui/material";

const generateOptions = (title, xProps, datas, type, types) => {
  //   const categories = data.map((item) => moment(item.date).format("DD-MMM-YY"));
  let series = [];
  if (type.type) {
    series = [
      {
        name: type.name,
        data: datas.map((dataItem) => dataItem[type.type]),
      },
    ];
  } else {
    for (let i = 0; i < types.length; i++) {
      const typeItem = types[i];
      if (typeItem.type) {
        series.push({
          name: typeItem.name,
          data: datas.map((dataItem) => dataItem[typeItem.type]),
        });
      }
    }
  }
  const colors = [];
  for (let i = 0; i < types.length; i++) {
    const typeItem = types[i];
    if (typeItem.type) {
      colors.push(typeItem.color);
    }
  }
  console.log({ series });
  console.log({ colors });
  return {
    chart: {
      type: "bar",
    },
    title: {
      text: title,
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      categories: xProps.categories,
      title: {
        text: xProps.title,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: type.title,
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    colors,
    tooltip: {
      valueSuffix: " millions",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series,
  };
};

//datas = [{name, type, data}]
//types = [{name, type}, {name, type}];
const BarChart = ({ title, xProps, datas, types }) => {
  //   const classes = useStyles();
  console.log({ xProps });
  console.log({ datas });
  const [options, setOptions] = React.useState([]);
  //   const [duration, setDuration] = React.useState(-1);
  const [type, setType] = React.useState(types[0]);
  React.useEffect(() => {
    // const offset = data.length - (duration !== -1 ? duration : data.length);
    // console.log("[offset]", offset);
    // console.log("[data.length]", data.length);
    // const durationData = data?.slice(offset) || [];
    // console.log("[durationData]", durationData);
    const newOptions = generateOptions(title, xProps, datas, type, types);
    setOptions(newOptions);
  }, [datas, title, type, types, xProps]);
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={1}
        style={{ marginBottom: "10px" }}
      >
        <Grid item>
          <ButtonGroup size="small" variant="outlined" color="primary">
            {types?.map((item) => (
              <Button
                key={item.type}
                color={"secondary"}
                onClick={() => setType(item)}
              >
                {item.name}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
      <HighchartsReact highcharts={Highchart} options={options} />
    </div>
  );
};

export default React.memo(BarChart);
