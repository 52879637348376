import "./AddonMenu.css";
import React from "react";
import { Button, Grid } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../../Header/Header";
import { ADDON_MENU, ADDON_MENU_STAFF } from "./constants";
import Addon from "./Addon/Addon";
import AddonCate from "./AddonCate/AddonCate";
import AddonSession from "./AddonSession/AddonSession";
import EditAddonSession from "./AddonSession/EditAddonSession";
import { AuthContext } from "../../../../context/AuthProvider";

export default function AddonMenu() {
  const { token, userInfo } = React.useContext(AuthContext);
  //   const { path } = useMatch();
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();

  const handleItemClick = React.useCallback(
    (event, type) => {
      console.log({ type });
      navigate("/" + paths[1] + "/" + type);
    },
    [navigate, paths]
  );

  if (paths.length === 2) {
    let menu = ADDON_MENU;
    if (userInfo?.role === "staff") {
      menu = ADDON_MENU_STAFF;
    }
    return (
      <>
        <Header title="Addon Menu" />
        <Grid container direction="column" spacing={0}>
          {menu.map((item) => (
            <Grid item key={item.type}>
              <Button
                variant="outlined"
                startIcon={item.icon}
                fullWidth
                className="btn-item-settings"
                size="large"
                color="secondary"
                onClick={(event) => handleItemClick(event, item.type)}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <Routes>
        <Route element={<Addon />} exact path="/addon" />
        <Route element={<Addon />} path="/addon/:id" />
        <Route element={<Addon />} exact path="/addon/new" />
        <Route element={<AddonCate />} exact path="/addonCate" />
        <Route element={<AddonCate />} path="/addonCate/:id" />
        <Route element={<AddonCate />} exact path="/addonCate/new" />
        <Route element={<AddonSession />} exact path="/addonSession" />
        <Route element={<EditAddonSession />} path="/addonSession/:id" />
        <Route element={<EditAddonSession />} exact path="/addonSession/new" />
      </Routes>
    );
  }
}
