import axios from "axios";
import React from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthProvider";
import { logout } from "../Login/Login";

// const DEBUG = process.env.NODE_ENV === "development";
const DEBUG = false;
const STAFF_RIGHTS = [
  { method: "post", pathNameRegex: /\/pos\/dateRange/gi },
  { method: "post", pathNameRegex: /\/pos\/search/gi },
  { method: "get", pathNameRegex: /\/addon/gi },
  { method: "get", pathNameRegex: /\/pos\/latest/gi },
  { method: "post", pathNameRegex: /\/pos/gi },
  { method: "get", pathNameRegex: /\/pos\/\w{24}/gi },
  { method: "post", pathNameRegex: /\/pos\/update/gi },
  { method: "post", pathNameRegex: /\/pos\/delete/gi }, //admin
  { method: "post", pathNameRegex: /\/booking\/dateRange/gi }, //admin
  { method: "post", pathNameRegex: /\/booking\/search/gi }, //admin
  { method: "get", pathNameRegex: /\/setting\/discountFixeds/gi }, //admin
  { method: "get", pathNameRegex: /\/customer/gi }, //admin
  { method: "post", pathNameRegex: /\/booking/gi }, //admin
  { method: "get", pathNameRegex: /\/booking\/\w{24}/gi }, //admin
  { method: "post", pathNameRegex: /\/class\/search/gi },
  { method: "post", pathNameRegex: /\/booking\/delete/gi }, //admin
  { method: "post", pathNameRegex: /\/booking\/update/gi }, //admin
  { method: "post", pathNameRegex: /\/class\/dateRange/gi },
  { method: "post", pathNameRegex: /\/class\/search/gi },
  { method: "get", pathNameRegex: /\/setting\/operationPercent/gi },
  { method: "get", pathNameRegex: /\/room/gi },
  { method: "post", pathNameRegex: /\/booking\/prop/gi },
  { method: "post", pathNameRegex: /\/class/gi },
  { method: "get", pathNameRegex: /\/class\/\w{24}/gi },
  { method: "post", pathNameRegex: /\/class\/update/gi },
  { method: "post", pathNameRegex: /\/class\/delete/gi }, //admin
];
const USER_RIGHTS = {
  staff: [...STAFF_RIGHTS],
  admin: [...STAFF_RIGHTS, "/booking/dateRange"],
};
const setupInterceptors = (navigate, userInfo) => {
  axios.interceptors.request.use(
    (config) => {
      const url = document.createElement("a");
      url.href = config.url;
      // el.host        // www.somedomain.com (includes port if there is one[1])
      // el.hostname    // www.somedomain.com
      // el.hash        // #top
      // el.href        // http://www.somedomain.com/account/search?filter=a#top
      /** In dev, intercepts request and logs it into console for dev */
      if (DEBUG) {
        // console.info("✉️ ", config);
        console.info("url", config.method + ":" + url.pathname);
        // console.info("userInfo", userInfo);
        // console.info("pathname", url.pathname);
      }
      return config;
    },
    (error) => {
      if (DEBUG) {
        console.error("✉️ ", error);
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          //   window.alert("Your login session is expired! Please login again!");
          logout(navigate, null);
        }
      }
      return Promise.reject(error);
    }
  );
};

export default function MyNavigator(props) {
  let navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  setupInterceptors(navigate, userInfo);
  return <></>;
}
