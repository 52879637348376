import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import {
  getOperationPercent,
  updateOperationPercent,
} from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";

export default function OperationPercent() {
  const [cancel, setCancel] = React.useState(0);
  const [move, setMove] = React.useState(0);
  const [vat, setVat] = React.useState(0);
  const { token } = React.useContext(AuthContext);
  React.useEffect(() => {
    getOperationPercent(token)
      .then((res) => {
        if (res && res.status === 200) {
          setCancel(res.data.cancel * 100);
          setMove(res.data.move * 100);
          setVat(res.data.vat * 100);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token]);
  const handleSubmit = React.useCallback(
    (event) => {
      const newData = {
        cancel: cancel / 100,
        move: move / 100,
        vat: vat / 100,
      };
      updateOperationPercent(token, newData)
        .then((res) => {
          alert("Update Successfully!");
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    [cancel, move, vat, token]
  );
  return (
    <>
      <Header title="Operation Percent" />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Cancel (%)"
            fullWidth
            variant="outlined"
            value={cancel}
            onChange={(e) => setCancel(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Move (%)"
            fullWidth
            variant="outlined"
            value={move}
            onChange={(e) => setMove(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="VAT (%)"
            fullWidth
            variant="outlined"
            value={vat}
            onChange={(e) => setVat(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
