import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router";
import "./WorkingSessionAllowance.css";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { numberWithCommas } from "../../../../../helpers/stringDate";
import { AuthContext } from "../../../../../context/AuthProvider";
import {
  getAllowanceAll,
  getWorkingSessionById,
  updateWorkingSession,
} from "../../../../../api/api";
import Header from "../../../../Header/Header";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";

class AllowanceSessionModel {
  constructor(allowanceId, name, quantity, price) {
    this.allowanceId = allowanceId;
    this.name = name;
    this.quantity = quantity;
    this.price = price;
  }
}

function AllowanceItem({ item, handleItemClick }) {
  return (
    <Button
      variant="contained"
      className="btn-allowance-item"
      size="medium"
      color="primary"
      onClick={(event) => handleItemClick(event, item)}
    >
      {item.name}
    </Button>
  );
}

function AllowanceSessionItem({ item, handleReduce, handleRemove }) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>{item.name + " (" + numberWithCommas(item.price, " đ)")}</Grid>
      <Grid item className="allowance-session-quantity">
        x {item.quantity}
      </Grid>
      <Grid item xs></Grid>
      <Grid item>
        <IconButton
          aria-label="reduce"
          size="medium"
          onClick={(event) => handleReduce(event, item)}
        >
          <RemoveCircleOutlineIcon
            className={"allowance-session-list-icon"}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="remove"
          size="medium"
          onClick={(event) => handleRemove(event, item)}
        >
          <DeleteIcon
            className={"allowance-session-list-icon"}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default function WorkingSessionAllowance() {
  const { id } = useParams();
  const sessionId = id;
  console.log({ sessionId });
  moment.locale("vi");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [allowances, setAllowances] = React.useState([]);
  const [allowancesSession, setAllowancesSession] = React.useState([]);
  const [sessionData, setSessionData] = React.useState(null);
  // const [posData, setPosData] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [fetching, setFetching] = React.useState({
    allowances: false,
    session: false,
  });
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  console.log({ allowancesSession });
  React.useEffect(() => {
    let sum = 0;
    allowancesSession.forEach((item) => {
      sum += item.quantity * item.price;
    });
    setTotal(sum);
  }, [allowancesSession]);
  React.useEffect(() => {
    async function doTask() {
      if (sessionId) {
        console.log("Getting allowances...");
        setProgress(10);
        setFetching((prevState) => ({ ...prevState, allowances: true }));
        let alertContent;
        await getAllowanceAll(token)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const allowanceList = res.data;
              console.log({ allowanceList });
              setAllowances(allowanceList);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(50);
        setFetching((prevState) => ({ ...prevState, allowances: false }));
        console.log("Getting working session...");
        setFetching((prevState) => ({ ...prevState, session: true }));
        await getWorkingSessionById(token, sessionId)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const resData = res.data;
              console.log({ resData });
              setSessionData(resData);
              setAllowancesSession(resData.allowances);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, session: false }));
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    doTask();
  }, [sessionId, token]);

  const getAllowancePrice = React.useCallback((allowance) => {
    if (allowance.price === 0) {
      let price = prompt(`Please enter price for "${allowance.name}"`, 0);
      console.log({ price });
      if (price === null) {
        return 0;
      } else {
        return parseInt(price);
      }
    } else {
      return allowance.price;
    }
  }, []);

  const handleAllowanceClick = React.useCallback(
    (event, allowance) => {
      console.log({ allowance });
      let allowanceSessionIndex = -1;
      for (let i = 0; i < allowancesSession.length; i++) {
        const item = allowancesSession[i];
        if (item.allowanceId._id === allowance._id) {
          allowanceSessionIndex = i;
          break;
        }
      }
      if (allowanceSessionIndex >= 0) {
        //update addon's quantity already had in class
        //   addonsClass[addonClassIndex].quantity += 1;
        setAllowancesSession((prevState) =>
          prevState.map((item) => {
            if (item.allowanceId._id === allowance._id) {
              return new AllowanceSessionModel(
                item.allowanceId,
                item.name,
                item.quantity + 1,
                item.price
              );
            } else {
              return item;
            }
          })
        );
      } else {
        //add new addon to class
        setAllowancesSession((prevState) => [
          ...prevState,
          new AllowanceSessionModel(
            allowance,
            allowance.name,
            1,
            getAllowancePrice(allowance)
          ),
        ]);
      }
    },
    [allowancesSession, getAllowancePrice]
  );

  const handleSubmit = React.useCallback(
    async (ev) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, session: true }));
      let alertContent;
      let newItem = {
        ...sessionData,
        allowances: allowancesSession.map((item) => ({
          allowanceId: item.allowanceId._id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
        })),
        lastUpdatedBy: userInfo._id,
      };
      console.log({ newItem });
      await updateWorkingSession(token, newItem)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            alertContent = "Update success!";
          } else {
            alertContent = `Error: ${res.status}`;
          }
        })
        .catch((err) => {
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, session: false }));
      if (alertContent) {
        alert(alertContent);
      }
    },
    [allowancesSession, sessionData, token, userInfo._id]
  );

  const handleRemoveAllowance = React.useCallback((event, allowanceSession) => {
    setAllowancesSession((prevState) =>
      prevState.filter((value, index, array) => {
        return value.allowanceId._id !== allowanceSession.allowanceId._id;
      })
    );
  }, []);

  const handleReduceQuantity = React.useCallback(
    (event, allowanceSession) => {
      let remove = false;
      allowancesSession.forEach((item) => {
        if (
          item.allowanceId._id === allowanceSession.allowanceId._id &&
          item.quantity === 1
        ) {
          remove = true;
          return;
        }
      });
      if (remove) {
        handleRemoveAllowance(event, allowanceSession);
      } else {
        setAllowancesSession((prevState) =>
          prevState.map((item) => {
            if (item.allowanceId._id === allowanceSession.allowanceId._id) {
              return new AllowanceSessionModel(
                item.allowanceId,
                item.name,
                item.quantity - 1,
                item.price
              );
            } else {
              return item;
            }
          })
        );
      }
    },
    [allowancesSession, handleRemoveAllowance]
  );
  return (
    <>
      <Header title="Allowances" />
      {fetching.allowances || fetching.session ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <div className="content">
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "regular" }}
              >
                {"Total: "}
              </Box>
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "bold" }}
              >
                {numberWithCommas(total, " đ")}
              </Box>
            </Grid>
            <Grid item>
              <div className="allowance-list">
                <Grid container direction="row" spacing={1}>
                  {allowances.map((item) => {
                    return (
                      <Grid item key={item._id}>
                        <AllowanceItem
                          item={item}
                          handleItemClick={handleAllowanceClick}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item>
              <div className="allowance-session-list">
                <Grid container direction="column" spacing={1}>
                  {allowancesSession.map((item) => (
                    <Grid item key={item.allowanceId._id}>
                      <AllowanceSessionItem
                        item={item}
                        handleReduce={handleReduceQuantity}
                        handleRemove={handleRemoveAllowance}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
            <Grid item className={!sessionData ? "no-display" : ""}>
              <Typography component="div">
                {`Last updated by: ${sessionData?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
