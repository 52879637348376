import { Button, Grid, TextareaAutosize, TextField } from "@mui/material";
import React from "react";
import { updateSMSContent } from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";
import { getSMSType } from "./helpers";
import Autocomplete from "@mui/material/Autocomplete";
import { LANGUAGE_OPTIONS } from "../../../../../../constants";

export default function EditSMSContent({ itemId, datas, setDatas, type }) {
  const getItem = React.useCallback(
    (id) => {
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === id) {
          return datas[i];
        }
      }
    },
    [datas]
  );
  let item = null;
  if (itemId) {
    item = getItem(itemId);
  }
  console.log({ item });
  const { token } = React.useContext(AuthContext);
  const [language, setLanguage] = React.useState(
    item ? item.language : LANGUAGE_OPTIONS[0]
  );
  const [content, setContent] = React.useState(item?.content);
  const updateItemSuccess = React.useCallback(
    (res) => {
      console.log({ res });
      if (res && res.status === 200) {
        alert(itemId ? "Update Successfully!" : "Create Successfully!");
        setDatas(res.data);
      }
    },
    [setDatas, itemId]
  );
  const handleSubmit = React.useCallback(
    (event) => {
      let newDatas;
      if (itemId) {
        const newItem = {
          _id: item._id,
          language: language,
          content: content,
        };
        console.log({ newItem });
        newDatas = datas.map((item) => {
          if (item._id === newItem._id) {
            return newItem;
          } else {
            return item;
          }
        });
      } else {
        const newItem = {
          language: language,
          content: content,
        };
        console.log({ newItem });
        newDatas = datas;
        newDatas.push(newItem);
      }
      updateSMSContent(token, newDatas, type)
        .then((res) => {
          updateItemSuccess(res);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    [
      content,
      item?._id,
      language,
      token,
      datas,
      updateItemSuccess,
      type,
      itemId,
    ]
  );
  return (
    <>
      <Header title={(itemId ? "Edit " : "New ") + getSMSType(type)?.text} />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Autocomplete
            className="sms-language-options"
            options={LANGUAGE_OPTIONS}
            value={language}
            getOptionLabel={(option) => option.toString()}
            onChange={(event, newValue) => {
              setLanguage(newValue);
              // console.log({newValue});
            }}
            id="sms-language-options"
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Language" variant="outlined" />
            )}
          />
          {/* <TextField
            label="Language"
            fullWidth
            variant="outlined"
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          /> */}
        </Grid>
        <Grid item>
          <TextareaAutosize
            minRows={10}
            maxRows={18}
            aria-label="Content"
            placeholder="Maximum 18 rows"
            defaultValue={content}
            style={{ width: "100%" }}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
