import HighchartsReact from "highcharts-react-official";
import Highchart from "highcharts";
import React from "react";
import moment from "moment";
// import { Button, ButtonGroup, Grid } from "@material-ui/core";
// import useStyles from "./styles";
import { Button, ButtonGroup, Grid } from "@mui/material";

const generateOptions = (title, xProps, datas, type) => {
  //   const categories = data.map((item) => moment(item.date).format("DD-MMM-YY"));
  const series = datas.map((dataItem) => ({
    name: dataItem.name,
    data: dataItem.data.map((item) => item[type.type]),
  }));
  const colors = datas.map((dataItem) => dataItem.color);
  console.log({ series });
  console.log({ colors });
  return {
    chart: {
      height: 500,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: xProps.categories,
      crosshair: true,
      title: {
        text: xProps.title,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: type.name,
      },
      labels: {
        align: "right",
      },
    },
    colors,
    tooltip: {
      headerFormat: "<span style='font-size:10px'>{point.key}</span><table>",
      pointFormat:
        "<tr><td style='color:{series.color};padding:0'>{series.name}: </td>" +
        "<td style='padding:0'><b>{point.y} ca</b></td></tr>",
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series,
  };
};

//datas = [{title, data}]
//types = [{name, type}, {name, type}];
const TYPE = {
  one: "One",
  two: "Two",
};
const LineChart = ({ title, xProps, datas, types }) => {
  //   const classes = useStyles();
  console.log({ xProps });
  console.log({ datas });
  const [options, setOptions] = React.useState([]);
  //   const [duration, setDuration] = React.useState(-1);
  const [type, setType] = React.useState(types[0]);
  React.useEffect(() => {
    // const offset = data.length - (duration !== -1 ? duration : data.length);
    // console.log("[offset]", offset);
    // console.log("[data.length]", data.length);
    // const durationData = data?.slice(offset) || [];
    // console.log("[durationData]", durationData);
    const newOptions = generateOptions(title, xProps, datas, type);
    setOptions(newOptions);
  }, [datas, title, type, xProps]);
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={1}
        style={{ marginBottom: "10px" }}
      >
        <Grid item>
          <ButtonGroup size="small" variant="outlined" color="primary">
            {types?.map((item) => (
              <Button
                key={item.type}
                color={"secondary"}
                onClick={() => setType(item)}
              >
                {item.name}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
      <HighchartsReact highcharts={Highchart} options={options} />
    </div>
  );
};

export default React.memo(LineChart);
