import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import BookingItem from "./BookingItem";
import EditBooking from "./EditBooking";
import AddIcon from "@mui/icons-material/Add";
import {
  deleteBooking,
  deleteBookingClasses,
  deleteClass,
  getBookingAll,
  getBookingByDateRange,
  getCustomerAll,
  getDiscountFixeds,
  getWorkingAll,
  searchBooking,
  searchClass,
  updateBookingDiscountPercent,
} from "../../../../api/api";
import { Grid, CircularProgress, Box, Select, MenuItem } from "@mui/material";
import ListHeader from "../../../Header/ListHeader";
import moment from "moment";
import ListSearch from "../../../Header/ListSearch";
import { deleteItemClass } from "../Class/Class";
import { sleep } from "../../../../helpers/general";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import { AppContext } from "../../../../context/AppProvider";
import { BOOKING_DATE_RANGES } from "../../../../constants";
import { debounce } from "lodash";

export default function Booking() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { searchBookingMem, setSearchBookingMem } =
    React.useContext(AppContext);
  const { dateRangeBookingMem, setDateRangeBookingMem } =
    React.useContext(AppContext);
  const { token, userInfo } = React.useContext(AuthContext);
  const [fetching, setFetching] = React.useState({
    setting: false,
    customers: false,
    bookings: false,
  });
  const [search, setSearch] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const updateDatas = React.useCallback(
    (data, newItem, updatedItem, deletedItem) => {
      if (data) {
        data.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setInitDatas(data);
        setDatas(data);
      } else if (newItem) {
        setInitDatas((prevState) => {
          let tmp = [...prevState, newItem];
          tmp.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          return tmp;
        });
        setDatas((prevState) => {
          let tmp = [...prevState, newItem];
          tmp.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          return tmp;
        });
      } else if (updatedItem) {
        setInitDatas((prevState) => {
          let tmp = prevState.map((item) => {
            return item._id === updatedItem._id ? updatedItem : item;
          });
          tmp.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          return tmp;
        });
        setDatas((prevState) => {
          let tmp = prevState.map((item) => {
            return item._id === updatedItem._id ? updatedItem : item;
          });
          tmp.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          return tmp;
        });
      } else if (deletedItem) {
        setInitDatas((prevState) => {
          let tmp = prevState.filter((value, index, array) => {
            return value._id !== deletedItem._id;
          });
          tmp.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          return tmp;
        });
        setDatas((prevState) => {
          let tmp = prevState.filter((value, index, array) => {
            return value._id !== deletedItem._id;
          });
          tmp.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          return tmp;
        });
      }
    },
    []
  );
  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchBooking(token, { search: value })
        .then((res) => {
          // console.log({ res });
          // console.log({mountedRef});
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      debounceFetcher(searchText);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, bookings: true }));
      const bookingClasses = await searchClass(token, {
        search: item._id,
      }).then((res) => {
        if (res && res.status === 200) {
          return res.data;
        }
      });
      console.log({ bookingClasses });
      let deletedCount = 0;
      let errorCount = 0;
      for (let i = 0; i < bookingClasses.length; i++) {
        const classItem = bookingClasses[i];
        console.log({ classItem });
        setProgress((i / bookingClasses.length) * 100);
        const { calendarCode, classCode } = await deleteItemClass(
          token,
          classItem
          // () => {
          //   setFetching((prevState) => ({
          //     ...prevState,
          //     bookings: false,
          //   }));
          // },
          // () => {
          //   setFetching((prevState) => ({
          //     ...prevState,
          //     bookings: false,
          //   }));
          // }
        );
        if (classCode === 200) {
          deletedCount += 1;
        } else {
          errorCount += 1;
        }
        // await sleep(1, 100);
      }
      let alertContent;
      if (errorCount === 0) {
        await deleteBooking(token, item)
          .then(async (res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              updateDatas(null, null, null, item);
              // setProgress(100);
              // setFetching((prevState) => ({ ...prevState, bookings: false }));
              alertContent = `Delete booking success! And (${deletedCount}) class(es) too!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
            // setProgress(100);
            // setFetching((prevState) => ({ ...prevState, bookings: false }));
          });
      } else {
        alertContent = `Delete Booking failed! Success: (${deletedCount}) classes! Error: (${errorCount}) classes`;
      }
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, bookings: false }));
      alert(alertContent);
      //   setRules(newRules);
    },
    [token, updateDatas]
  );
  React.useEffect(() => {
    async function doTask() {
      if (searchBookingMem) {
        handleSearch(searchBookingMem);
      } else {
        let alertContent;
        console.log("Getting bookings...");
        setProgress(50);
        setFetching(true);
        if (dateRangeBookingMem >= 0) {
          const startDate = moment()
            .subtract(dateRangeBookingMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangeBookingMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getBookingByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getBookingAll(token)
            .then(async (res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
                // const bookingList = res.data;
                // for (let i = 0; i < bookingList.length; i++) {
                //   const item = bookingList[i];
                // if (item?.discountType === "rule") {
                //   await updateBookingDiscountPercent(token, item)
                //     .then((res) => {
                //       if (!mountedRef.current) return null;
                //       if (res && res.status === 200) {
                //         console.log({ booking: res });
                //       }
                //     })
                //     .catch((err) => {
                //       if (!mountedRef.current) return null;
                //       console.log({ err });
                //       // alertContent += "Update Booking Discount Percent failed! ";
                //     });
                // }
                // }
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    doTask();
  }, [dateRangeBookingMem, handleSearch, searchBookingMem, token, updateDatas]);
  let rows = [];
  for (var i = 0; i < datas.length; i++) {
    const item = datas[i];
    // if (
    //   userInfo?.role === "admin" ||
    //   (userInfo?.role === "staff" && userInfo?._id === item._id)
    // ) {
    rows.push(
      <Grid item key={item._id}>
        <BookingItem number={i + 1} item={item} deleteItem={deleteItem} />
      </Grid>
    );
    // }
  }
  const rightButton = {
    icon: <AddIcon fontSize="inherit" />,
    callback: function (event) {
      navigate(pathname + "/new");
    },
  };
  const selectBox = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={dateRangeBookingMem}
      // label="Date Range"
      fullWidth
      onChange={(event) => {
        setDateRangeBookingMem(event.target.value);
      }}
    >
      {BOOKING_DATE_RANGES.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      <Header title="Bookings" rightButton={rightButton} />
      <ListSearch
        handleChange={(searchText) => {
          if (searchText) {
            handleSearch(searchText);
          } else {
            setDatas(initDatas);
          }
          setSearchBookingMem(searchText);
        }}
        selectBox={selectBox}
        placeholder="Customer Name | Customer Phone"
        value={searchBookingMem}
      />
      <ListHeader
        titles={[
          { width: 1, align: "center", title: "No." },
          { width: 3, align: "left", title: "Date" },
          { width: 5, align: "left", title: "Name" },
          { width: 1, align: "center", title: "Pax" },
          { width: 2, align: "center", title: "Action" },
        ]}
      />
      {fetching?.setting || fetching?.customers || fetching?.bookings ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <Grid container direction="column" spacing={0}>
          {rows?.length > 0 ? (
            rows
          ) : (
            <Grid item>
              <Box component="div" className="center-text">
                No Booking found!
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
