import { Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import "moment/locale/vi";
import "./WorkingSessionItem.css";
import { AuthContext } from "../../../../../context/AuthProvider";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

export default function WorkingSessionItem({ item, number, deleteItem }) {
  const { token, userInfo } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleItemClick = React.useCallback((event) => {
    // navigate(pathname + "/" + item._id + "/view");
  }, []);
  const handleEditClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  const dateTime = () => {
    const date = moment(item.startTime).format("dddd, DD/MM/yyyy");
    const output =
      date.charAt(0).toUpperCase() +
      date.slice(1) +
      " (" +
      moment(item.startTime).format("HH:mm") +
      "-" +
      moment(item.endTime).format("HH:mm") +
      ")";
    return output;
  };
  const colorName = React.useMemo(() => {
    switch (item.status) {
      case "active": {
        // console.log({ color: room?.colorCalendarId });
        return item.workingRoomId?.color?.label
          ? item.workingRoomId?.color?.label
          : "";
      }
      case "complete": {
        return item.workingRoomId?.colorComplete?.label
          ? item.workingRoomId?.colorComplete?.label
          : "";
      }
      default: {
        return "";
      }
    }
  }, [
    item.status,
    item.workingRoomId?.color?.label,
    item.workingRoomId?.colorComplete?.label,
  ]);
  return (
    <Grid
      onClick={(event) => handleItemClick(event)}
      container
      spacing={0}
      direction="row"
      className={"item-list " + colorName}
    >
      <Grid item xs={1}>
        <Typography
          align="center"
          className={
            item.calendarEventId
              ? item.status === "active"
                ? "link-to-calendar-active"
                : "link-to-calendar-complete"
              : ""
          }
        >
          {number + "."}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography align="left">{dateTime()}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography align="center">{item.workingRoomId.name}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography noWrap align="center">
          {item.userId.username}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={(event) => handleEditClick(event)}
        >
          <EditIcon
            className={"working-session-item-list-icon-" + item.status}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        {userInfo?.role === "admin" ? (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon
              className={"working-item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        ) : (
          <IconButton aria-label="delete" size="small" onClick={(event) => {}}>
            <HorizontalRuleIcon
              className={"working-item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
