export function stringToDate(_date, _format, _delimiter) {
  var formatLowerCase = _format.toLowerCase();
  var formatItems = formatLowerCase.split(_delimiter);
  var dateItems = _date.split(_delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
}

export function convertTimeToHourMin(timeInMilisecond) {
  // console.log({timeInMilisecond});
  const timeInMinute = Math.round(timeInMilisecond / 1000 / 60);
  // console.log({timeInMinute});
  const min = timeInMinute % 60;
  const hour = (timeInMinute - (timeInMinute % 60)) / 60;
  return hour + ":" + min;
}

export function numberWithCommas(x = "", suffix = "", prefix = "") {
  let strOut = "";
  // try {
    // if (!x) {
    //   x = "0";
    // }
    strOut =
      prefix + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix;
  // } catch (err) {
  //   console.log({ err });
  // }
  return strOut;
}
