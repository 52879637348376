// import axios from "./api-client";

import axios from "axios";
import { DEBUG } from "../context/AppProvider";

const URL_DEV = "http://localhost:5000";
// const URL_DEV = "https://server.thebibspace.com";
// const URL_PRODUCTION = "https://server-ypmce.ondigitalocean.app"; 
// const URL_PRODUCTION = "https://the-bib-space-4-dot-0.herokuapp.com";
const URL_PRODUCTION = "https://server.thebibspace.com";
const URL = DEBUG ? URL_DEV : URL_PRODUCTION;

export async function loginUser(credentials) {
  return fetch(`${URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export const login = (payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${URL}/user/login`, payload, config);
};

export const updatePassword = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/user/updatePassword`, payload, config);
};

export const getUserAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/user`, config);
};

export const createUser = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/user`, payload, config);
};

export const deleteUser = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/user/delete`, payload, config);
};

export const updateUser = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/user/update`, payload, config);
};

export const searchBooking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/search`, payload, config);
};

export const getBookingById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/booking/${id}`, config);
};

export const getBookingAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/booking`, config);
};

export const getBookingByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/dateRange`, payload, config);
};

export const getBookingByProperties = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/prop`, payload, config);
};

export const getBookingSummary = (token, bookingId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/booking/summary/${bookingId}`, config);
};

export const getBookingDiscountPercentById = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/discountPercent/`, payload, config);
};

export const createBooking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking`, payload, config);
};

export const deleteBooking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/delete`, payload, config);
};

export const updateBooking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/update`, payload, config);
};

export const updateBookingDiscountPercent = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/booking/updateDiscountPercent`, payload, config);
};

export const searchClass = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/class/search`, payload, config);
};

export const getClassByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/class/dateRange`, payload, config);
};

export const getClassById = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/class/${classId}`, config);
};

export const getClassSummary = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/class/summary/${classId}`, config);
};

export const getClassAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/class`, config);
};

export const createClass = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/class`, payload, config);
};

export const deleteClass = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/class/delete`, payload, config);
};

export const updateClass = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/class/update`, payload, config);
};

export const deleteBookingClasses = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/class/deleteBooking`, payload, config);
};

export const getRoomAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/room`, config);
};

export const createRoom = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/room`, payload, config);
};

export const deleteRoom = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/room/delete`, payload, config);
};

export const updateRoom = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/room/update`, payload, config);
};

export const getCustomerById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/customer/${id}`, config);
};

export const getCustomerAndPhotoById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/customer/photo/${id}`, config);
};

export const getCustomerAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/customer`, config);
};

export const createCustomer = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/customer`, payload, config);
};

export const deleteCustomer = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/customer/delete`, payload, config);
};

export const updateCustomer = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/customer/update`, payload, config);
};

export const searchAddon = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addon/search`, payload, config);
};

export const getAddonById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addon/${id}`, config);
};

export const getAddonAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addon`, config);
};

export const getAddonAllGroupCate = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addon/groupCate`, config);
};

export const createAddon = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addon`, payload, config);
};

export const deleteAddon = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addon/delete`, payload, config);
};

export const updateAddon = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addon/update`, payload, config);
};

export const updateAddonInventory = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addon/updateInventory`, payload, config);
};

export const searchAddonCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonCate/search`, payload, config);
};

export const getAddonCateById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addonCate/${id}`, config);
};

export const getAddonCateAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addonCate`, config);
};

export const createAddonCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonCate`, payload, config);
};

export const deleteAddonCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonCate/delete`, payload, config);
};

export const updateAddonCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonCate/update`, payload, config);
};

export const searchVat = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/vat/search`, payload, config);
};

export const getVatById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/vat/${id}`, config);
};

export const getVatAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/vat`, config);
};

export const createVat = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/vat`, payload, config);
};

export const deleteVat = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/vat/delete`, payload, config);
};

export const updateVat = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/vat/update`, payload, config);
};

export const searchPos = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/pos/search`, payload, config);
};

export const getPosByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/pos/dateRange`, payload, config);
};

export const getPosByProperties = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/pos/prop`, payload, config);
};

export const getPosLatest = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/pos/latest`, config);
};

export const getPosById = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/pos/${classId}`, config);
};

export const getPosAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/pos`, config);
};

export const createPos = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/pos`, payload, config);
};

export const deletePos = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/pos/delete`, payload, config);
};

export const updatePos = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/pos/update`, payload, config);
};

export const searchSpendingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingSession/search`, payload, config);
};

export const getSpendingSessionByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingSession/dateRange`, payload, config);
};

export const getSpendingSessionLatest = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spendingSession/latest`, config);
};

export const getSpendingSessionById = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spendingSession/${classId}`, config);
};

export const getSpendingSessionAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spendingSession`, config);
};

export const createSpendingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingSession`, payload, config);
};

export const deleteSpendingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingSession/delete`, payload, config);
};

export const updateSpendingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingSession/update`, payload, config);
};

export const searchAddonSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonSession/search`, payload, config);
};

export const getAddonSessionByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonSession/dateRange`, payload, config);
};

export const getAddonSessionLatest = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addonSession/latest`, config);
};

export const getAddonSessionById = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addonSession/${classId}`, config);
};

export const getAddonSessionAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/addonSession`, config);
};

export const createAddonSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonSession`, payload, config);
};

export const deleteAddonSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonSession/delete`, payload, config);
};

export const updateAddonSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/addonSession/update`, payload, config);
};

export const getSetting = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting`, config);
};

export const updateSetting = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/update`, payload, config);
};

export const getDiscountFixeds = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/discountFixeds`, config);
};

export const updateDiscountFixeds = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/discountFixeds/update`, payload, config);
};

export const getDiscountRules = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/discountRules`, config);
};

export const updateDiscountRules = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/discountRules/update`, payload, config);
};

export const getSMSContent = (token, type) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/${type}`, config);
};

export const updateSMSContent = (token, payload, type) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/${type}/update`, payload, config);
};

export const getRemindTime = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/remindTime`, config);
};

export const updateRemindTime = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/remindTime/update`, payload, config);
};

export const getOperationPercent = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/operationPercent`, config);
};

export const updateOperationPercent = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/operationPercent/update`, payload, config);
};

export const getAllowanceReward = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/allowanceReward`, config);
};

export const updateAllowanceReward = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/allowanceReward/update`, payload, config);
};

export const searchSpendingCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingCate/search`, payload, config);
};

export const getSpendingCateById = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spendingCate/${classId}`, config);
};

export const getSpendingCateAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spendingCate`, config);
};

export const createSpendingCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingCate`, payload, config);
};

export const deleteSpendingCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingCate/delete`, payload, config);
};

export const updateSpendingCate = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spendingCate/update`, payload, config);
};

export const searchSpending = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spending/search`, payload, config);
};

export const getSpendingById = (token, classId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spending/${classId}`, config);
};

export const getSpendingAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/spending`, config);
};

export const createSpending = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spending`, payload, config);
};

export const deleteSpending = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spending/delete`, payload, config);
};

export const updateSpending = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/spending/update`, payload, config);
};

export const searchWorking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/working/search`, payload, config);
};

export const getWorkingSummary = (token, workingId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/working/summary/${workingId}`, config);
};

export const getWorkingByProperties = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/working/prop`, payload, config);
};

export const getWorkingById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/working/${id}`, config);
};

export const getWorkingByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/working/dateRange`, payload, config);
};

export const getWorkingAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/working`, config);
};

export const createWorking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/working`, payload, config);
};

export const deleteWorking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/working/delete`, payload, config);
};

export const updateWorking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/working/update`, payload, config);
};

export const getPhotoById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/photo/${id}`, config);
};

export const getPhotoAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/photo`, config);
};

export const createPhoto = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/photo`, payload, config);
};

export const deletePhoto = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/photo/delete`, payload, config);
};

export const updatePhoto = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/photo/update`, payload, config);
};

export const searchWorkingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingSession/search`, payload, config);
};

export const getWorkingSessionByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingSession/dateRange`, payload, config);
};

export const getWorkingSessionById = (token, workingSessionId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/workingSession/${workingSessionId}`, config);
};

export const getWorkingSessionSummary = (token, workingSessionId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/workingSession/summary/${workingSessionId}`, config);
};

export const getWorkingSessionAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/workingSession`, config);
};

export const createWorkingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingSession`, payload, config);
};

export const deleteWorkingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingSession/delete`, payload, config);
};

export const updateWorkingSession = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingSession/update`, payload, config);
};

export const deleteWorkingSessionsInWorking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingSession/deleteWorking`, payload, config);
};

export const getWorkingRoomAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/workingRoom`, config);
};

export const createWorkingRoom = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingRoom`, payload, config);
};

export const deleteWorkingRoom = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingRoom/delete`, payload, config);
};

export const updateWorkingRoom = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/workingRoom/update`, payload, config);
};

export const searchAllowance = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/allowance/search`, payload, config);
};

export const getAllowanceById = (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/allowance/${id}`, config);
};

export const getAllowanceAll = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/allowance`, config);
};

export const createAllowance = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/allowance`, payload, config);
};

export const deleteAllowance = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/allowance/delete`, payload, config);
};

export const updateAllowance = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/allowance/update`, payload, config);
};

export const sendSMSConfirm = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/sms/confirm`, payload, config);
};

export const sendSMSClasses = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/sms/classes`, payload, config);
};

export const sendSMSParking = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/sms/parking`, payload, config);
};

export const sendSMSRemind = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/sms/remind`, payload, config);
};

export const getStatisticalRoomByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/statistical/room/dateRange`, payload, config);
};

export const getStatisticalPosByDateRange = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/statistical/pos/dateRange`, payload, config);
};

export const getTimeZone = (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.get(`${URL}/setting/timeZone`, config);
};

export const updateTimeZone = (token, payload) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  return axios.post(`${URL}/setting/timeZone/update`, payload, config);
};
