import { Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import "moment/locale/vi";
import "./WorkingItem.css";
import { AuthContext } from "../../../../../context/AuthProvider";

export default function WorkingItem({ item, number, deleteItem }) {
  const { token, userInfo } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleItemClick = React.useCallback((event) => {
    // navigate(pathname + "/" + item._id + "/view");
  }, []);
  const handleEditClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  const handleSummaryClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      navigate(pathname + "/" + item._id + "/summary");
    },
    [navigate, pathname, item._id]
  );
  const dateTime = () => {
    const date = moment(item.createdAt).format("dddd, DD/MM/yyyy");
    const output = date.charAt(0).toUpperCase() + date.slice(1);
    return output;
  };
  const colorName = React.useMemo(() => {
    switch (item.status) {
      case "active": {
        return "";
      }
      case "complete": {
        return "Graphite";
      }
      default: {
        return "";
      }
    }
  }, [item.status]);
  return (
    <Grid
      onClick={(event) => handleItemClick(event)}
      container
      spacing={0}
      direction="row"
      className={"item-list " + colorName}
    >
      <Grid item xs={1}>
        <Typography align="center">{number + "."}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align="left">{item.name}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography noWrap align="center">
          {item.userId.username}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {userInfo?.role === "admin" ? (
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(event) => handleEditClick(event)}
          >
            <EditIcon
              className={"working-item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        ) : (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleSummaryClick(event)}
          >
            <VisibilityIcon
              className={"working-item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={1}>
        {userInfo?.role === "admin" ? (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon
              className={"working-item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        ) : (
          <IconButton aria-label="delete" size="small" onClick={(event) => {}}>
            <HorizontalRuleIcon
              className={"working-item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
