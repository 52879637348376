import { Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import moment from "moment";
import "moment/locale/vi";
import "./PosDialogItem.css";
import { numberWithCommas } from "../../../../../helpers/stringDate";
import { AuthContext } from "../../../../../context/AuthProvider";

export default function PosDialogItem({ item, number }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token, userInfo } = React.useContext(AuthContext);
  const handleItemClick = React.useCallback((event) => {
    // navigate(pathname + "/" + item._id + "/view");
  }, []);
  const dateTime = React.useMemo(() => {
    const date = moment(item.createdAt).format("dddd, DD/MM/yyyy");
    const output =
      date.charAt(0).toUpperCase() +
      date.slice(1) +
      " (" +
      moment(item.createdAt).format("HH:mm") +
      ")";
    return output;
  }, [item.createdAt]);
  const totalCount = React.useMemo(() => {
    let count = 0;
    item?.addons?.forEach((addon) => {
      count += addon.quantity;
    });
    return count;
  }, [item?.addons]);

  return (
    <Grid
      onClick={(event) => handleItemClick(event)}
      container
      spacing={0}
      direction="row"
      className={"item-list pos-" + item.status}
    >
      <Grid item xs={1}>
        <Typography align="center">{number + "."}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align="left">{dateTime}</Typography>
      </Grid>
      {/* <Grid item xs={0}>
        <Typography align="center">{item.no}</Typography>
      </Grid> */}
      <Grid item xs={3}>
        <Typography align="center">{totalCount + " items"}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography align="center">
          {numberWithCommas(item.total, " đ")}
        </Typography>
      </Grid>
    </Grid>
  );
}
