import { Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getSMSContent,
  getSMSParkingMotor,
  updateSMSContent,
} from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";
import EditSMSContent from "./EditSMSContent";
import { getSMSType } from "./helpers";
import SMSContentItem from "./SMSContentItem";
import AddIcon from "@mui/icons-material/Add";

export default function SMSContent() {
  const navigate = useNavigate();
  const { id } = useParams();
  //   console.log({ id });
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const pathArray = pathname.split("/");
  const type = React.useMemo(() => {
    const paths = pathname.split("/");
    let t;
    if (!id) {
      t = paths.pop();
      if (t === "new") {
        t = paths.pop();
      }
    } else {
      paths.pop();
      t = paths.pop();
    }
    console.log({ t });
    return t;
  }, [id, pathname]);
  const [datas, setDatas] = React.useState([]);
  const { token } = React.useContext(AuthContext);
  const updateList = React.useCallback((res) => {
    console.log({ res });
    if (res && res.status === 200) {
      const dataList = res.data;
      setDatas(dataList);
    }
  }, []);
  const deleteItem = React.useCallback(
    (item) => {
      const newDatas = datas.filter(function (value, index, arr) {
        return value._id !== item._id;
      });
      updateSMSContent(token, newDatas, type)
        .then((res) => {
          alert("Delete Successfully!");
          updateList(res);
        })
        .catch((err) => {
          console.log({ err });
        });
      //   setRules(newRules);
    },
    [datas, token, updateList, type]
  );
  React.useEffect(() => {
    // console.log("getDiscountRules");
    getSMSContent(token, type)
      .then((res) => {
        updateList(res);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token, updateList, type]);
  if (id) {
    return (
      <>
        <EditSMSContent
          itemId={id}
          datas={datas}
          setDatas={setDatas}
          type={type}
        />
      </>
    );
  } else if (pathArray.pop() === "new") {
    return <EditSMSContent datas={datas} setDatas={setDatas} type={type} />;
  } else {
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      rows.push(
        <Grid item key={item._id}>
          <SMSContentItem number={i + 1} item={item} deleteItem={deleteItem} />
        </Grid>
      );
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header title={getSMSType(type)?.text} rightButton={rightButton} />
        <Grid container direction="column" spacing={0}>
          {rows}
        </Grid>
      </>
    );
  }
}
