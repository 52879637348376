import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import PosItem from "./PosItem";
import AddIcon from "@mui/icons-material/Add";
import {
  deletePos,
  getPosAll,
  getPosByDateRange,
  searchPos,
} from "../../../../api/api";
import { Grid, MenuItem, Select, LinearProgress } from "@mui/material";
import ListHeader from "../../../Header/ListHeader";
import ListSearch from "../../../Header/ListSearch";
import moment from "moment";
import { POS_DATE_RANGES } from "../../../../constants";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import { AppContext } from "../../../../context/AppProvider";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import { updateInventory } from "./EditPos";
export default function Pos() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const { searchPosMem, setSearchPosMem } = React.useContext(AppContext);
  const { dateRangePosMem, setDateRangePosMem } = React.useContext(AppContext);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateDatas = React.useCallback((newDatas, setInit = true) => {
    // console.log({ res });
    newDatas.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    console.log({ newDatas });
    if (setInit) {
      setInitDatas(newDatas);
    }
    setDatas(newDatas);
    // console.log({ newDatas });
  }, []);

  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchPos(token, { search: value })
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      debounceFetcher(searchText);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching(true);
      let alertContent = "";
      await deletePos(token, item)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            const newDatas = datas.filter(function (value, index, arr) {
              return value._id !== item._id;
            });
            setDatas(newDatas);
            alertContent += `\nDelete POS [OK]`;
          } else {
            alertContent += `\nDelete POS [Error: ${res.status}]`;
          }
        })
        .catch((err) => {
          console.log({ err });
          if (!mountedRef.current) return null;
          // return err.response.status;
          alertContent += `\nDelete POS [Error: ${err}]`;
        });
      setProgress(50);
      alertContent +=
        "\n" +
        (await updateInventory(
          token,
          mountedRef,
          item?.addons.map((addon) => ({
            ...addon,
            addonId: addon.addonId._id,
          })),
          undefined
        ));
      setProgress(100);
      setFetching(false);
      if (alertContent) {
        alert(alertContent);
      }
    },
    [token, datas]
  );
  React.useEffect(() => {
    async function abc() {
      if (searchPosMem) {
        handleSearch(searchPosMem);
      } else {
        let alertContent;
        console.log("Getting posses");
        setProgress(50);
        setFetching(true);
        if (dateRangePosMem >= 0) {
          const startDate = moment()
            .subtract(dateRangePosMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangePosMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getPosByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getPosAll(token)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    abc();
  }, [dateRangePosMem, handleSearch, searchPosMem, token, updateDatas]);
  let rows = [];
  for (var i = 0; i < datas.length; i++) {
    const item = datas[i];
    rows.push(
      <Grid item key={item._id}>
        <PosItem
          key={item._id}
          number={i + 1}
          item={item}
          deleteItem={deleteItem}
        />
      </Grid>
    );
  }
  const rightButton = {
    icon: <AddIcon fontSize="inherit" />,
    callback: function (event) {
      navigate(pathname + "/new");
    },
  };
  const selectBox = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={dateRangePosMem}
      // label="Date Range"
      fullWidth
      onChange={(event) => {
        setDateRangePosMem(event.target.value);
      }}
    >
      {POS_DATE_RANGES.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      <Header title="POS" rightButton={rightButton} />
      <ListSearch
        handleChange={(searchText) => {
          if (searchText) {
            handleSearch(searchText);
          } else {
            setDatas(initDatas);
          }
          setSearchPosMem(searchText);
        }}
        selectBox={selectBox}
        placeholder="Invoice No."
        value={searchPosMem}
      />
      <ListHeader
        titles={[
          { width: 1, align: "center", title: "No." },
          { width: 4, align: "left", title: "Time" },
          { width: 1, align: "center", title: "Invoice" },
          { width: 2, align: "center", title: "Pax" },
          { width: 2, align: "center", title: "Total" },
          { width: 2, align: "center", title: "Action" },
        ]}
      />
      <div className="list-item">
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <Grid container direction="column" spacing={0}>
            {rows?.length > 0 ? (
              rows
            ) : (
              <Grid item>
                <Box component="div" className="center-text">
                  No Pos found!
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
