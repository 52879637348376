import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import "./BookingItem.css";

export default function BookingItem({ item, number, deleteItem }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleEditClick = React.useCallback(
    (event) => {
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        className={"item-list " + item?.status}
      >
        <Grid item xs={1}>
          <Typography align="center">{number + "."}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="left">
            {moment(item.createdAt).format("DD/MM/yyyy")}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography noWrap align="left">
            {item.customerId.name +
              "-" +
              item.customerId.phone +
              (item.bookingName ? "-" + item.bookingName : "")}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography align="center">{item.pax}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(event) => handleEditClick(event)}
          >
            <EditIcon
              fontSize="inherit"
              className={"booking-item-list-icon-" + item?.status}
            />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon
              fontSize="inherit"
              className={"booking-item-list-icon-" + item?.status}
            />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
