import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import moment from "moment";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import { deleteCalendarEvent } from "../../../../../services/calendar";
import {
  deleteWorkingSession,
  getWorkingSessionAll,
  getWorkingSessionByDateRange,
  searchWorkingSession,
} from "../../../../../api/api";
import { AuthContext } from "../../../../../context/AuthProvider";
import { AppContext } from "../../../../../context/AppProvider";
import { updateOtherSessionNoCalendar } from "./EditWorkingSession";
import WorkingSessionItem from "./WorkingSessionItem";
import Header from "../../../../Header/Header";
import ListSearch from "../../../../Header/ListSearch";
import { WORKING_SESSION_DATE_RANGES } from "../../../../../constants";
import ListHeader from "../../../../Header/ListHeader";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";

export async function deleteItemSession(
  token,
  item,
  successCallback = () => {},
  errorCallback = () => {}
) {
  const eventRes = await deleteCalendarEvent(
    item.calendarEventId,
    item.workingRoomId.calendarId
  );
  const eventStatusList = [404, 410, 200, 205, 204];
  if (eventStatusList.indexOf(eventRes.status) > -1) {
    const sessionStatus = await deleteWorkingSession(token, item)
      .then((res) => {
        // successCallback(res);
        return res.status;
      })
      .catch((err) => {
        console.log({ err });
        // errorCallback(err);
        return err.response.status;
      });
    return {
      calendarCode: eventRes.status,
      serverCode: sessionStatus,
    };
  } else {
    return { calendarCode: eventRes.status, serverCode: 0 };
  }
  //   setRules(newRules);
}

export default function WorkingSession() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [workings, setWorkings] = React.useState([]);
  const [workingRooms, setWorkingRooms] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const { searchWSessionMem, setSearchWSessionMem } =
    React.useContext(AppContext);
  const { dateRangeWSessionMem, setDateRangeWSessionMem } =
    React.useContext(AppContext);
  // const [dateRange, setDateRange] = React.useState(CLASS_DATE_RANGES[0].value);
  const [fetching, setFetching] = React.useState(false);
  // const [progress, setProgress] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateDatas = React.useCallback(
    (newDatas, setInit = true) => {
      // console.log({ res });
      if (userInfo?.role !== "admin") {
        newDatas = newDatas.filter((value, index, array) => {
          return value.userId?._id === userInfo?._id;
        });
      }
      newDatas.sort(function (a, b) {
        return new Date(b.startTime) - new Date(a.startTime);
      });
      console.log({ newDatas });
      if (setInit) {
        setInitDatas(newDatas);
      }
      setDatas(newDatas);
      // console.log({ newDatas });
    },
    [userInfo?._id, userInfo?.role]
  );

  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchWorkingSession(token, { search: value })
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      debounceFetcher(searchText);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching(true);
      let alertContent = "";
      const { calendarCode, serverCode } = await deleteItemSession(token, item);
      setProgress(50);
      if (serverCode === 200) {
        const newDatas = datas.filter(function (value, index, arr) {
          return value._id !== item._id;
        });
        setDatas(newDatas);
        const { update, updatedCount, errorCount } =
          await updateOtherSessionNoCalendar(
            token,
            item.workingId._id,
            true,
            [],
            [],
            setProgress,
            50,
            80
          );
        if (update) {
          alertContent += `Calendar: [${updatedCount} OK] - [${errorCount} ERROR]`;
        }
        alertContent += `\nDelete Success: [Calendar: ${calendarCode}] - [DB: ${serverCode}]`;
      } else {
        alertContent += `\nDelete ERROR! [Calendar: ${calendarCode}] - [DB: ${serverCode}]`;
      }
      setProgress(100);
      setFetching(false);
      alert(alertContent);
    },
    [token, datas]
  );
  React.useEffect(() => {
    async function doTask() {
      if (searchWSessionMem) {
        handleSearch(searchWSessionMem);
      } else {
        let alertContent;
        console.log("Getting sessions...");
        setProgress(50);
        setFetching(true);
        if (dateRangeWSessionMem >= 0) {
          const startDate = moment()
            .subtract(dateRangeWSessionMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangeWSessionMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getWorkingSessionByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getWorkingSessionAll(token)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    doTask();
  }, [
    dateRangeWSessionMem,
    handleSearch,
    searchWSessionMem,
    token,
    updateDatas,
  ]);

  let rows = [];
  for (var i = 0; i < datas.length; i++) {
    const item = datas[i];
    rows.push(
      <Grid item key={item._id}>
        <WorkingSessionItem
          key={item._id}
          number={i + 1}
          item={item}
          deleteItem={deleteItem}
        />
      </Grid>
    );
  }
  const rightButton = {
    icon: <AddIcon fontSize="inherit" />,
    callback: function (event) {
      navigate(pathname + "/new");
    },
  };
  const selectBox = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={dateRangeWSessionMem}
      // label="Date Range"
      fullWidth
      onChange={(event) => {
        setDateRangeWSessionMem(event.target.value);
      }}
    >
      {WORKING_SESSION_DATE_RANGES.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      <Header
        title="Working Sessions"
        rightButton={userInfo?.role === "admin" ? rightButton : null}
      />
      <ListSearch
        handleChange={(searchText) => {
          if (searchText) {
            handleSearch(searchText);
          } else {
            setDatas(initDatas);
          }
          setSearchWSessionMem(searchText);
        }}
        selectBox={selectBox}
        placeholder="Name or Phone"
        value={searchWSessionMem}
      />
      <ListHeader
        titles={[
          { width: 1, align: "center", title: "No." },
          { width: 4, align: "left", title: "Time" },
          { width: 3, align: "center", title: "Place" },
          { width: 2, align: "center", title: "User" },
          { width: 2, align: "center", title: "Action" },
        ]}
      />
      <div className="list-item">
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <Grid container direction="column" spacing={0}>
            {rows?.length > 0 ? (
              rows
            ) : (
              <Grid item>
                <Box component="div" className="center-text">
                  No Session found!
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
