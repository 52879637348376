import "./App.css";
import Login from "../Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import AuthProvider, { AuthContext } from "../../context/AuthProvider";
import { handleClientLoad } from "../../services/calendar";
import AppProvider from "../../context/AppProvider";
import React from "react";
import MyNavigator from "./MyNavigator";

function App() {
  console.log("App");
  handleClientLoad();
  return (
    <div className="wrapper">
      <BrowserRouter>
        {/* <CustomRouter history={customHistory}> */}
        <AuthProvider>
          <AppProvider>
            <MyNavigator />
            <Routes>
              <Route element={<Login />} path="/login" />
              <Route element={<Dashboard />} exact path="/" />
              <Route element={<Dashboard />} path="/*" />
            </Routes>
          </AppProvider>
        </AuthProvider>
        {/* </CustomRouter> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
