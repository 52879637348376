import React from "react";
import { AuthContext } from "../../../../../context/AuthProvider";
import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import { createAddonCate, updateAddonCate } from "../../../../../api/api";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import Header from "../../../../Header/Header";

export default function EditAddonCate({ itemId, datas, updateDatas }) {
  console.log({ itemId });
  console.log({ datas });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const item = React.useMemo(() => {
    if (itemId) {
      let item = null;
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setName(item.name);
      }
      console.log({ item });
      return item;
    }
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        updateDatas(null, null, res.data);
      } else {
        //created
        updateDatas(null, res.data, null);
      }
    },
    [updateDatas]
  );
  const handleSubmit = React.useCallback(
    async (event) => {
      let alertContent;
      setProgress(50);
      setFetching(true);
      const newItem = {
        name,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        await updateAddonCate(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent = `Update success!`;
              setDataList(itemId, res);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      } else {
        console.log({ newItem });
        await createAddonCate(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              alertContent = `Create success!`;
              setDataList(itemId, res);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
    },
    [itemId, name, setDataList, token, userInfo?._id]
  );
  return (
    <>
      <Header title={(itemId ? "Edit" : "New") + " Addon-Cate"} />
      {fetching ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Typography component="div">
              {`Last updated by: ${data?.lastUpdatedBy?.username}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleSubmit(event)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
