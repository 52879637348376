import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import "moment/locale/vi";
import { Typography } from "@mui/material";
import "./WorkingSessionSummary.css";
import { Box } from "@mui/system";
import {
  getAllowanceReward,
  getWorkingSessionSummary,
} from "../../../../../api/api";
import {
  SETTING_ENABLE_ALLOWANCE,
  SETTING_ENABLE_REWARD,
  WORKING_SESSION_STATUS,
  WORKING_STATUS,
  WORKING_SUMMARY_TYPE,
} from "../../../../../constants";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import { numberWithCommas } from "../../../../../helpers/stringDate";
import { AuthContext } from "../../../../../context/AuthProvider";
import Header from "../../../../Header/Header";

function SummaryItem({ label, value }) {
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        {label + ": "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {value}
      </Box>
    </Grid>
  );
}

export default function WorkingSessionSummary() {
  const { id } = useParams();
  console.log({ id });
  moment.locale("vi");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [fetching, setFetching] = React.useState({
    setting: false,
    data: false,
  });
  const [allowanceReward, setAllowanceReward] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    async function doTask() {
      let alertContent;
      console.log("Getting Setting...");
      setProgress(0);
      setFetching((prevState) => ({ ...prevState, setting: true }));
      await getAllowanceReward(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            setAllowanceReward(res.data);
          }
        })
        .catch((err) => {
          console.log({ err });
          if (!mountedRef.current) return null;
          alertContent = err;
          // setFetching((prevState) => ({ ...prevState, setting: false }));
        });
      setProgress(50);
      setFetching((prevState) => ({ ...prevState, setting: false }));
      if (id && !alertContent) {
        setFetching((prevState) => ({ ...prevState, data: true }));
        console.log("Getting Working session summary...");
        setFetching(true);
        await getWorkingSessionSummary(token, id)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const item = res.data;
              console.log({ item });
              setData(item);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, data: false }));
      }
      if (alertContent) {
        alert(alertContent);
      }
    }
    doTask();
  }, [id, token]);

  const status = React.useMemo(() => {
    const startTime = data?.data.startTime;
    const endTime = data?.data.endTime;
    if (moment().isBefore(moment(startTime))) {
      return "Active";
    } else if (moment().isAfter(moment(endTime))) {
      return "Complete";
    } else {
      return "Working";
    }
  }, [data?.data.endTime, data?.data.startTime]);

  // total,
  //   salary,
  //   hour,
  //   allowance,
  //   no,
  //   cashChanged,

  const sessionSummaryItems = [
    {
      label: "Tình trạng (Status)",
      value: WORKING_SESSION_STATUS.filter((value, index, array) => {
        return value.value === data?.status;
      })[0]?.label,
    },
    {
      label: "No",
      value: data?.no + "/" + data?.workingSummary.sessionCount.all,
    },
    {
      label: "Tiền quầy (Cash changed)",
      value: numberWithCommas(data?.cashChanged, " đ"),
    },
    {
      label: "Bắt đầu (Start)",
      value: moment(data?.data.startTime).format("HH:mm"),
    },
    {
      label: "Kết thúc (End)",
      value: moment(data?.data.endTime).format("HH:mm"),
    },
    {
      label: "Số giờ (Used)",
      value: data?.hour,
    },
    {
      label: "Lương/Giờ (Salary/Hour)",
      value: numberWithCommas(data?.data.salaryPerHour, " đ"),
    },
    {
      label: "Lương (Salary)",
      value: numberWithCommas(data?.salary, " đ"),
    },
    {
      label: "Phụ cấp (Allowance)",
      value: numberWithCommas(data?.allowance, " đ"),
    },
    {
      label: "Tổng (Total)",
      value: numberWithCommas(data?.total, " đ"),
    },
    {
      label: "Đã nhận (Payed)",
      value: numberWithCommas(data?.data.payed, " đ"),
    },
    {
      label: "Còn lại (Remain)",
      value: numberWithCommas(data?.remain, " đ"),
    },
  ];
  for (let i = 0; i < sessionSummaryItems.length; i++) {
    const item = sessionSummaryItems[i];
    if (!allowanceReward?.allowance && item.label.includes("Allowance")) {
      sessionSummaryItems.splice(i, 1);
    }
  }
  const workingStatus = WORKING_STATUS.filter((value, index, array) => {
    return data?.workingSummary.data.status === value.value;
  })[0];
  const workingSummaryItems = [
    {
      label: "Tình trạng (Status)",
      value: function (key) {
        return workingStatus?.label;
      },
    },
    {
      label: "Tổng số ca (Total Session)",
      value: function (key) {
        return data?.workingSummary.sessionCount[key];
      },
    },
    {
      label: "Tổng số giờ (Working hours)",
      value: function (key) {
        return data?.workingSummary.totalHours[key];
      },
    },
    {
      label: "Tổng lương theo giờ (Salary by hour)",
      value: function (key) {
        return numberWithCommas(data?.workingSummary.totalSalary[key], " đ");
      },
    },
    {
      label: "Tổng phụ cấp (Total allowance)",
      value: function (key) {
        return numberWithCommas(data?.workingSummary.totalAllowance[key], " đ");
      },
    },
    {
      label: "Tổng thưởng (Total Kpi Hour reward)",
      value: function (key) {
        return numberWithCommas(
          data?.workingSummary.totalKpiHourReward[key],
          " đ"
        );
      },
    },
    {
      label: "Tổng (Total)",
      value: function (key) {
        return numberWithCommas(data?.workingSummary.total[key], " đ");
      },
    },
    {
      label: "Đã nhận (Payed)",
      value: function (key) {
        return numberWithCommas(data?.workingSummary.totalPayed[key], " đ");
      },
    },
    {
      label: "Còn lại (Remain)",
      value: function (key) {
        return numberWithCommas(data?.workingSummary.remain[key], " đ");
      },
    },
  ];
  for (let i = 0; i < workingSummaryItems.length; i++) {
    const item = workingSummaryItems[i];
    if (!allowanceReward?.allowance && item.label.includes("allowance")) {
      workingSummaryItems.splice(i, 1);
    }
  }
  for (let i = 0; i < workingSummaryItems.length; i++) {
    const item = workingSummaryItems[i];
    if (!allowanceReward?.reward && item.label.includes("reward")) {
      workingSummaryItems.splice(i, 1);
    }
  }
  return (
    <>
      <Header title="Working Session Summary" />
      {fetching.setting || fetching.data ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <div className="content">
          <Grid container direction="column" spacing={1} className="invoice">
            {sessionSummaryItems.map((item) => (
              <SummaryItem
                key={item.label}
                label={item.label}
                value={item.value}
              />
            ))}
          </Grid>
          {WORKING_SUMMARY_TYPE.map((type) => (
            <div key={type.value}>
              <Typography
                variant="h4"
                component="div"
                className="working-summary-header"
              >
                {`Working ${type.name}`}
              </Typography>
              <Grid
                container
                direction="column"
                spacing={1}
                className="invoice"
              >
                {workingSummaryItems.map((item) => (
                  <SummaryItem
                    key={item.label}
                    label={item.label}
                    value={item.value(type.value)}
                  />
                ))}
              </Grid>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
