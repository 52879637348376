import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Popover,
  MenuItem,
  MenuList,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Chip,
} from "@mui/material";

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import "moment/locale/vi";
import { AuthContext } from "../../../../../context/AuthProvider";
import {
  RECURRENCE_ACTION_VALUE,
  SETTING_ENABLE_ALLOWANCE,
  WORKING_SESSION_REPEAT_OPTIONS,
  WORKING_SESSION_STATUS,
  WORKING_STATUS,
} from "../../../../../constants";
import {
  createWorking,
  createWorkingSession,
  getUserAll,
  getWorkingAll,
  getWorkingById,
  getWorkingByProperties,
  getWorkingRoomAll,
  getWorkingSessionById,
  searchWorkingSession,
  updateWorking,
  updateWorkingSession,
} from "../../../../../api/api";
import Header from "../../../../Header/Header";
import MoreActionButton from "../../../../Header/MoreActionButton";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
// import { NumberFormatVNCurrency } from "../../../../../helpers/NumberFormatCustom";
import NumberField from "../../../../../helpers/NumberField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePickerField from "../../../../../helpers/DateTimePickerField";
import {
  convertTimeToHourMin,
  numberWithCommas,
} from "../../../../../helpers/stringDate";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SessionInfoHeader from "../../../../../helpers/SessionInfoHeader/SessionInfoHeader";
import ConfirmUpdateDialog from "../../../../../helpers/ConfirmUpdateDialog";
import {
  insertCalendarEvent,
  makeCalendarEvent,
  moveCalendarEvent,
  updateCalendarEvent,
  getCalendarEvent,
} from "../../../../../services/calendar";
import { v4 } from "uuid";
import { ObjectID } from "bson";
import "./EditWorkingSession.css";

const makeCalendarEventSummary = (name) => {
  return `bib.${name}`;
};

const makeCalendarEventDes = (
  _id,
  no,
  status,
  phone,
  note,
  cashStart,
  cashEnd,
  spendings
) => {
  const link =
    window.location.protocol +
    "//" +
    window.location.host +
    "/workingMenu/workingSession/" +
    _id;
  const makeSpendingsText = () => {
    if (spendings && spendings.length > 0) {
      let spendingsText = numberWithCommas(spendings.total, "đ");
      spendings.forEach((item) => {
        spendingsText +=
          "\n" +
          item.name +
          " (" +
          numberWithCommas(item.quantity * item.price, "đ") +
          ")";
      });
      return spendingsText;
    } else {
      return numberWithCommas(0, "đ");
    }
  };
  const summary =
    "No: " +
    no +
    "\n" +
    "Status: " +
    status +
    "\n" +
    "Phone: " +
    phone +
    (status === "complete"
      ? "\n" +
        "Cash start: " +
        numberWithCommas(cashStart, " đ") +
        "\n" +
        "Cash end: " +
        numberWithCommas(cashEnd, " đ") +
        "\n" +
        "Spendings: " +
        makeSpendingsText()
      : "") +
    "\n" +
    "Note: " +
    note +
    "\n" +
    "Link: " +
    link;
  return summary;
};

const makeCalendarEventColorId = (status, room) => {
  switch (status) {
    case "active": {
      // console.log({ color: room?.colorCalendar.id });
      return room?.colorCalendar?.id ? room?.colorCalendar?.id : undefined;
    }
    case "complete": {
      return room?.colorCalendarComplete?.id
        ? room?.colorCalendarComplete?.id
        : undefined;
    }
    default: {
      return undefined;
    }
  }
};

export async function updateItemSession(token, item, userInfo) {
  //update calendar description
  let calendarCode;
  const newItem = {
    _id: item._id,
    note: item.note,
    // status: item.status,
    lastUpdatedBy: userInfo?._id,
  };
  console.log({ newItem });
  const serverCode = await updateWorkingSession(token, newItem)
    .then((res) => {
      if (res && res.status === 200) {
        return res.status;
      }
    })
    .catch((err) => {
      console.log({ err });
      return err.response.status;
    });
  if (serverCode === 200) {
    const event = makeCalendarEvent(
      makeCalendarEventSummary(item.userId?.username),
      "",
      makeCalendarEventDes(
        item._id,
        item.no,
        item.status,
        item.userId?.phone,
        item.note,
        item.cashStart,
        item.cashEnd,
        item.spendingSessionId?.spendings
      ),
      item.startTime,
      item.endTime,
      "",
      item.attendees,
      makeCalendarEventColorId(item.status, item.workingRoomId)
    );
    const eventRes = await updateCalendarEvent(
      event,
      item.calendarEventId,
      item.workingRoomId.calendarId,
      () => {},
      "update"
    );
    calendarCode = eventRes.status;
  }
  return { calendarCode, serverCode };
}

export const updateOtherSessionNoCalendar = async (
  token,
  workingId,
  isNew,
  recurrenceList,
  sessionList,
  setProgress = () => {},
  startProgress,
  endProgress,
  msg = "Update session(s) No. on calendar?"
) => {
  if (sessionList.length === 0) {
    sessionList = await searchWorkingSession(token, {
      search: workingId,
    }).then((res) => res.data);
  }
  console.log({ sessionList });
  let tmpList = [];
  let tmpOtherList = [];
  if (!isNew) {
    let tmpIdRecurrenceList = [];
    for (let k = 0; k < recurrenceList.length; k++) {
      tmpIdRecurrenceList.push(recurrenceList[k]._id);
    }
    tmpOtherList = sessionList.filter((value, index, array) => {
      return !tmpIdRecurrenceList.includes(value._id);
    });
    tmpList = [...recurrenceList, ...tmpOtherList];
  } else {
    tmpList = [...sessionList, ...recurrenceList];
    tmpOtherList = [...sessionList];
  }
  tmpList.sort(function (a, b) {
    return new Date(a.startTime) - new Date(b.startTime);
  });
  console.log({ tmpList });
  console.log({ tmpOtherList });
  let updatedCount = 0;
  let errorCount = 0;
  const result = window.confirm(msg);
  if (result) {
    for (let i = 0; i < tmpOtherList.length; i++) {
      // await sleep(1, 100);
      setProgress(
        (i / tmpOtherList.length) * (endProgress - startProgress) +
          startProgress
      );
      const item = tmpOtherList[i];
      let itemNo;
      for (let k = 0; k < tmpList.length; k++) {
        if (tmpList[k]._id === item._id) {
          itemNo = k + 1 + "/" + tmpList.length;
          break;
        }
      }
      const event = makeCalendarEvent(
        undefined,
        undefined,
        makeCalendarEventDes(
          item._id,
          itemNo,
          item.status,
          item.userId?.phone,
          item.note,
          item.cashStart,
          item.cashEnd,
          item.spendingSessionId?.spendings
        ),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      console.log({ otherEvent: event });
      const eventRes = await updateCalendarEvent(
        event,
        item.calendarEventId,
        item.workingRoomId.calendarId
      );
      if (eventRes.status === 200) {
        updatedCount += 1;
      } else {
        errorCount += 1;
      }
    }
  }
  return { update: result, sessionList: tmpList, updatedCount, errorCount };
};

export default function EditWorkingSession() {
  moment.locale("vi");
  // moment.utcOffset(0);
  // const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, userInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [sessionsInWorking, setSessionsInWorking] = React.useState([]);
  const [workingRooms, setWorkingRooms] = React.useState([]);
  const [workings, setWorkings] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [working, setWorking] = React.useState("");
  const [workingRoom, setWorkingRoom] = React.useState([]);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [time, setTime] = React.useState(0);
  const [salaryPerHour, setSalaryPerHour] = React.useState(0);
  const [cashStart, setCashStart] = React.useState(0);
  const [cashEnd, setCashEnd] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [payed, setPayed] = React.useState(0);
  const [attendees, setAttendees] = React.useState([]);
  const [status, setStatus] = React.useState(WORKING_SESSION_STATUS[0].value);
  const [recurrence, setRecurrence] = React.useState({
    repeat: WORKING_SESSION_REPEAT_OPTIONS[0],
  });
  const [no, setNo] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [fetching, setFetching] = React.useState({
    workings: false,
    workingRooms: false,
    user: false,
    data: false,
  });
  const [progress, setProgress] = React.useState(0);
  const [returnParent, setReturnParent] = React.useState(false);

  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    async function doTask() {
      let alertContent;
      console.log("Getting working rooms...");
      setProgress(0);
      setFetching((prevState) => ({ ...prevState, workingRooms: true }));
      await getWorkingRoomAll(token)
        .then((res) => {
          if (res && res.status === 200) {
            if (!mountedRef.current) return null;
            setWorkingRooms(res.data);
          }
        })
        .catch((err) => {
          console.log({ err });
          if (!mountedRef.current) return null;
          // setFetching((prevState) => ({ ...prevState, rooms: false }));
        });
      setFetching((prevState) => ({ ...prevState, workingRooms: false }));
      let workingProps = { status: "active" };
      if (id) {
        console.log("Getting data...");
        setProgress(30);
        setFetching((prevState) => ({ ...prevState, data: true }));
        await getWorkingSessionById(token, id)
          .then(async (res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const item = res.data;
              console.log({ item });
              item.workingId["userId"] = item.userId;
              setData(item);
              // workingProps = {
              //   userId: item.userId?._id,
              // };
              // setWorkings([item.workingId]);
              setWorking(item.workingId);
              setUser(item.userId);
              setWorkingRoom(item.workingRoomId);
              setStartTime(item.startTime);
              setEndTime(item.endTime);
              setTime(
                convertTimeToHourMin(
                  moment(item.endTime) - moment(item.startTime)
                )
              );
              setSalaryPerHour(item.salaryPerHour);
              setCashStart(item.cashStart);
              setCashEnd(item.cashEnd);
              setNote(item.note);
              setStatus(item.status);
              setAttendees(item.attendees);
              setPayed(item.payed);
              await searchWorkingSession(token, { search: item.workingId._id })
                .then((res) => {
                  if (res && res.status === 200) {
                    const datas = res.data;
                    setSessionsInWorking(datas);
                    datas.sort(function (a, b) {
                      return new Date(a.startTime) - new Date(b.startTime);
                    });
                    for (let i = 0; i < datas.length; i++) {
                      if (datas[i]._id === item._id) {
                        setNo(`${i + 1} / ${datas.length}`);
                        break;
                      }
                    }
                    if (item.recurrence) {
                      const recurrenceList = datas.filter(function (
                        value,
                        index,
                        arr
                      ) {
                        return value.recurrence?.id === item.recurrence.id;
                      });
                      const recurrenceFromNowList = recurrenceList.filter(
                        function (value, index, arr) {
                          return (
                            moment(value.startTime) >= moment(item.startTime)
                          );
                        }
                      );
                      setRecurrence({
                        ...item.recurrence,
                        count: recurrenceList.length,
                        list: recurrenceList,
                        countFromNow: recurrenceFromNowList.length,
                        listFromNow: recurrenceFromNowList,
                      });
                    }
                  }
                })
                .catch((err) => {
                  console.log({ err });
                  // setFetching((prevState) => ({ ...prevState, class: false }));
                });
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        // setProgress(60);
        setFetching((prevState) => ({ ...prevState, data: false }));
      }
      console.log("Getting workings...");
      setProgress(60);
      setFetching((prevState) => ({ ...prevState, workings: true }));
      await getWorkingByProperties(token, workingProps)
        .then((res) => {
          if (res && res.status === 200) {
            if (!mountedRef.current) return null;
            const workingsData = res.data;
            console.log({ workingsData });
            workingsData.sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
            setWorkings(workingsData);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, workings: false }));
      if (alertContent) {
        alert(alertContent);
      }
    }
    doTask();
  }, [id, token]);

  const workingOptions = React.useMemo(() => {
    return workings.map((item) => ({
      label: item.name + " (" + item.userId.name + ")",
      id: item._id,
      data: item,
    }));
  }, [workings]);

  const workingChoosen = React.useMemo(() => {
    for (let i = 0; i < workingOptions.length; i++) {
      if (workingOptions[i].id === working?._id) {
        return workingOptions[i];
      }
    }
    return null;
  }, [working?._id, workingOptions]);

  const workingRoomOptions = React.useMemo(() => {
    return workingRooms.map((item) => ({
      label: item.name,
      id: item._id,
      data: item,
    }));
  }, [workingRooms]);

  const workingRoomChoosen = React.useMemo(() => {
    for (let i = 0; i < workingRoomOptions.length; i++) {
      if (workingRoomOptions[i].id === workingRoom?._id) {
        return workingRoomOptions[i];
      }
    }
    return null;
  }, [workingRoom?._id, workingRoomOptions]);

  const updateDB = React.useCallback(
    async (
      _id,
      calendarEventId,
      calendarEventLink,
      start,
      end,
      recurrence,
      callBack = function () {}
    ) => {
      const newItem = {
        _id,
        workingId: working?._id,
        userId: user?._id,
        workingRoomId: workingRoom?._id,
        startTime: start,
        endTime: end,
        salaryPerHour,
        cashStart,
        cashEnd,
        note,
        status,
        payed,
        attendees,
        calendarEventId,
        calendarEventLink,
        recurrence,
        lastUpdatedBy: userInfo?._id,
      };
      if (id) {
        // newItem["_id"] = _id;
        console.log({ newItem });
        const resStatus = await updateWorkingSession(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              callBack();
              return res.status;
              // alert("Update Successfully!");
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            return err.response.status;
          });
        return resStatus;
      } else {
        // console.log({ newItem });
        const resStatus = await createWorkingSession(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              callBack();
              return res.status;
              // alert("Create Successfully!");
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            return err.response.status;
          });
        return resStatus;
      }
    },
    [
      working?._id,
      user?._id,
      workingRoom?._id,
      salaryPerHour,
      cashStart,
      cashEnd,
      note,
      status,
      payed,
      attendees,
      userInfo?._id,
      id,
      token,
    ]
  );

  const makeRecurrenceObject = React.useCallback((repeat, count, id) => {
    let obj = null;
    if (repeat?.value > 0 && count > 1) {
      obj = { repeat };
      if (id) {
        obj["id"] = id;
      } else {
        obj["id"] = v4();
      }
    }
    return obj;
  }, []);

  const supportMakeRecurrenceList = React.useCallback(
    (count, recurrenceList, list, recurrenceId) => {
      recurrenceList.forEach((item) => {
        list.push({
          _id: item._id ? item._id : new ObjectID(),
          oldData: item,
          startTime: moment(item.startTime)
            .set({
              hour: moment(startTime).get("hour"),
              minute: moment(startTime).get("minute"),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          endTime: moment(item.endTime)
            .set({
              hour: moment(endTime).get("hour"),
              minute: moment(endTime).get("minute"),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          recurrence: makeRecurrenceObject(
            recurrence?.repeat,
            count,
            recurrenceId
          ),
        });
      });
    },
    [startTime, endTime, makeRecurrenceObject, recurrence?.repeat]
  );
  const makeRecurrenceList = React.useCallback(
    (recurrenceAction) => {
      let list = []; //this class
      if (id) {
        switch (recurrenceAction) {
          case RECURRENCE_ACTION_VALUE.thisOne: {
            //this class only
            list.push({
              _id: data?._id ? data?._id : new ObjectID(),
              oldData: data,
              startTime: moment(startTime)
                .set({ second: 0, millisecond: 0 })
                .toISOString(),
              endTime: moment(endTime)
                .set({ second: 0, millisecond: 0 })
                .toISOString(),
              recurrence: makeRecurrenceObject(
                WORKING_SESSION_REPEAT_OPTIONS[0],
                1,
                null
              ),
            });
            // setRecurrence((prevState) => ({
            //   ...prevState,
            //   id: null,
            //   count: 1,
            // }));
            break;
          }
          case RECURRENCE_ACTION_VALUE.oneAndFollowing: {
            //this and following
            supportMakeRecurrenceList(
              recurrence.countFromNow,
              recurrence.listFromNow,
              list,
              v4()
            );

            // setRecurrence((prevState) => ({
            //   ...prevState,
            //   id: null,
            //   count: recurrence.countFromNow,
            // }));
            break;
          }
          case RECURRENCE_ACTION_VALUE.all: {
            supportMakeRecurrenceList(
              recurrence.count,
              recurrence.list,
              list,
              recurrence.id
            );
            break;
          }
          default: {
          }
        }
      } else {
        if (recurrence?.repeat?.value > 0 && recurrence?.count > 1) {
          const recurrenceObj = makeRecurrenceObject(
            recurrence.repeat,
            recurrence.count,
            v4()
          );
          for (let i = 0; i < recurrence.count; i++) {
            const step = i * recurrence.repeat?.value * 24 * 60 * 60 * 1000;
            list.push({
              _id: new ObjectID(),
              oldData: null,
              startTime: moment(
                moment(startTime).set({ second: 0, millisecond: 0 }).valueOf() +
                  step
              ).toISOString(),
              endTime: moment(
                moment(endTime).set({ second: 0, millisecond: 0 }).valueOf() +
                  step
              ).toISOString(),
              recurrence: recurrenceObj,
            });
          }
        } else {
          list.push({
            _id: new ObjectID(),
            oldData: null,
            startTime: moment(startTime)
              .set({ second: 0, millisecond: 0 })
              .toISOString(),
            endTime: moment(endTime)
              .set({ second: 0, millisecond: 0 })
              .toISOString(),
            recurrence: makeRecurrenceObject(
              WORKING_SESSION_REPEAT_OPTIONS[0],
              1,
              null
            ),
          });
        }
      }
      return list;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, endTime, id, recurrence, startTime, supportMakeRecurrenceList]
  );

  const submitAdvance = React.useCallback(
    async (recurrenceAction) => {
      let alertContent = "";
      let successCount = 0;
      let errorCount = 0;
      setProgress(0);
      setFetching((prevState) => ({ ...prevState, class: true }));
      // try {
      const recurrenceList = makeRecurrenceList(recurrenceAction);
      console.log({ recurrenceList });
      // const result = window.confirm("Update other class(s) on calendar?");
      //********* Update class No. of other classes in the booking **************/
      // let eventBack = null;
      let errorOthersCount = 0;
      // let successOthersCount = 0;
      let workingSessionList = [];
      if (userInfo?.role === "admin") {
        const { update, sessionList, updatedCount, errorCount } =
          await updateOtherSessionNoCalendar(
            token,
            working?._id,
            working?._id === data?.workingId?._id ? (id ? false : true) : true,
            recurrenceList,
            working?._id === data?.workingId?._id ? sessionsInWorking : [],
            setProgress,
            10,
            50
          );
        console.log({ sessionList });
        if (update) {
          alertContent += `Calendar: [${updatedCount} OK] - [${errorCount} ERROR]`;
        }
        errorOthersCount = errorCount;
        // successOthersCount = updatedCount;
        workingSessionList = sessionList;
      }
      //************* end update Class No. of other classes in the booking */
      if (errorOthersCount === 0) {
        for (let i = 0; i < recurrenceList.length; i++) {
          const item = recurrenceList[i];
          const _id = item?._id;
          // let itemClassNo = "Access Link below";
          let event;
          if (userInfo?.role === "admin") {
            let itemNo;
            for (let k = 0; k < workingSessionList.length; k++) {
              if (workingSessionList[k]._id === _id) {
                itemNo = k + 1 + "/" + workingSessionList.length;
                break;
              }
            }
            event = makeCalendarEvent(
              makeCalendarEventSummary(user?.username),
              "",
              makeCalendarEventDes(
                _id,
                itemNo,
                status,
                user?.phone,
                note,
                cashStart,
                cashEnd,
                data?.spendingSessionId?.spendings
              ),
              item.startTime,
              item.endTime,
              "",
              attendees,
              makeCalendarEventColorId(status, workingRoom)
            );
            console.log({ event });
          }
          // await sleep(1, 100);
          if (id) {
            let eventRes = { status: 200 };
            if (userInfo?.role === "admin") {
              eventRes = await updateCalendarEvent(
                event,
                item?.oldData?.calendarEventId,
                item?.oldData?.workingRoomId?.calendarId
              );
              console.log({ eventRes });
              if (eventRes.status === 200) {
                if (item?.oldData?.workingRoomId?._id !== workingRoom?._id) {
                  eventRes = await moveCalendarEvent(
                    workingRoom?.calendarId,
                    item?.oldData?.calendarEventId,
                    item?.oldData?.workingRoomId?.calendarId
                  );
                  console.log({ eventRes });
                  if (eventRes.status === 200) {
                    eventRes = await getCalendarEvent(
                      item?.oldData?.calendarEventId,
                      workingRoom?.calendarId
                    );
                    console.log({ eventRes });
                  }
                }
              }
            }
            if (eventRes?.status === 200) {
              //works OK with calendar then update DB
              const sessionStatus = await updateDB(
                _id,
                item?.oldData?.calendarEventId,
                eventRes?.result?.htmlLink,
                item.startTime,
                item.endTime,
                item.recurrence
              );
              if (sessionStatus === 200) {
                successCount += 1;
              } else {
                errorCount += 1;
              }
            } else {
              errorCount += 1;
            }
            // } else {
            //   //if role not an admin, successCount += 1
            //   successCount += 1;
            // }
          } else {
            const eventRes = await insertCalendarEvent(
              event,
              workingRoom?.calendarId
            );
            console.log({ eventRes });
            if (eventRes.status === 200) {
              const sessionStatus = await updateDB(
                _id,
                eventRes.result.id,
                eventRes.result.htmlLink,
                item.startTime,
                item.endTime,
                item.recurrence
              );
              if (sessionStatus === 200) {
                successCount += 1;
              } else {
                errorCount += 1;
              }
            } else {
              errorCount += 1;
            }
          }
        }
        if (userInfo?.role === "admin") {
          //update event no. on old working sessions (changing working case)
          if (data && working?._id !== data?.workingId?._id) {
            const { update, classList, updatedCount, errorCount } =
              await updateOtherSessionNoCalendar(
                token,
                data?.workingId?._id,
                true,
                [],
                [],
                setProgress,
                60,
                90,
                "Update session(s) No. on calendar? [OLD]"
              );
            // console.log({ classList });
            if (update) {
              alertContent += `\nCalendar[OLD]: [${updatedCount} OK] - [${errorCount} ERROR]`;
            }
          }
        }
        alertContent += `\n${
          id ? "Update" : "Create"
        } Database: [${successCount} OK] - [${errorCount} ERROR]!`;
      }
      // } catch (err) {
      //   console.log({ err });
      // }
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, class: false }));
      if (alertContent) {
        alert(alertContent);
      }
      setReturnParent(true);
    },
    [
      makeRecurrenceList,
      userInfo?.role,
      token,
      working?._id,
      data,
      sessionsInWorking,
      id,
      user?.username,
      user?.phone,
      status,
      note,
      cashStart,
      cashEnd,
      attendees,
      workingRoom,
      updateDB,
    ]
  );

  const handleSubmit = React.useCallback(
    (ev) => {
      if (id) {
        if (recurrence.repeat.value > 0 && recurrence.count > 1) {
          setOpenDialog(true);
        } else {
          submitAdvance(RECURRENCE_ACTION_VALUE.thisOne);
        }
      } else {
        submitAdvance();
      }
    },
    [id, recurrence.count, recurrence.repeat.value, submitAdvance]
  );

  const actions = ["Allowances", "Spendings", "Add-on Import", "Summary"];
  for (let i = 0; i < actions.length; i++) {
    const item = actions[i];
    if (!SETTING_ENABLE_ALLOWANCE && item.includes("Allowance")) {
      actions.splice(i, 1);
    }
  }
  const handleClickAction = React.useCallback(
    (event, action) => {
      switch (action) {
        case "Allowances":
          navigate("allowance");
          break;
        case "Spendings":
          // console.log({ pathname });
          navigate("spending");
          break;
        case "Add-on Import":
          // console.log({ pathname });
          navigate("addon");
          break;
        case "Summary":
          // console.log({ pathname });
          navigate("summary");
          break;
        default:
          return;
      }
    },
    [navigate]
  );
  const rightButton = (
    <MoreActionButton actions={actions} handleClickAction={handleClickAction} />
  );

  // const rightButton = {
  //   icon: <PreviewIcon fontSize="inherit" />,
  //   callback: function (event) {
  //     navigate(pathname + "/invoice");
  //   },
  // };

  const handleDialogItemClick = React.useCallback(
    (value) => {
      setOpenDialog(false);
      // setDialogSelectedValue(value);
      submitAdvance(value);
    },
    [submitAdvance]
  );

  return (
    <>
      <Header
        title={(id ? "Edit" : "New") + " Working Session"}
        rightElement={id ? rightButton : null}
        triggerParentClick={returnParent}
      />
      {fetching.workings || fetching.data ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      ) : (
        <>
          <Grid container direction="column" spacing={3}>
            {id ? (
              <>
                <SessionInfoHeader
                  title="Session"
                  no={no}
                  calendarLink={data?.calendarEventLink}
                  parentPath="workingMenu/working"
                  parentData={
                    workingOptions?.filter((value, index, arr) => {
                      return value?.id === data?.workingId?._id;
                    })[0]
                  }
                />
                <Grid item>
                  <TextField
                    label="Session Id"
                    fullWidth
                    disabled
                    variant="outlined"
                    value={id}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {userInfo?.role === "admin" ? (
              <Grid item>
                <Autocomplete
                  className="working-options"
                  options={workingOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={workingChoosen}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      newValue = workingOptions[0];
                    }
                    setWorking(newValue.data);
                    setUser(newValue.data.userId);
                    setSalaryPerHour(newValue.data.userId.salaryPerHour);
                    setNote(newValue.data.note ? newValue.data.note : "");
                    setAttendees(
                      newValue.data.userId.email
                        ? [newValue.data.userId.email]
                        : []
                    );
                  }}
                  id="working-options"
                  sx={{ width: "100%" }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Working" variant="outlined" />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item>
              <Autocomplete
                disabled={userInfo?.role === "staff"}
                className="working-room-options"
                options={workingRoomOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={workingRoomChoosen}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    newValue = workingRoomOptions[0];
                  }
                  setWorkingRoom(newValue.data);
                }}
                id="working-room-options"
                sx={{ width: "100%" }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Working Room"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DateTimePickerField
                disabled={userInfo?.role === "staff"}
                label="Start Time"
                value={startTime}
                onChange={(newValue) => {
                  // console.log({newValue});
                  if (newValue) {
                    newValue.set({ second: 0, millisecond: 0 });
                    setStartTime(newValue.toISOString());
                    setTime(convertTimeToHourMin(moment(endTime) - newValue));
                  }
                }}
              />
            </Grid>
            <Grid item>
              <DateTimePickerField
                disabled={userInfo?.role === "staff"}
                label="End Time"
                value={endTime}
                onChange={(newValue) => {
                  // console.log({newValue});
                  if (newValue) {
                    newValue.set({ second: 0, millisecond: 0 });
                    setEndTime(newValue.toISOString());
                    setTime(convertTimeToHourMin(newValue - moment(startTime)));
                  }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Working Time"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="time"
                variant="outlined"
                value={time}
                disabled
              />
            </Grid>
            <Grid item>
              <NumberField
                disabled={userInfo?.role === "staff"}
                label="Salary (Per Hour)"
                fullWidth
                name="salaryPerHour"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={salaryPerHour}
                myOnChange={(e, newValue) => {
                  setSalaryPerHour(newValue);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={userInfo?.role === "staff"}
                label="Attendees"
                fullWidth
                name="time"
                variant="outlined"
                value={attendees}
                onChange={(e) => {
                  setAttendees(e.target.value.split(","));
                }}
              />
            </Grid>
            {/* <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-status-label">Status</InputLabel>
                  <Select
                    labelId="select-status-label"
                    id="select-status"
                    value={status}
                    fullWidth
                    label="Status"
                    onChange={(event) => {
                      const state = event.target.value;
                      setStatus(state);
                    }}
                  >
                    {WORKING_SESSION_STATUS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid> */}
            <Grid item>
              <Autocomplete
                disabled={id ? true : false}
                className="working-session-repeat-options"
                options={WORKING_SESSION_REPEAT_OPTIONS}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                // defaultValue={CLASS_REPEAT_OPTIONS[0]}
                value={
                  recurrence?.repeat
                    ? recurrence.repeat
                    : WORKING_SESSION_REPEAT_OPTIONS[0]
                }
                onChange={(event, newValue) => {
                  setRecurrence((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          repeat: newValue,
                        }
                      : {
                          // id: v4(),
                          repeat: newValue,
                        }
                  );
                }}
                id="working-session-repeat-options"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Repeat" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item>
              <NumberField
                label="Count"
                fullWidth
                disabled={
                  id || recurrence?.repeat === WORKING_SESSION_REPEAT_OPTIONS[0]
                    ? true
                    : false
                }
                name="count"
                variant="outlined"
                myValue={recurrence?.count}
                myOnChange={(e, newValue) => {
                  setRecurrence((prevState) => ({
                    ...prevState,
                    count: parseInt(newValue),
                  }));
                }}
              />
            </Grid>
            <Grid item>
              <NumberField
                disabled={userInfo?.role === "staff"}
                label="Payed"
                fullWidth
                name="payed"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={payed}
                myOnChange={(e, newValue) => {
                  setPayed(newValue);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              />
            </Grid>
            <Grid item>
              <NumberField
                error
                label="Cash (Start)"
                fullWidth
                name="cashStart"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={cashStart}
                myOnChange={(e, newValue) => {
                  setCashStart(newValue);
                }}
              />
            </Grid>
            <Grid item>
              <NumberField
                error
                label="Cash (End)"
                fullWidth
                name="cashEnd"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={cashEnd}
                myOnChange={(e, newValue) => {
                  setCashEnd(newValue);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                error
                label="Note"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <ConfirmUpdateDialog
            dialogOpen={openDialog}
            setDialogOpenState={(state) => {
              setOpenDialog(state);
            }}
            handleItemClick={handleDialogItemClick}
            countAll={recurrence.count}
            countFromNow={recurrence.countFromNow}
            title="Class"
          />
        </>
      )}
    </>
  );
}
