import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export default function PhotoActionsDialog(props) {
  const { setDialogOpenState, dialogOpen, handleItemClick } = props;
  const options = [
    { label: `View`, value: "view" },
    {
      label: `Download`,
      value: "download",
    },
  ];

  const handleClose = () => {
    setDialogOpenState(false);
  };

  const handleListItemClick = (value) => {
    setDialogOpenState(false);
    handleItemClick(value);
  };

  return (
    <Dialog onClose={handleClose} open={dialogOpen}>
      <DialogTitle>Photo Actions</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options.map((option) => (
          <ListItem
            button
            onClick={() => handleListItemClick(option.value)}
            key={option.value}
          >
            <ListItemText primary={option.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

PhotoActionsDialog.propTypes = {
  setDialogOpenState: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleItemClick: PropTypes.func.isRequired,
};
