import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import PosDialogItem from "./PosDialogItem";

export default function PosDialog(props) {
  const { setDialogOpenState, dialogOpen, dataList, title, handleItemClick } =
    props;

  const handleClose = () => {
    setDialogOpenState(false);
  };

  const handleListItemClick = (item) => {
    handleItemClick(item);
  };

  return (
    <Dialog onClose={handleClose} open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem
          button
          onClick={(event) => handleListItemClick(null)}
          key="new"
        >
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
          >
            NEW
          </Button>
        </ListItem>
        {dataList?.map((item, index) => (
          <ListItem
            button
            onClick={(event) => handleListItemClick(item)}
            key={item._id}
          >
            <PosDialogItem number={index + 1} item={item} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

PosDialog.propTypes = {
  setDialogOpenState: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  dataList: PropTypes.array,
  title: PropTypes.string,
  handleItemClick: PropTypes.func.isRequired,
};
