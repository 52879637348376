import { Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import moment from "moment";
import "moment/locale/vi";
import "./ClassItem.css";
import { AuthContext } from "../../../../context/AuthProvider";

export default function ClassItem({ item, number, deleteItem }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token, userInfo } = React.useContext(AuthContext);
  const handleItemClick = React.useCallback((event) => {
    // navigate(pathname + "/" + item._id + "/view");
  }, []);
  const handleEditClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      navigate(pathname + "/" + item._id);
    },
    [navigate, pathname, item._id]
  );
  const handleDeleteClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      const result = window.confirm("Are you sure to delete: " + number);
      if (result) {
        deleteItem(item);
      }
    },
    [deleteItem, item, number]
  );
  const dateTime = () => {
    const date = moment(item.startTime).format("dddd, DD/MM/yyyy");
    const output =
      date.charAt(0).toUpperCase() +
      date.slice(1) +
      " (" +
      moment(item.startTime).format("HH:mm") +
      "-" +
      moment(item.endTime).format("HH:mm") +
      ")";
    return output;
  };
  const colorName = React.useMemo(() => {
    switch (item.status) {
      case "move":
      case "active": {
        // console.log({ color: room?.colorCalendarId });
        return item.roomId.color.label ? item.roomId.color.label : "";
      }
      case "pending": {
        return item.roomId.colorPending.label
          ? item.roomId.colorPending.label
          : "";
      }
      case "cancel": {
        return item.roomId.colorCancel.label
          ? item.roomId.colorCancel.label
          : "";
      }
      default: {
        return "";
      }
    }
  }, [
    item.roomId.color.label,
    item.roomId.colorCancel.label,
    item.roomId.colorPending.label,
    item.status,
  ]);
  return (
    <Grid
      onClick={(event) => handleItemClick(event)}
      container
      spacing={0}
      direction="row"
      className={"item-list " + colorName}
    >
      <Grid item xs={1}>
        <Typography
          align="center"
          className={item.calendarId ? "link-to-calendar" : ""}
        >
          {number + "."}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align="left">{dateTime()}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography align="center">{item.roomId.name}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography noWrap align="center">
          {item.customerId?.name}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label="edit"
          size="small"
          onClick={(event) => handleEditClick(event)}
        >
          <EditIcon
            className={"item-list-icon-" + item.status}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        {userInfo?.role === "admin" ? (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(event) => handleDeleteClick(event)}
          >
            <DeleteIcon
              className={"item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        ) : (
          <IconButton aria-label="delete" size="small" onClick={(event) => {}}>
            <HorizontalRuleIcon
              className={"item-list-icon-" + item.status}
              fontSize="inherit"
            />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
