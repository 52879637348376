import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { deleteVat, getVatAll } from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import AddIcon from "@mui/icons-material/Add";
import ListSearch from "../../../Header/ListSearch";
import ListHeader from "../../../Header/ListHeader";
import EditVat from "./EditVat";
import VatItem from "./VatItem";
import { updateOwnerObject } from "./EditVat";

export default function Vat() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  console.log({ pathArray });
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const [fetching, setFetching] = React.useState(false);
  //   console.log("re-render");
  const [search, setSearch] = React.useState("");

  const updateDatas = React.useCallback(
    (data, newItem, updatedItem, deletedItem) => {
      // newDatas.sort(function (a, b) {
      //   return new Date(b.createdAt) - new Date(a.createdAt);
      // });
      if (data) {
        setInitDatas(data);
        setDatas(data);
      } else if (newItem) {
        setInitDatas((prevState) => [...prevState, newItem]);
        setDatas((prevState) => [...prevState, newItem]);
      } else if (updatedItem) {
        setInitDatas((prevState) =>
          prevState.map((item) => {
            return item._id === updatedItem._id ? updatedItem : item;
          })
        );
        setDatas((prevState) =>
          prevState.map((item) => {
            return item._id === updatedItem._id ? updatedItem : item;
          })
        );
      } else if (deletedItem) {
        setInitDatas((prevState) => {
          return prevState.filter((value, index, array) => {
            return value._id !== deletedItem._id;
          });
        });
        setDatas((prevState) => {
          return prevState.filter((value, index, array) => {
            return value._id !== deletedItem._id;
          });
        });
      }
    },
    []
  );
  const handleSearch = React.useCallback(
    (search) => {
      console.log({ search });
      setSearch(search);
      if (search) {
        // console.log("filter");
        const newDatas = initDatas.filter(function (value, index, arr) {
          return (
            value.company.toLowerCase().includes(search.toLowerCase()) ||
            value.phone.includes(search) ||
            value.customerId.name.includes(search.toLowerCase()) ||
            value.customerId.phone.includes(search)
          );
        });
        setDatas(newDatas);
      } else {
        // console.log("not filter");
        setDatas(initDatas);
      }
      // navigate(pathname + "/" + search.id);
    },
    [initDatas]
  );

  const deleteItem = React.useCallback(
    async (item) => {
      setFetching(true);
      await updateOwnerObject(
        item?.classId?._id,
        item?.bookingId?._id,
        null,
        userInfo?._id,
        token
      );
      deleteVat(token, item)
        .then((res) => {
          alert("Delete Successfully!");
          console.log({ res });
          updateDatas(null, null, null, res.data);
          setFetching(false);
        })
        .catch((err) => {
          console.log({ err });
          setFetching(false);
        });
      //   setRules(newRules);
    },
    [token, updateDatas, userInfo?._id]
  );
  React.useEffect(() => {
    console.log("Getting new data");
    setFetching(true);
    getVatAll(token)
      .then((res) => {
        console.log({ res });
        if (res && res.status === 200) {
          updateDatas(res.data, null, null, null);
        }
        setFetching(false);
      })
      .catch((err) => {
        console.log({ err });
        setFetching(false);
      });
  }, [token, updateDatas]);
  const action = pathArray.pop();
  console.log({ action });
  if (action === "new") {
    return <EditVat updateDatas={updateDatas} />;
  } else if (id) {
    return <EditVat itemId={id} datas={datas} updateDatas={updateDatas} />;
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < datas.length; i++) {
      const item = datas[i];
      //   if (
      //     userInfo?.role === "admin" ||
      //     (userInfo?.role === "staff" && userInfo?._id === item._id)
      //   ) {
      rows.push(
        <Grid item key={item._id}>
          <VatItem number={i + 1} item={item} deleteItem={deleteItem} />
        </Grid>
      );
      //   }
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header title="VATs" rightButton={rightButton} />
        <ListSearch
          value={search}
          placeholder="Name"
          handleChange={handleSearch}
        />
        <ListHeader
          titles={[
            { width: 1, align: "center", title: "No." },
            { width: 7, align: "center", title: "Company" },
            { width: 2, align: "center", title: "B.Status" },
            { width: 2, align: "center", title: "Action" },
          ]}
        />
        {fetching ? (
          <CircularProgress />
        ) : (
          <Grid container direction="column" spacing={0}>
            {rows}
          </Grid>
        )}
      </>
    );
  }
}
