import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Popover,
  MenuItem,
  MenuList,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import "moment/locale/vi";
import { AuthContext } from "../../../../../context/AuthProvider";
import { WORKING_STATUS } from "../../../../../constants";
import {
  createWorking,
  getUserAll,
  getWorkingById,
  searchWorkingSession,
  updateWorking,
} from "../../../../../api/api";
import Header from "../../../../Header/Header";
import MoreActionButton from "../../../../Header/MoreActionButton";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
// import { NumberFormatVNCurrency } from "../../../../../helpers/NumberFormatCustom";
import NumberField from "../../../../../helpers/NumberField";
import {
  updateItemSession,
  updateItemSessionDes,
  updateOtherSessionNoCalendar,
} from "../WorkingSession/EditWorkingSession";

export default function EditWorking() {
  moment.locale("vi");
  // moment.utcOffset(0);
  // const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, userInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [userId, setUserId] = React.useState("");
  const [name, setName] = React.useState("");
  const [note, setNote] = React.useState("");
  const [kpiHour, setKpiHour] = React.useState(0);
  const [kpiHourReward, setKpiHourReward] = React.useState(0);
  const [status, setStatus] = React.useState(WORKING_STATUS[0].value);
  const [fetching, setFetching] = React.useState({
    users: false,
    data: false,
  });
  const [progress, setProgress] = React.useState(0);
  const [returnParent, setReturnParent] = React.useState(false);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    async function abc() {
      setProgress(0);
      let alertContent;
      if (id) {
        console.log("Getting data...");
        setProgress(30);
        setFetching((prevState) => ({ ...prevState, data: true }));
        await getWorkingById(token, id)
          .then(async (res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const item = res.data;
              console.log({ item });
              setData(item);
              setUsers([item.userId]);
              setUserId(item.userId._id);
              setName(item.name);
              setNote(item.note);
              setStatus(item.status);
              setKpiHour(item.kpiHour);
              setKpiHourReward(item.kpiHourReward);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, data: false }));
      } else {
        console.log("Getting users...");
        setProgress(30);
        setFetching((prevState) => ({ ...prevState, users: true }));
        await getUserAll(token)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const usersData = res.data;
              console.log({ usersData });
              usersData.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
              setUsers(usersData);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, users: false }));
      }
      if (alertContent) {
        alert(alertContent);
      }
    }
    abc();
  }, [id, token]);

  const updateSessions = React.useCallback(
    async (startProgress, endProgress, field = "") => {
      // setProgress(startProgress);
      // const { note, status } = field;
      const workingSessions = await searchWorkingSession(token, {
        search: id,
      }).then((res) => {
        if (res && res.status === 200) {
          return res.data;
        }
      });
      workingSessions.sort(function (a, b) {
        return new Date(a.startTime) - new Date(b.startTime);
      });
      console.log({ workingSessions });
      let successCount = 0;
      let errorCount = 0;
      for (let i = 0; i < workingSessions.length; i++) {
        const sessionItem = workingSessions[i];
        switch (field) {
          case "note":
            sessionItem["note"] = note;
            break;
          case "status":
            sessionItem["status"] = status;
            break;
          default:
            sessionItem["note"] = note;
            sessionItem["status"] = status;
        }
        sessionItem["no"] = i + 1 + "/" + workingSessions.length;
        console.log({ sessionItem });
        setProgress(
          (i / workingSessions.length) * (endProgress - startProgress) +
            startProgress
        );
        const { calendarCode, serverCode } = await updateItemSession(
          token,
          sessionItem,
          userInfo
        );
        if (serverCode === 200) {
          successCount += 1;
        } else {
          errorCount += 1;
        }
      }
      return { totalCount: workingSessions.length, successCount, errorCount };
    },
    [id, note, status, token, userInfo]
  );

  const handleUpdateChildren = React.useCallback(
    async (event, field) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, data: true }));
      let alertContent;
      if (id) {
        let successSessionCount = 0;
        let errorSessionCount = 0;

        const result = window.confirm(
          "Do you want update to all child session(s)?"
        );
        if (result) {
          const { successCount, errorCount } = await updateSessions(
            10,
            70,
            field
          );
          // console.log({res});
          successSessionCount = successCount;
          errorSessionCount = errorCount;
        }
        if (errorSessionCount === 0) {
          alertContent = `Update ${successSessionCount} Session(s) success!`;
        } else {
          alertContent = `Update ${errorSessionCount} session(s) failed!`;
        }
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
    },
    [id, updateSessions]
  );

  const handleSubmit = React.useCallback(
    async (event) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, data: true }));
      let alertContent;
      const newItem = {
        userId,
        name,
        note,
        status,
        lastUpdatedBy: userInfo?._id,
      };
      if (id) {
        newItem["_id"] = id;
        console.log({ newItem });
        let successSessionCount = 0;
        let errorSessionCount = 0;
        if (note !== data?.note || status !== data?.status) {
          const result = window.confirm(
            "Note or Status are changed. Do you want to update all child session(s)"
          );
          if (result) {
            const { successCount, errorCount } = await updateSessions(10, 70);
            // console.log({res});
            successSessionCount = successCount;
            errorSessionCount = errorCount;
          }
        }
        if (errorSessionCount === 0) {
          await updateWorking(token, newItem)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent = `Update Working and ${successSessionCount} Session(s) success!`;
              } else {
                alertContent = `Update Working FAILED and ${successSessionCount} Session(s) SUCCESS! Error: ${res.status}`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent = `Update Working FAILED and Update ${successSessionCount} Session(s) SUCCESS! Error: ${err}`;
            });
        } else {
          alertContent = `Update Working FAILED! Error session(s): ${errorSessionCount}`;
        }
      } else {
        console.log({ newItem });
        await createWorking(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent = `Create success!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
      setReturnParent(true);
    },
    [
      userId,
      name,
      note,
      status,
      userInfo?._id,
      id,
      data?.note,
      data?.status,
      token,
      updateSessions,
    ]
  );

  const userOptions = React.useMemo(() => {
    return users.map((item) => ({
      label: item.name + " (" + item.phone + ")",
      id: item._id,
      data: item,
    }));
  }, [users]);

  const userChoosen = React.useMemo(() => {
    for (let i = 0; i < userOptions.length; i++) {
      if (userOptions[i].id === userId) {
        return userOptions[i];
      }
    }
    return null;
  }, [userId, userOptions]);

  const actions = ["Summary", "Update Session No."];
  const handleClickAction = React.useCallback(
    async (event, action) => {
      let alertContent;
      switch (action) {
        case "Summary":
          navigate("summary");
          break;
        case "Update Session No.":
          setFetching((prevState) => ({ ...prevState, data: false }));
          const { update, updatedCount, errorCount } =
            await updateOtherSessionNoCalendar(
              token,
              id,
              true,
              [],
              [],
              setProgress,
              10,
              50
            );
          if (update) {
            alertContent = `Calendar: [${updatedCount} OK] - [${errorCount} ERROR]`;
          }
          break;
        default:
          return;
      }
      setFetching((prevState) => ({ ...prevState, data: false }));
      if (alertContent) {
        alert(alertContent);
      }
    },
    [id, navigate, token]
  );
  const rightButton = (
    <MoreActionButton actions={actions} handleClickAction={handleClickAction} />
  );
  // const rightButton = {
  //   icon: <PreviewIcon fontSize="inherit" />,
  //   callback: function (event) {
  //     navigate(pathname + "/invoice");
  //   },
  // };

  return (
    <>
      <Header
        title={(id ? "Edit" : "New") + " Working"}
        rightElement={id ? rightButton : null}
        triggerParentClick={returnParent}
      />
      {fetching.users || fetching.data ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      ) : (
        <>
          <Grid container direction="column" spacing={3}>
            <Grid item className={id ? "" : "no-display"}>
              <TextField
                label="Id"
                fullWidth
                disabled
                variant="outlined"
                value={id}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                disabled={id ? true : false}
                className="user-options"
                options={userOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={userChoosen}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    newValue = userOptions[0];
                  }
                  setUserId(newValue?.id);
                }}
                id="user-options"
                sx={{ width: "100%" }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="User" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Name"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <NumberField
                label="KPI (Working Hour)"
                fullWidth
                name="kpiHour"
                myValue={kpiHour}
                myOnChange={(e, newValue) => {
                  setKpiHour(newValue);
                }}
              />
            </Grid>
            <Grid item>
              <NumberField
                label="KPI Reward (Working Hour)"
                fullWidth
                name="kpiHourReward"
                myValue={kpiHourReward}
                myOnChange={(e, newValue) => {
                  setKpiHourReward(newValue);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Note"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleUpdateChildren(event, "note")}
              >
                Update Note to Children
              </Button>
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-status-label">Status</InputLabel>
                  <Select
                    labelId="select-status-label"
                    id="select-status"
                    value={status}
                    fullWidth
                    label="Status"
                    onChange={(event) => {
                      const state = event.target.value;
                      setStatus(state);
                    }}
                  >
                    {WORKING_STATUS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleUpdateChildren(event, "status")}
              >
                Update Status to Children
              </Button>
            </Grid>
            <Grid item>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
