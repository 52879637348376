import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import React from "react";
// import AdapterMoment from "@mui/lab/AdapterMoment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export default function DatePickerField({ ...props }) {
  moment.locale("en");
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          renderInput={(propsInput) => <TextField fullWidth {...propsInput} />}
          inputFormat="dddd, DD/MM/yyyy"
          {...props}
        />
      </LocalizationProvider>
    </>
  );
}
