import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { getDiscountRules, updateDiscountRules } from "../../../../../../api/api";
import { AuthContext } from "../../../../../../context/AuthProvider";
import Header from "../../../../../Header/Header";
import EditDiscountRule from "./EditDiscountRule";
import DiscountRulesItem from "./DiscountRulesItem";
import AddIcon from "@mui/icons-material/Add";

export default function DiscountRules() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const navigate = useNavigate();
  const { id } = useParams();
  console.log({ id });
  const [rules, setRules] = React.useState([]);
  const { token } = React.useContext(AuthContext);
  //   console.log("re-render");
  const updateRules = React.useCallback((res) => {
    console.log({ res });
    if (res && res.status === 200) {
      const rulesArray = res.data;
      setRules(rulesArray);
    }
  }, []);
  const deleteItem = React.useCallback(
    (item) => {
      //   const datas = rules;
      // let itemIndex;
      //   for (let i = 0; i < rules.length; i++) {
      //     if (rules[i]._id === item._id) {
      //       itemIndex= i;
      //       break;
      //     }
      //   }
      //   console.log({itemIndex});
      const newRules = rules.filter(function (value, index, arr) {
        return value._id !== item._id;
      });
      updateDiscountRules(token, newRules)
        .then((res) => {
          alert("Delete Successfully!");
          updateRules(res);
        })
        .catch((err) => {
          console.log({ err });
        });
      //   setRules(newRules);
    },
    [rules, token, updateRules]
  );
  React.useEffect(() => {
    console.log("getDiscountRules");
    getDiscountRules(token)
      .then((res) => {
        updateRules(res);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [token, updateRules]);
  if (id) {
    return (
      <>
        <EditDiscountRule ruleId={id} rules={rules} setRules={setRules} />
      </>
    );
  } else if (pathArray.pop() === "new") {
    return <EditDiscountRule rules={rules} setRules={setRules} />;
  } else {
    // console.log({ rules });
    let rows = [];
    for (var i = 0; i < rules.length; i++) {
      const item = rules[i];
      rows.push(
        <Grid item key={item._id}>
          <DiscountRulesItem
            number={i + 1}
            rule={item}
            deleteItem={deleteItem}
          />
        </Grid>
      );
    }
    const rightButton = {
      icon: <AddIcon fontSize="inherit" />,
      callback: function (event) {
        navigate(pathname + "/new");
      },
    };
    return (
      <>
        <Header title="Discount Rules" rightButton={rightButton} />
        <Grid container direction="column" spacing={0}>
          {rows}
        </Grid>
      </>
    );
  }
}
