import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
// import { RECURRENCE_ACTION_VALUE } from "../constants";
import moment from "moment";
import { CLASS_STATUS } from "../constants";
import { convertTimeToHourMin, numberWithCommas } from "./stringDate";

function ClassItem({ number, data }) {
  const status = CLASS_STATUS.filter((value, index, array) => {
    return value.value === data?.status;
  })[0];
  return (
    <Grid item>
      <Box component="span" sx={{ display: "inline", fontWeight: "bold" }}>
        {number + ". "}
      </Box>
      <Box component="span" sx={{ display: "inline", fontWeight: "regular" }}>
        <Link
          href={
            window.location.protocol +
            "//" +
            window.location.host +
            "/class/" +
            data?._id
          }
        >
          {moment(data?.startTime).format("dddd, DD/MM/yyyy HH:mm") +
            "-" +
            moment(data?.endTime).format("HH:mm")}
        </Link>
      </Box>
      <Box component="div" sx={{ fontWeight: "regular" }}>
        {`${convertTimeToHourMin(
          moment(data?.endTime) - moment(data?.startTime)
        )} hours - ${status.label} - Room: ${
          data?.roomId.name
        } - Price/Hour: ${numberWithCommas(
          data?.pricePerHour,
          " đ"
        )} - Payed: ${numberWithCommas(data?.payed, " đ")}${
          data?.note ? " - (" + data?.note + ")" : ""
        }`}
      </Box>
    </Grid>
  );
}

export default function RecurrenceDialog(props) {
  const {
    setDialogOpenState,
    dialogOpen,
    recurrenceList,
    title,
    handleItemClick,
  } = props;
  // const options = [
  //   { label: `This ${title}`, value: RECURRENCE_ACTION_VALUE.thisOne },
  //   {
  //     label: `This and following ${title}(s) (${countFromNow})`,
  //     value: RECURRENCE_ACTION_VALUE.oneAndFollowing,
  //   },
  //   {
  //     label: `All ${title}(s) (${countAll})`,
  //     value: RECURRENCE_ACTION_VALUE.all,
  //   },
  // ];

  const handleClose = () => {
    setDialogOpenState(false);
  };

  const handleListItemClick = (item) => {
    handleItemClick(item);
  };

  return (
    <Dialog onClose={handleClose} open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {recurrenceList?.map((item, index) => (
          <ListItem
            button
            onClick={(event) => handleListItemClick(item)}
            key={item._id}
          >
            <ClassItem number={index + 1} data={item} />
            {/* <ListItemText primary={moment(item.startTime).format(
                          "dddd, DD/MM/yyyy HH:mm"
                        )} /> */}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

RecurrenceDialog.propTypes = {
  setDialogOpenState: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  recurrenceList: PropTypes.array,
  title: PropTypes.string,
  handleItemClick: PropTypes.func.isRequired,
};
