import React from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../../../context/AuthProvider";
import { createAddon, updateAddon } from "../../../../../api/api";
import Header from "../../../../Header/Header";
import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// import { NumberFormatVNCurrency } from "../../../../../helpers/NumberFormatCustom";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import { COLOR_OPTIONS } from "../../../../../constants";
import NumberField from "../../../../../helpers/NumberField";

export default function EditAddon({ itemId, datas, updateDatas, cates }) {
  console.log({ itemId });
  console.log({ datas });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [categoryId, setCategoryId] = React.useState(cates[0]?._id);
  const [inventory, setInventory] = React.useState(0);
  const [priceIn, setPriceIn] = React.useState(0);
  const [priceOut, setPriceOut] = React.useState(0);
  const [color, setColor] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const item = React.useMemo(() => {
    if (itemId) {
      let item = null;
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setName(item.name);
        setCategoryId(item.categoryId._id);
        setInventory(item.inventory);
        setPriceIn(item.priceIn);
        setPriceOut(item.priceOut);
        setColor(item.color);
      }
      console.log({ item });
      return item;
    }
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        updateDatas(null, null, res.data);
      } else {
        //created
        updateDatas(null, res.data, null);
      }
    },
    [updateDatas]
  );
  const handleSubmit = React.useCallback(
    async (event) => {
      let alertContent;
      setProgress(50);
      setFetching(true);
      const newItem = {
        name,
        categoryId,
        inventory,
        priceIn,
        priceOut,
        color,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        await updateAddon(token, newItem)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent = `Update success!`;
              setDataList(itemId, res);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      } else {
        console.log({ newItem });
        await createAddon(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              alertContent = `Create success!`;
              setDataList(itemId, res);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
      }
      setProgress(100);
      if (alertContent) {
        alert(alertContent);
      }
      setFetching(false);
    },
    [
      categoryId,
      inventory,
      itemId,
      name,
      priceIn,
      priceOut,
      color,
      setDataList,
      token,
      userInfo?._id,
    ]
  );
  const categoryChoosen = React.useMemo(() => {
    const cate = cates.filter((value, index, array) => {
      return value._id === categoryId;
    });
    // console.log({ cate });
    return cate === undefined ? null : cate[0];
  }, [categoryId, cates]);

  return (
    <>
      <Header title={(itemId ? "Edit" : "New") + " Addon"} />
      {fetching ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Autocomplete
              className="addon-category-options"
              options={cates}
              // isOptionEqualToValue={(option, value) => option._id === value}
              defaultValue={cates[0]}
              value={categoryChoosen}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                console.log({ newValue });
                if (!newValue) {
                  newValue = cates[0];
                }
                setCategoryId(newValue._id);
              }}
              id="addon-category-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              className="color-options"
              options={COLOR_OPTIONS}
              defaultValue={
                COLOR_OPTIONS.filter(function (value, index, arr) {
                  return value.id === color?.id;
                })[0]
              }
              getOptionLabel={(option) => (option.label ? option.label : "")}
              onChange={(event, newValue) => {
                // if (newValue) {
                setColor(newValue ? newValue : null);
                // }
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Color" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            {/* <NumberField
              label="Inventory"
              fullWidth
              name="inventory"
              value={inventory}
              onChange={(e) => {
                setInventory(e.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatVNCurrency,
              }}
            /> */}
            <NumberField
              label="Inventory"
              // InputLabelProps={{ shrink: true }}
              fullWidth
              name="inventory"
              myValue={inventory}
              myOnChange={(e, newValue) => {
                setInventory(newValue);
              }}
            />
          </Grid>
          <Grid item>
            <NumberField
              label="Price Buy"
              fullWidth
              name="priceIn"
              suffix=" đ"
              isThousandSeparator={true}
              myValue={priceIn}
              myOnChange={(e, newValue) => {
                setPriceIn(newValue);
              }}
              // InputProps={{
              //   inputComponent: NumberFormatVNCurrency,
              // }}
            />
          </Grid>
          <Grid item>
            <NumberField
              label="Price Sell"
              fullWidth
              name="priceOut"
              suffix=" đ"
              isThousandSeparator={true}
              myValue={priceOut}
              myOnChange={(e, newValue) => {
                setPriceOut(newValue);
              }}
              // InputProps={{
              //   inputComponent: NumberFormatVNCurrency,
              // }}
            />
          </Grid>
          <Grid item>
            <Typography component="div">
              {`Last updated by: ${data?.lastUpdatedBy?.username}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              className="btn-submit"
              size="medium"
              color="primary"
              onClick={(event) => handleSubmit(event)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
