import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@mui/material";
import React from "react";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment from "moment";
export default function DateTimePickerField({ ...props }) {
  moment.locale("en");
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          renderInput={(propsInput) => <TextField fullWidth {...propsInput} />}
          inputFormat="dddd, DD/MM/yyyy HH:mm"
          {...props}
        />
      </LocalizationProvider>
    </>
  );
}
