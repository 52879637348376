export const SETTING_MENU = [
  {
    text: "Discount Fixed",
    icon: undefined,
    type: "discountFixed",
  },
  {
    text: "Discount Rules",
    icon: undefined,
    type: "discountRules",
  },
  {
    text: "SMS Parking Car",
    icon: undefined,
    type: "smsParkingCar",
  },
  {
    text: "SMS Parking Motor",
    icon: undefined,
    type: "smsParkingMotor",
  },
  {
    text: "SMS Confirm",
    icon: undefined,
    type: "smsConfirm",
  },
  {
    text: "SMS Remind",
    icon: undefined,
    type: "smsRemind",
  },
  {
    text: "SMS Classes",
    icon: undefined,
    type: "smsClasses",
  },
  {
    text: "Remind Time",
    icon: undefined,
    type: "remindTime",
  },
  {
    text: "Operation Percent",
    icon: undefined,
    type: "operationPercent",
  },
  {
    text: "Allowance Reward",
    icon: undefined,
    type: "allowanceReward",
  },
  {
    text: "Time Zone",
    icon: undefined,
    type: "timeZone",
  }
];
