export const SETTING_ENABLE_ALLOWANCE = false;
export const SETTING_ENABLE_REWARD = false;
export const LOCAL_STORAGE_KEY_TOKEN = "token";
export const LOCAL_STORAGE_KEY_USER_INFO = "userInfo";
export const DISCOUNT_TYPE_OPTIONS = ["rule", "fixed"];
export const PAY_ALL_OPTIONS = [true, false];
export const PAYMENT_TYPE_OPTIONS = [
  { label: "Cash", value: "cash" },
  { label: "Banking", value: "banking" },
  { label: "Momo", value: "momo" },
];
export const GENDER_OPTIONS = ["male", "female", "unknown"];
export const LANGUAGE_OPTIONS = ["vi", "en"];
export const CONTACT_OPTIONS = ["Zalo", "Messenger", "Phone", "Unknown"];
export const CUSTOMER_FROM_OPTIONS = [
  "Google Ads",
  "Google Search",
  "Facebook Ads",
  "Facebook Search",
  "Facebook Fanpage",
  "Viral",
  "Local",
  "Unknown",
];
export const USER_ROLE_OPTIONS = ["admin", "staff"];
export const REMIND_ACTIVE_OPTIONS = [false, true];
export const ALLOWANCE_REWARD_ACTIVE_OPTIONS = [false, true];
export const DISCOUNT_RULE_CONTINUOUS_OPTIONS = [false, true];
export const DISCOUNT_RULE_PAY_ALL_OPTIONS = ["false", "true", "false,true"];
export const CLASS_STATUS_OPTIONS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Move",
    value: "move",
  },
  {
    label: "Cancel",
    value: "cancel",
  },
  {
    label: "Awaiting for confirm",
    value: "waiting",
  }
];

export const CLASS_STATUS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Move",
    value: "move",
  },
  {
    label: "Cancel",
    value: "cancel",
  },
  {
    label: "Awaiting for confirm",
    value: "waiting",
  },
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Using",
    value: "using",
  },
];
export const CLASS_REPEAT_OPTIONS = [
  { label: "No", value: 0 },
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 7 },
];

export const WORKING_SESSION_REPEAT_OPTIONS = [
  { label: "No", value: 0 },
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 7 },
];
export const COLOR_OPTIONS = [
  {
    label: "Graphite",
    id: "8",
    background: "#e1e1e1",
    foreground: "#1d1d1d",
  },
  { label: "Tomato", id: "11", background: "#dc2127", foreground: "#1d1d1d" },
  { label: "Basil", id: "10", background: "#51b749", foreground: "#1d1d1d" },
  { label: "Peacock", id: "9", background: "#5484ed", foreground: "#1d1d1d" },
  { label: "Tangerine", id: "6", background: "#ffb878", foreground: "#1d1d1d" },
  { label: "Banana", id: "5", background: "#fbd75b", foreground: "#1d1d1d" },
  { label: "Grape", id: "3", background: "#dbadff", foreground: "#1d1d1d" },
  { label: "Sage", id: "7", background: "#42d692", foreground: "#1d1d1d" },
  { label: "Amethyst", id: "17", background: "#9a9cff", foreground: "#1d1d1d" },
];

export const RECURRENCE_ACTION_VALUE = {
  thisOne: 1,
  oneAndFollowing: 2,
  all: 3,
};

export const UPDATE_CALENDAR_NO_ACTION_VALUE = {
  updateOthers: 1,
  noUpdateOthers: 2,
};

export const CLASS_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const BOOKING_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const POS_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const WORKING_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const WORKING_SESSION_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const SPENDING_SESSION_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const ADDON_SESSION_DATE_RANGES = [
  { label: "Today", value: 0 },
  { label: "1 week", value: 3 },
  { label: "Half month", value: 7 },
  { label: "1 month", value: 14 },
  { label: "2 months", value: 30 },
  { label: "All time", value: -1 },
];

export const ADD_ON_CATEGORIES = [
  { name: "Uncategory", value: "uncategory" },
  { name: "Drink", value: "drink" },
  { name: "Parking", value: "parking" },
];

export const VAT_KIND = [
  // { label: "No", value: "no" },
  { label: "Room Fee", value: "room" },
  { label: "Room + Addon Fee", value: "total" },
  { label: "Manual", value: "manual" },
];

export const VAT_STATUS = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Processed",
    value: "processed",
  },
];

export const BOOKING_STATUS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Complete",
    value: "complete",
  },
  // {
  //   label: "Awaiting for confirm",
  //   value: "waiting",
  // },
];

export const POS_STATUS = [
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const SPENDING_SESSION_STATUS = [
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const ADDON_SESSION_STATUS = [
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const WORKING_STATUS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Complete",
    value: "complete",
  },
];

export const WORKING_SESSION_STATUS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Working",
    value: "working",
  },
];

export const WORKING_SESSION_ALLOWANCES = [
  { name: "Gas", value: "gas" },
  { name: "Eating", value: "eating" },
  { name: "Birthday", value: "birthday" },
  { name: "Holiday", value: "holiday" },
  { name: "Tet", value: "tet" },
  { name: "YearEnd", value: "yearEnd" },
  { name: "Bonus", value: "bonus" },
  { name: "Others", value: "others" },
];

// export const WORKING_SUMMARY_TYPE = [
//   {
//     name: "(Now)",
//     value: "now",
//   },
//   {
//     name: "(End this Session)",
//     value: "this",
//   },
//   {
//     name: "(All)",
//     value: "all",
//   },
// ];

export const WORKING_SUMMARY_TYPE = [
  {
    name: "(Now)",
    value: "now",
  },
  // {
  //   name: "(End this Session)",
  //   value: "this",
  // },
  {
    name: "(All)",
    value: "all",
  },
];

export const WORKING_SUMMARY_TYPE_NOW_ALL = [
  {
    name: "Now",
    value: "now",
  },
  {
    name: "All",
    value: "all",
  },
];

// export const BOOKING_SUMMARY_TYPE = [
//   {
//     name: "(Now)",
//     value: "now",
//   },
//   {
//     name: "(End this Class)",
//     value: "this",
//   },
//   {
//     name: "(All)",
//     value: "all",
//   },
// ];

export const BOOKING_SUMMARY_TYPE = [
  // {
  //   name: "(Now)",
  //   value: "now",
  // },
  {
    name: "(End this Class)",
    value: "this",
  },
  {
    name: "(All)",
    value: "all",
  },
];

export const BOOKING_SUMMARY_TYPE_NOW_ALL = [
  {
    name: "Now",
    value: "now",
  },
  {
    name: "All",
    value: "all",
  },
];
