import React from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createWorkingRoom, updateWorkingRoom } from "../../../../../api/api";
import { AuthContext } from "../../../../../context/AuthProvider";
import { COLOR_OPTIONS } from "../../../../../constants";
import Header from "../../../../Header/Header";

export default function EditWorkingRoom({ itemId, datas, setDatas }) {
  console.log({ itemId });
  console.log({ datas });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [calendarId, setCalendarId] = React.useState("");
  const [colorCalendar, setColorCalendar] = React.useState(null);
  const [colorCalendarComplete, setColorCalendarComplete] =
    React.useState(null);
  const [color, setColor] = React.useState(null);
  const [colorComplete, setColorComplete] = React.useState(null);
  const [returnParent, setReturnParent] = React.useState(false);
  const item = React.useMemo(() => {
    if (itemId) {
      let item = null;
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setName(item.name);
        setCalendarId(item.calendarId);
        setColorCalendar(item.colorCalendar);
        setColorCalendarComplete(item.colorCalendarComplete);
        setColor(item.color);
        setColorComplete(item.colorComplete);
      }
      console.log({ item });
      return item;
    }
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        setDatas(
          datas.map((item) => {
            if (item._id === itemId) {
              return res.data;
            } else {
              return item;
            }
          })
        );
      } else {
        //created
        setDatas([...datas, res.data]);
      }
    },
    [datas, setDatas]
  );
  const updateItemSuccess = React.useCallback(
    (res) => {
      //   console.log({ res });
      if (res && res.status === 200) {
        alert(itemId ? "Update Successfully!" : "Create Successfully!");
        setDataList(itemId, res);
      }
    },
    [setDataList, itemId]
  );
  const handleSubmit = React.useCallback(
    (event) => {
      const newItem = {
        name,
        calendarId,
        colorCalendar,
        colorCalendarComplete,
        color,
        colorComplete,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        updateWorkingRoom(token, newItem)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        console.log({ newItem });
        createWorkingRoom(token, newItem)
          .then((res) => {
            updateItemSuccess(res);
          })
          .catch((err) => {
            console.log({ err });
          });
      }
      setReturnParent(true);
    },
    [
      calendarId,
      color,
      colorCalendar,
      colorCalendarComplete,
      colorComplete,
      itemId,
      name,
      token,
      updateItemSuccess,
      userInfo?._id,
    ]
  );

  return (
    <>
      <Header
        title={(itemId ? "Edit" : "New") + " Working Room"}
        triggerParentClick={returnParent}
      />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            label="Name"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Calendar ID"
            fullWidth
            variant="outlined"
            value={calendarId}
            onChange={(e) => {
              setCalendarId(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorCalendar?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorCalendar(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Calendar Color"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorCalendarComplete?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorCalendarComplete(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Calendar Color Complete"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === color?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColor(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Color" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            className="color-options"
            options={COLOR_OPTIONS}
            defaultValue={
              COLOR_OPTIONS.filter(function (value, index, arr) {
                return value.id === colorComplete?.id;
              })[0]
            }
            getOptionLabel={(option) => (option.label ? option.label : "")}
            onChange={(event, newValue) => {
              // if (newValue) {
              setColorComplete(newValue ? newValue : null);
              // }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Color Complete"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item>
          <Typography component="div">
            {`Last updated by: ${data?.lastUpdatedBy?.username}`}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            className="btn-submit"
            size="medium"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
