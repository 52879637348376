import BookIcon from "@mui/icons-material/Book";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExtensionIcon from "@mui/icons-material/Extension";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PaidIcon from "@mui/icons-material/Paid";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

export const DASHBOARD_MENU = [
  {
    text: "POS",
    icon: <PointOfSaleIcon />,
    type: "pos",
  },
  {
    text: "Booking",
    icon: <BookIcon />,
    type: "booking",
  },
  {
    text: "Class",
    icon: <BookmarksIcon />,
    type: "class",
  },
  {
    text: "Customer",
    icon: <GroupIcon />,
    type: "customer",
  },
  {
    text: "Working",
    icon: <WorkHistoryIcon />,
    type: "workingMenu",
  },
  {
    text: "User",
    icon: <PersonIcon />,
    type: "user",
  },
  {
    text: "Room",
    icon: <MeetingRoomIcon />,
    type: "room",
  },
  {
    text: "VAT",
    icon: <CurrencyExchangeIcon />,
    type: "vat",
  },
  {
    text: "Addon",
    icon: <ExtensionIcon />,
    type: "addonMenu",
  },
  {
    text: "Spending",
    icon: <PaidIcon />,
    type: "spendingMenu",
  },
  {
    text: "Statistical",
    icon: <AssessmentIcon />,
    type: "statisticalMenu",
  },
  {
    text: "Settings",
    icon: <SettingsIcon />,
    type: "settings",
  },
];

export const DASHBOARD_MENU_STAFF = [
  {
    text: "POS",
    icon: <PointOfSaleIcon />,
    type: "pos",
  },
  {
    text: "Class",
    icon: <BookmarksIcon />,
    type: "class",
  },
  {
    text: "Working",
    icon: <WorkHistoryIcon />,
    type: "workingMenu",
  },
  {
    text: "User",
    icon: <PersonIcon />,
    type: "user",
  },
  {
    text: "Addon",
    icon: <ExtensionIcon />,
    type: "addonMenu",
  },
  {
    text: "Spending",
    icon: <PaidIcon />,
    type: "spendingMenu",
  },
];