import React from "react";
import "./Login.css";
import PropTypes from "prop-types";
import { login, loginUser } from "../../api/api";
import { AuthContext } from "../../context/AuthProvider.js";
import { useNavigate } from "react-router";
import { Button, TextField, Typography } from "@mui/material";
import {
  LOCAL_STORAGE_KEY_TOKEN,
  LOCAL_STORAGE_KEY_USER_INFO,
} from "../../constants";
import { handleGapiSignIn, handleGapiSignOut } from "../../services/calendar";

export function logout(navigate, history) {
  handleGapiSignOut();
  localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY_USER_INFO);
  if (navigate) {
    navigate("/login");
    return;
  }
  if (history) {
    history.replace("/login");
    return;
  }
}

export default function Login() {
  console.log("login");
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const { setToken, setUserInfo } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const handleSubmit = React.useCallback(
    async (e) => {
      //   e.preventDefault();
      try {
        const res = await login({ username, password });
        console.log({ res });
        if (res && res.status === 200) {
          setToken({ token: res.data.token });
          setUserInfo(res.data);
          navigate("/");
          handleGapiSignIn();
        }
      } catch (err) {
        alert(err.response.data);
        console.log({ err });
      }
      //   console.log({ token });
      //   setToken(token);
    },
    [username, password, setToken, navigate, setUserInfo]
  );
  return (
    <div className="login-wrapper">
      <Typography variant="h4" className="app-name">
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="outlined-basic"
          label="Username"
          variant="outlined"
          fullWidth
          className="txt-username-login"
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Password"
          variant="outlined"
          fullWidth
          className="txt-password-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          fullWidth
          size="medium"
          color="primary"
          className="btn-submit"
          onClick={(event) => handleSubmit(event)}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

// Login.propTypes = {
//   setToken: PropTypes.func.isRequired,
//};
