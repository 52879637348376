import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Popover,
  MenuItem,
  MenuList,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  createClass,
  getBookingAll,
  getBookingByProperties,
  getClassById,
  getOperationPercent,
  getRoomAll,
  searchClass,
  searchVat,
  updateBookingDiscountPercent,
  updateClass,
  updatePos,
  getPosByProperties,
} from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import Autocomplete from "@mui/material/Autocomplete";
// import { NumberFormatVNCurrency } from "../../../../helpers/NumberFormatCustom";
import moment from "moment";
import {
  CLASS_REPEAT_OPTIONS,
  CLASS_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
  POS_STATUS,
  RECURRENCE_ACTION_VALUE,
} from "../../../../constants";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import "moment/locale/vi";
import {
  convertTimeToHourMin,
  numberWithCommas,
} from "../../../../helpers/stringDate";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  getCalendarEvent,
  insertCalendarEvent,
  makeCalendarEvent,
  moveCalendarEvent,
  updateCalendarEvent,
} from "../../../../services/calendar";
import { v4 } from "uuid";
import { ObjectID } from "bson";
import { arrayToString } from "../../../../helpers/stringArray";
import { Chip, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import "./EditClass.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { sleep } from "../../../../helpers/general";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import SessionInfoHeader from "../../../../helpers/SessionInfoHeader/SessionInfoHeader";
import ConfirmUpdateDialog from "../../../../helpers/ConfirmUpdateDialog";
import RecurrenceDialog from "../../../../helpers/RecurrenceDialog";
import MoreActionButton from "../../../Header/MoreActionButton";
import NumberField from "../../../../helpers/NumberField";
import PosDialog from "../Pos/Dialog/PosDialog";
// const StyledChip = withStyles({
//   root: {
//     backgroundColor: 'brown',// here you can do anything actually
//     textColor: "white"
//   },
//   label: {
//     textTransform: 'capitalize',
//   },
// })(Chip);

const makeCalendarEventSummary = (customerGender, customerName) => {
  switch (customerGender) {
    case "male": {
      return `A.${customerName}`;
    }
    case "female": {
      return `C.${customerName}`;
    }
    default: {
      return customerName;
    }
  }
};

const makeCalendarEventDes = (
  _id,
  calNo,
  calStatus,
  calPhone,
  calPax,
  calParkingName,
  calDeposit,
  calNote
) => {
  // let link;
  // if (itemId) {
  //   link = window.location.href;
  // } else {
  // const pathArray = window.location.pathname.split("/");
  // pathArray.pop();
  // const path = arrayToString(pathArray, "/");
  const link =
    window.location.protocol + "//" + window.location.host + "/class/" + _id;
  // }
  const summary =
    "Class No: " +
    calNo +
    "\n" +
    "Status: " +
    calStatus +
    "\n" +
    "Phone: " +
    // customer?.phone +
    calPhone +
    "\n" +
    "Pax: " +
    calPax +
    "\n" +
    "Parking free: " +
    calParkingName +
    "\n" +
    "Deposit: " +
    // numberWithCommas(booking?.deposit, " đ") +
    numberWithCommas(calDeposit, " đ") +
    "\n" +
    "Note: " +
    calNote +
    "\n" +
    "Link: " +
    link;
  return summary;
};

const makeCalendarEventColorId = (status, room) => {
  switch (status) {
    case "active": {
      // console.log({ color: room?.colorCalendar.id });
      return room?.colorCalendar?.id ? room?.colorCalendar?.id : undefined;
    }
    case "pending": {
      return room?.colorCalendarPending?.id
        ? room?.colorCalendarPending?.id
        : undefined;
    }
    case "cancel": {
      return room?.colorCalendarCancel?.id
        ? room?.colorCalendarCancel?.id
        : undefined;
    }
    default: {
      return undefined;
    }
  }
};

export async function updateBookingDiscount(token, booking, mountedRef) {
  if (booking?.discountType === "rule") {
    const res = await updateBookingDiscountPercent(token, booking)
      .then((res) => {
        if (!mountedRef.current) return null;
        if (res && res.status === 200) {
          console.log({ booking: res.data });
          return { status: 200, response: res.data };
        }
      })
      .catch((err) => {
        if (!mountedRef.current) return null;
        console.log({ err });
        return {
          status: err.response.status,
          msg: "Update Booking Discount Percent failed!",
        };
      });
    return res;
  }
}

export async function updateItemClass(token, item, updateDB = true, userInfo) {
  //update calendar description
  let calendarCode;
  let serverCode = 200;
  if (updateDB) {
    const newItem = {
      _id: item._id,
      pax: item.pax,
      parkingName: item.parkingName,
      // deposit: item.deposit,
      note: item.note,
      lastUpdatedBy: userInfo?._id,
    };
    console.log({ newItem });
    serverCode = await updateClass(token, newItem)
      .then((res) => {
        if (res && res.status === 200) {
          return res.status;
        }
      })
      .catch((err) => {
        console.log({ err });
        return err.response.status;
      });
  }
  if (serverCode === 200) {
    const event = makeCalendarEvent(
      makeCalendarEventSummary(
        item?.customerId?.gender,
        item?.customerId?.name
      ),
      "",
      makeCalendarEventDes(
        item._id,
        item.no,
        item.status,
        item.customerId?.phone,
        item.pax,
        item.parkingName,
        item.bookingId?.deposit,
        item.note
      ),
      item.startTime,
      item.endTime,
      "",
      item.attendees,
      makeCalendarEventColorId(item.status, item.roomId)
    );
    const eventRes = await updateCalendarEvent(
      event,
      item.calendarId,
      item.roomId.calendarId,
      () => {},
      "update"
    );
    calendarCode = eventRes.status;
  }
  return { calendarCode, serverCode };
}

export const updateOtherClassNoCalendar = async (
  token,
  bookingId,
  isNew,
  recurrenceList,
  bookingClassList,
  setProgress = () => {},
  startProgress,
  endProgress,
  msg = "Update class(s) No. on calendar?"
) => {
  if (bookingClassList.length === 0) {
    bookingClassList = await searchClass(token, {
      search: bookingId,
    }).then((res) => res.data);
  }
  console.log({ bookingClassList });
  let tmpClassList = [];
  let tmpClassOtherList = [];
  if (!isNew) {
    let tmpIdRecurrenceList = [];
    for (let k = 0; k < recurrenceList.length; k++) {
      tmpIdRecurrenceList.push(recurrenceList[k]._id);
    }
    tmpClassOtherList = bookingClassList.filter((value, index, array) => {
      return !tmpIdRecurrenceList.includes(value._id);
    });
    tmpClassList = [...recurrenceList, ...tmpClassOtherList];
  } else {
    tmpClassList = [...bookingClassList, ...recurrenceList];
    tmpClassOtherList = [...bookingClassList];
  }
  tmpClassList.sort(function (a, b) {
    return new Date(a.startTime) - new Date(b.startTime);
  });
  console.log({ tmpClassList });
  console.log({ tmpClassOtherList });
  let updatedCount = 0;
  let errorCount = 0;
  const result = window.confirm(msg);
  if (result) {
    for (let i = 0; i < tmpClassOtherList.length; i++) {
      // await sleep(1, 100);
      setProgress(
        (i / tmpClassOtherList.length) * (endProgress - startProgress) +
          startProgress
      );
      const item = tmpClassOtherList[i];
      let itemClassNo;
      for (let k = 0; k < tmpClassList.length; k++) {
        if (tmpClassList[k]._id === item._id) {
          itemClassNo = k + 1 + "/" + tmpClassList.length;
          break;
        }
      }
      const event = makeCalendarEvent(
        undefined,
        undefined,
        makeCalendarEventDes(
          item._id,
          itemClassNo,
          item.status,
          item.customerId?.phone,
          item.pax,
          item.parkingName,
          item.bookingId?.deposit,
          item.note
        ),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      console.log({ otherEvent: event });
      const eventRes = await updateCalendarEvent(
        event,
        item.calendarId,
        item.roomId.calendarId
      );
      if (eventRes.status === 200) {
        updatedCount += 1;
      } else {
        errorCount += 1;
      }
    }
  }
  return { update: result, classList: tmpClassList, updatedCount, errorCount };
};

export default function EditClass() {
  // {
  // itemId,
  // datas,
  // setDatas,
  // customers,
  // bookings,
  // rooms,
  // }
  // console.log({ itemId });
  // console.log({ datas });
  moment.locale("vi");
  // moment.utcOffset(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const itemId = id;
  // console.log({ itemId });
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [bookingClasses, setBookingClasses] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const [bookings, setBookings] = React.useState([]);
  const [customer, setCustomer] = React.useState(null);
  const [booking, setBooking] = React.useState(null);
  // const [bookingId, setBookingId] = React.useState("");
  const [room, setRoom] = React.useState(null);
  // const [roomId, setRoomId] = React.useState("");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [startTimeBook, setStartTimeBook] = React.useState(null);
  const [endTimeBook, setEndTimeBook] = React.useState(null);
  const [time, setTime] = React.useState(0);
  const [pricePerHour, setPricePerHour] = React.useState(0);
  const [pax, setPax] = React.useState(0);
  const [parkingName, setParkingName] = React.useState("");
  const [note, setNote] = React.useState("");
  const [status, setStatus] = React.useState(CLASS_STATUS_OPTIONS[0].value);
  const [cancelFee, setCancelFee] = React.useState(0);
  const [moveFee, setMoveFee] = React.useState(0);
  const [pendingTime, setPendingTime] = React.useState(null);
  const [payed, setPayed] = React.useState(0);
  const [paymentType, setPaymentType] = React.useState(
    PAYMENT_TYPE_OPTIONS[0].value
  );
  const [invoice, setInvoice] = React.useState("");
  const [attendees, setAttendees] = React.useState([]);
  const [recurrence, setRecurrence] = React.useState({
    repeat: CLASS_REPEAT_OPTIONS[0],
    count: 1,
  });
  const [classNo, setClassNo] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRecurrenceDialog, setOpenRecurrenceDialog] = React.useState(false);
  const [openBookingClassesDialog, setOpenBookingClassesDialog] =
    React.useState(false);
  const [openPosDialog, setOpenPosDialog] = React.useState(false);
  const [operationPercent, setOperationPercent] = React.useState(null);
  // const [vatId, setVatId] = React.useState(null);
  const [fetching, setFetching] = React.useState({
    setting: false,
    rooms: false,
    class: false,
    booking: false,
    bookingClass: false,
    pos: false,
  });
  const [progress, setProgress] = React.useState(0);
  const [returnParent, setReturnParent] = React.useState(false);
  const [pendingPosList, setPendingPosList] = React.useState([]);
  const mountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // console.log({ room });
  React.useEffect(() => {
    async function doTask() {
      console.log("Getting Setting...");
      setProgress(0);
      setFetching((prevState) => ({ ...prevState, setting: true }));
      await getOperationPercent(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            setOperationPercent(res.data);
            console.log(res.data);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          // setFetching((prevState) => ({ ...prevState, setting: false }));
        });
      setFetching((prevState) => ({ ...prevState, setting: false }));
      console.log("Getting rooms...");
      setProgress(30);
      setFetching((prevState) => ({ ...prevState, rooms: true }));
      await getRoomAll(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            setRooms(res.data);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          // setFetching((prevState) => ({ ...prevState, rooms: false }));
        });
      setFetching((prevState) => ({ ...prevState, rooms: false }));
      let bookingProps = { status: "active" };
      if (itemId) {
        console.log("Getting class...");
        setProgress(60);
        setFetching((prevState) => ({ ...prevState, class: true }));
        await getClassById(token, itemId)
          .then(async (res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const item = res.data;
              console.log({ item });
              item.bookingId["customerId"] = item.customerId;
              setData(item);
              bookingProps = {
                customerId: item.customerId?._id,
              };
              setCustomer(item.customerId);
              setBooking(item.bookingId);
              // setBookings([item.bookingId]);
              setRoom(item.roomId);
              setStartTime(item.startTime);
              setEndTime(item.endTime);
              setStartTimeBook(item.startTimeBook);
              setEndTimeBook(item.endTimeBook);
              setTime(
                convertTimeToHourMin(
                  moment(item.endTime) - moment(item.startTime)
                )
              );
              setPricePerHour(item.pricePerHour);
              setPax(item.pax);
              setParkingName(item.parkingName);
              setNote(item.note);
              setStatus(item.status);
              setCancelFee(item.cancelFee);
              setPendingTime(item.pendingTime);
              setPayed(item.payed);
              setPaymentType(item.paymentType);
              setInvoice(item.invoice);
              setAttendees(item.attendees);
              // setVatId(item.vatId);
              await searchClass(token, { search: item.bookingId._id })
                .then((res) => {
                  if (res && res.status === 200) {
                    const datas = res.data;
                    setBookingClasses(datas);
                    datas.sort(function (a, b) {
                      return new Date(a.startTime) - new Date(b.startTime);
                    });
                    for (let i = 0; i < datas.length; i++) {
                      if (datas[i]._id === item._id) {
                        setClassNo(`${i + 1} / ${datas.length}`);
                        break;
                      }
                    }
                    if (item.recurrence) {
                      const recurrenceList = datas.filter(function (
                        value,
                        index,
                        arr
                      ) {
                        return value.recurrence?.id === item.recurrence.id;
                      });
                      const recurrenceFromNowList = recurrenceList.filter(
                        function (value, index, arr) {
                          return (
                            moment(value.startTime) >= moment(item.startTime)
                          );
                        }
                      );
                      setRecurrence({
                        ...item.recurrence,
                        count: recurrenceList.length,
                        list: recurrenceList,
                        countFromNow: recurrenceFromNowList.length,
                        listFromNow: recurrenceFromNowList,
                      });
                    }
                  }
                })
                .catch((err) => {
                  console.log({ err });
                  // setFetching((prevState) => ({ ...prevState, class: false }));
                });
            }
          })
          .catch((err) => {
            console.log({ err });
            if (!mountedRef.current) return null;
            // setFetching((prevState) => ({ ...prevState, class: false }));
          });
        // setProgress(60);
        setFetching((prevState) => ({ ...prevState, class: false }));
      }
      console.log("Getting bookings...");
      setProgress(60);
      setFetching((prevState) => ({ ...prevState, booking: true }));
      console.log({ bookingProps });
      await getBookingByProperties(token, bookingProps)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            console.log({ res });
            res.data.sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            setBookings(res.data);
            // setFetching((prevState) => ({ ...prevState, booking: false }));
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          // setFetching((prevState) => ({ ...prevState, booking: false }));
        });
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, booking: false }));
    }
    doTask();
  }, [itemId, token]);

  const bookingOptions = React.useMemo(() => {
    return bookings.map((item) => ({
      label:
        item.customerId.name +
        "-" +
        item.customerId.phone +
        "-" +
        moment(item.createdAt).format("DD/MM/yyyy") +
        (item.bookingName ? "-" + item.bookingName : ""),
      id: item._id,
      data: item,
    }));
  }, [bookings]);
  // console.log({ bookingOptions });
  const roomOptions = React.useMemo(() => {
    return rooms.map((item) => ({
      label: item.name + " (Capacity: " + item.capacity + ")",
      id: item._id,
      data: item,
    }));
  }, [rooms]);
  // console.log({ roomOptions });
  // const data = React.useMemo(() => {
  //   // console.log("useEffect");
  //   let item = null;
  //   if (itemId) {
  //     for (let i = 0; i < datas.length; i++) {
  //       if (datas[i]._id === itemId) {
  //         item = datas[i];
  //         // setData(item);
  //         // console.log({ item });
  //         break;
  //       }
  //     }
  //     // const item = getItem(itemId);
  //     if (item) {
  //       // console.log({ item });
  //       // for (let i = 0; i < customerOptions.length; i++) {
  //       //   // console.log("customerOption", customerOptions[i].id);
  //       //   // console.log("customerId", item.customerId);
  //       //   if (customerOptions[i].id === item.customerId._id) {
  //       //     setCustomer(customerOptions[i]);
  //       //     // console.log("customerOption", customerOptions[i]);
  //       //     break;
  //       //   }
  //       // }
  //       // for (let i = 0; i < bookingOptions.length; i++) {
  //       //   // console.log("customerOption", customerOptions[i].id);
  //       //   // console.log("customerId", item.customerId);
  //       //   if (bookingOptions[i].id === item.bookingId._id) {
  //       //     setBooking(bookingOptions[i]);
  //       //     console.log("init Booking", customerOptions[i]);
  //       //     break;
  //       //   }
  //       // }
  //       // for (let i = 0; i < roomOptions.length; i++) {
  //       //   // console.log("customerOption", customerOptions[i].id);
  //       //   // console.log("customerId", item.customerId);
  //       //   if (roomOptions[i].id === item.roomId._id) {
  //       //     setRoom(roomOptions[i]);
  //       //     // console.log("customerOption", customerOptions[i]);
  //       //     break;
  //       //   }
  //       // }
  //       // setCustomer(item.customerId);
  //       // console.log({booking});
  //       setBooking(item.bookingId);
  //       setRoom(item.roomId);
  //       setStartTime(item.startTime);
  //       setEndTime(item.endTime);
  //       setTime(
  //         convertTimeToHourMin(moment(item.endTime) - moment(item.startTime))
  //       );
  //       setPricePerHour(item.pricePerHour);
  //       setPax(item.pax);
  //       setParkingName(item.parkingName);
  //       setNote(item.note);
  //       setStatus(item.status);
  //       setCancelFee(item.cancelFee);
  //       setPayed(item.payed);
  //       setInvoice(item.invoice);
  //       setAttendees(item.attendees);
  //       if (item.recurrence) {
  //         const recurrenceList = datas.filter(function (value, index, arr) {
  //           return value.recurrence?.id === item.recurrence.id;
  //         });
  //         const recurrenceFromNowList = recurrenceList.filter(function (
  //           value,
  //           index,
  //           arr
  //         ) {
  //           return moment(value.startTime) >= moment(item.startTime);
  //         });
  //         setRecurrence({
  //           ...item.recurrence,
  //           count: recurrenceList.length,
  //           list: recurrenceList,
  //           countFromNow: recurrenceFromNowList.length,
  //           listFromNow: recurrenceFromNowList,
  //         });
  //       }
  //       // setCalendarId(item.calendarId);
  //     }
  //   }
  //   // console.log({ item });
  //   return item;
  // }, [datas, itemId]);

  // const classSummaryCalendar = React.useCallback(
  //   (
  //     _id,
  //     calNo,
  //     calStatus,
  //     calPhone,
  //     calPax,
  //     calParkingName,
  //     calDeposit,
  //     calNote
  //   ) => {
  //     // let link;
  //     // if (itemId) {
  //     //   link = window.location.href;
  //     // } else {
  //     const pathArray = window.location.pathname.split("/");
  //     pathArray.pop();
  //     const path = arrayToString(pathArray, "/");
  //     const link =
  //       window.location.protocol +
  //       "//" +
  //       window.location.host +
  //       path +
  //       "/" +
  //       _id;
  //     // }
  //     const summary =
  //       "Class No: " +
  //       calNo +
  //       "\n" +
  //       "Status: " +
  //       calStatus +
  //       "\n" +
  //       "Phone: " +
  //       // customer?.phone +
  //       calPhone +
  //       "\n" +
  //       "Pax: " +
  //       calPax +
  //       "\n" +
  //       "Parking free: " +
  //       calParkingName +
  //       "\n" +
  //       "Deposit: " +
  //       // numberWithCommas(booking?.deposit, " đ") +
  //       numberWithCommas(calDeposit, " đ") +
  //       "\n" +
  //       "Note: " +
  //       calNote +
  //       "\n" +
  //       "Link: " +
  //       link;
  //     return summary;
  //   },
  //   []
  // );

  // const updateItemSuccess = React.useCallback(
  //   (res) => {
  //     //   console.log({ res });
  //     if (res && res.status === 200) {
  //       console.log("updateItemSuccess");
  //       alert(itemId ? "Update Successfully!" : "Create Successfully!");
  //       if (itemId) {
  //         //update
  //         datas = datas.map((item) => {
  //           if (item._id === res.data._id) {
  //             return res.data;
  //           } else {
  //             return item;
  //           }
  //         });
  //         setDatas(datas);
  //       } else {
  //         //created
  //         datas.push(res.data);
  //         setDatas(datas);
  //         console.log({ newData: res.data, newDatas: datas });
  //       }
  //       // setDataList(itemId, res);
  //     }
  //   },
  //   [datas, itemId, setDatas]
  //);
  // const recurrenceObject = React.useCallback((repeatValue, count, id) => {
  //   let result = null;
  //   if (recurrence) {
  //     if (recurrence.repeat?.value > 0 && recurrence.count > 1) {
  //       result = { repeat: recurrence.repeat };
  //       if (recurrence.id) {
  //         result["id"] = recurrence.id;
  //       } else {
  //         result["id"] = v4();
  //       }
  //     }
  //   }
  //   return result;
  // }, [recurrence]);
  const makeRecurrenceObject = React.useCallback((repeat, count, id) => {
    let obj = null;
    if (repeat?.value > 0 && count > 1) {
      obj = { repeat };
      if (id) {
        obj["id"] = id;
      } else {
        obj["id"] = v4();
      }
    }
    return obj;
  }, []);

  const getTimeBook = React.useCallback(
    (time, newTime, timeBook) => {
      if (userInfo?.role === "staff") {
        if (
          moment(newTime).isSame(timeBook ? moment(timeBook) : moment(time))
        ) {
          return null;
        } else {
          return timeBook ? timeBook : time;
        }
      } else {
        return null;
      }
    },
    [userInfo?.role]
  );

  const updateDB = React.useCallback(
    async (
      _id,
      calendarId,
      calendarLink,
      start,
      end,
      recurrence,
      callBack = function () {}
    ) => {
      let moveFees = data?.moveFees;
      let newStatus = data?.status;
      if (status === "move") {
        moveFees = [
          ...data?.moveFees,
          {
            oldStartTime: data?.startTime,
            oldEndTime: data?.endTime,
            fee: moveFee,
          },
        ];
        console.log({ moveFees });
      } else {
        newStatus = status;
      }
      const newItem = {
        _id,
        bookingId: booking?._id,
        customerId: customer?._id,
        roomId: room?._id,
        startTime: start,
        endTime: end,
        startTimeBook: getTimeBook(data?.startTime, start, startTimeBook),
        endTimeBook: getTimeBook(data?.endTime, end, endTimeBook),
        pricePerHour,
        pax,
        parkingName,
        note,
        status: newStatus,
        cancelFee,
        moveFees,
        pendingTime,
        payed,
        paymentType,
        invoice,
        attendees,
        calendarId,
        calendarLink,
        recurrence,
        // vatId: vatId?._id,
        lastUpdatedBy: userInfo?._id,
      };
      if (itemId) {
        // newItem["_id"] = _id;
        console.log({ newItem });
        const resStatus = await updateClass(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              callBack();
              return res.status;
              // alert("Update Successfully!");
            }
          })
          .catch((err) => {
            console.log({ err });
            return err.response.status;
          });
        return resStatus;
      } else {
        // console.log({ newItem });
        const resStatus = await createClass(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              callBack();
              return res.status;
              // alert("Create Successfully!");
            }
          })
          .catch((err) => {
            console.log({ err });
            return err.response.status;
          });
        return resStatus;
      }
    },
    [
      data?.moveFees,
      data?.status,
      data?.startTime,
      data?.endTime,
      status,
      booking?._id,
      customer?._id,
      room?._id,
      getTimeBook,
      startTimeBook,
      endTimeBook,
      pricePerHour,
      pax,
      parkingName,
      note,
      cancelFee,
      pendingTime,
      payed,
      paymentType,
      invoice,
      attendees,
      userInfo?._id,
      itemId,
      moveFee,
      token,
    ]
  );

  const supportMakeRecurrenceList = React.useCallback(
    (count, recurrenceList, list, recurrenceId) => {
      recurrenceList.forEach((item) => {
        list.push({
          _id: item._id ? item._id : new ObjectID(),
          oldData: item,
          startTime: moment(item.startTime)
            .set({
              hour: moment(startTime).get("hour"),
              minute: moment(startTime).get("minute"),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          endTime: moment(item.endTime)
            .set({
              hour: moment(endTime).get("hour"),
              minute: moment(endTime).get("minute"),
              second: 0,
              millisecond: 0,
            })
            .toISOString(),
          recurrence: makeRecurrenceObject(
            recurrence?.repeat,
            count,
            recurrenceId
          ),
        });
      });
    },
    [startTime, endTime, makeRecurrenceObject, recurrence?.repeat]
  );
  const makeRecurrenceList = React.useCallback(
    (recurrenceAction) => {
      let list = []; //this class
      if (itemId) {
        switch (recurrenceAction) {
          case RECURRENCE_ACTION_VALUE.thisOne: {
            //this class only
            list.push({
              _id: data?._id ? data?._id : new ObjectID(),
              oldData: data,
              startTime: moment(startTime)
                .set({ second: 0, millisecond: 0 })
                .toISOString(),
              endTime: moment(endTime)
                .set({ second: 0, millisecond: 0 })
                .toISOString(),
              recurrence: makeRecurrenceObject(
                CLASS_REPEAT_OPTIONS[0],
                1,
                null
              ),
            });
            // setRecurrence((prevState) => ({
            //   ...prevState,
            //   id: null,
            //   count: 1,
            // }));
            break;
          }
          case RECURRENCE_ACTION_VALUE.oneAndFollowing: {
            //this and following
            supportMakeRecurrenceList(
              recurrence.countFromNow,
              recurrence.listFromNow,
              list,
              v4()
            );

            // setRecurrence((prevState) => ({
            //   ...prevState,
            //   id: null,
            //   count: recurrence.countFromNow,
            // }));
            break;
          }
          case RECURRENCE_ACTION_VALUE.all: {
            supportMakeRecurrenceList(
              recurrence.count,
              recurrence.list,
              list,
              recurrence.id
            );
            break;
          }
          default: {
          }
        }
      } else {
        if (recurrence?.repeat?.value > 0 && recurrence?.count > 1) {
          const recurrenceObj = makeRecurrenceObject(
            recurrence.repeat,
            recurrence.count,
            v4()
          );
          for (let i = 0; i < recurrence.count; i++) {
            const step = i * recurrence.repeat?.value * 24 * 60 * 60 * 1000;
            list.push({
              _id: new ObjectID(),
              oldData: null,
              startTime: moment(
                moment(startTime).set({ second: 0, millisecond: 0 }).valueOf() +
                  step
              ).toISOString(),
              endTime: moment(
                moment(endTime).set({ second: 0, millisecond: 0 }).valueOf() +
                  step
              ).toISOString(),
              recurrence: recurrenceObj,
            });
          }
        } else {
          list.push({
            _id: new ObjectID(),
            oldData: null,
            startTime: moment(startTime)
              .set({ second: 0, millisecond: 0 })
              .toISOString(),
            endTime: moment(endTime)
              .set({ second: 0, millisecond: 0 })
              .toISOString(),
            recurrence: makeRecurrenceObject(CLASS_REPEAT_OPTIONS[0], 1, null),
          });
        }
      }
      return list;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, endTime, itemId, recurrence, startTime, supportMakeRecurrenceList]
  );

  const submitAdvance = React.useCallback(
    async (recurrenceAction) => {
      let successCount = 0;
      let errorCount = 0;
      let alertContent = "";
      setProgress(0);
      setFetching((prevState) => ({ ...prevState, class: true }));
      // try {
      const recurrenceList = makeRecurrenceList(recurrenceAction);
      console.log({ recurrenceList });
      // const result = window.confirm("Update other class(s) on calendar?");
      //********* Update class No. of other classes in the booking **************/
      // let eventBack = null;
      let errorOthersCount = 0;
      // let successOthersCount = 0;
      let bookingClassList = [];
      if (userInfo?.role === "admin") {
        const { update, classList, updatedCount, errorCount } =
          await updateOtherClassNoCalendar(
            token,
            booking?._id,
            booking?._id === data?.bookingId?._id
              ? itemId
                ? false
                : true
              : true,
            recurrenceList,
            booking?._id === data?.bookingId?._id ? bookingClasses : [],
            setProgress,
            10,
            50
          );
        console.log({ classList });
        // console.log({update, updatedCount});
        if (update) {
          alertContent += `Calendar: [${updatedCount} OK] - [${errorCount} ERROR]`;
        }
        errorOthersCount = errorCount;
        // successOthersCount = updatedCount;
        bookingClassList = classList;
      }
      //************* end update Class No. of other classes in the booking */
      if (errorOthersCount === 0) {
        for (let i = 0; i < recurrenceList.length; i++) {
          const item = recurrenceList[i];
          const _id = item?._id;
          // let itemClassNo = "Access Link below";
          let event;
          if (userInfo?.role === "admin") {
            let itemClassNo;
            for (let k = 0; k < bookingClassList.length; k++) {
              if (bookingClassList[k]._id === _id) {
                itemClassNo = k + 1 + "/" + bookingClassList.length;
                break;
              }
            }
            event = makeCalendarEvent(
              makeCalendarEventSummary(customer?.gender, customer?.name),
              "",
              makeCalendarEventDes(
                _id,
                itemClassNo,
                status,
                customer?.phone,
                pax,
                parkingName,
                booking?.deposit,
                note
              ),
              item.startTime,
              item.endTime,
              "",
              attendees,
              makeCalendarEventColorId(status, room)
            );
            console.log({ event });
          }
          // await sleep(1, 100);
          if (itemId) {
            let eventRes = { status: 200 };
            if (userInfo?.role === "admin") {
              eventRes = await updateCalendarEvent(
                event,
                item?.oldData?.calendarId,
                item?.oldData?.roomId?.calendarId
              );
              console.log({ eventRes });
              if (eventRes.status === 200) {
                if (item?.oldData?.roomId?._id !== room?._id) {
                  eventRes = await moveCalendarEvent(
                    room?.calendarId,
                    item?.oldData?.calendarId,
                    item?.oldData?.roomId?.calendarId
                  );
                  console.log({ eventRes });
                  if (eventRes.status === 200) {
                    eventRes = await getCalendarEvent(
                      item?.oldData?.calendarId,
                      room?.calendarId
                    );
                    console.log({ eventRes });
                  }
                }
              }
            }
            if (eventRes?.status === 200) {
              //works OK with calendar then update DB
              const classStatus = await updateDB(
                _id,
                item?.oldData?.calendarId,
                eventRes?.result?.htmlLink,
                item.startTime,
                item.endTime,
                item.recurrence
              );
              if (classStatus === 200) {
                successCount += 1;
              } else {
                errorCount += 1;
              }
            } else {
              errorCount += 1;
            }
            // } else {
            //   //if role not an admin, successCount += 1
            //   successCount += 1;
            // }
          } else {
            const eventRes = await insertCalendarEvent(event, room?.calendarId);
            console.log({ eventRes });
            if (eventRes.status === 200) {
              const classStatus = await updateDB(
                _id,
                eventRes.result.id,
                eventRes.result.htmlLink,
                item.startTime,
                item.endTime,
                item.recurrence
              );
              if (classStatus === 200) {
                successCount += 1;
              } else {
                errorCount += 1;
              }
            } else {
              errorCount += 1;
            }
          }
        }
        if (userInfo?.role === "admin") {
          //update booking discount percent if discount type is rule
          const res = await updateBookingDiscount(token, booking, mountedRef);
          if (res?.msg) {
            alertContent += res.msg + " ";
          }
          //update booking discount percent for old booking (changing book case)
          if (data && booking?._id !== data?.bookingId?._id) {
            const res = await updateBookingDiscount(
              token,
              data?.bookingId,
              mountedRef
            );
            if (res?.msg) {
              alertContent += res.msg + "[OLD] ";
            }
            const { update, classList, updatedCount, errorCount } =
              await updateOtherClassNoCalendar(
                token,
                data?.bookingId?._id,
                true,
                [],
                [],
                setProgress,
                60,
                90,
                "Update class(s) No. on calendar? [OLD]"
              );
            // console.log({ classList });
            if (update) {
              alertContent += `\nCalendar[OLD]: [${updatedCount} OK] - [${errorCount} ERROR]`;
            }
          }
        }
        alertContent += `\n${
          itemId ? "Update" : "Create"
        } Database: [${successCount} OK] - [${errorCount} ERROR]!`;
        // if (errorCount > 0) {
        //   alertContent += `${
        //     itemId ? "Update" : "Create"
        //   } failed ${errorCount} class(es)!`;
        // } else {
        //   alertContent += `${
        //     itemId ? "Update" : "Create"
        //   } success ${successCount} class(es) and updated ${successOthersCount} other class(es)!`;
        // }
      }
      // } catch (err) {
      //   console.log({ err });
      // }
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, class: false }));
      if (alertContent) {
        alert(alertContent);
      }
      setReturnParent(true);
    },
    [
      makeRecurrenceList,
      userInfo?.role,
      token,
      booking,
      data,
      itemId,
      bookingClasses,
      customer?.gender,
      customer?.name,
      customer?.phone,
      status,
      pax,
      parkingName,
      note,
      attendees,
      room,
      updateDB,
    ]
  );

  const handleSubmit = React.useCallback(
    (ev) => {
      if (itemId) {
        if (
          recurrence.repeat.value > 0 &&
          recurrence.count > 1 &&
          userInfo?.role === "admin"
        ) {
          setOpenDialog(true);
        } else {
          submitAdvance(RECURRENCE_ACTION_VALUE.thisOne);
        }
      } else {
        submitAdvance();
      }
    },
    [
      itemId,
      recurrence.count,
      recurrence.repeat.value,
      submitAdvance,
      userInfo?.role,
    ]
  );

  // const filter = bookingOptions.filter(function (value, index, arr) {
  //   return value.id === booking?._id;
  // });
  // console.log({ filter });
  const bookingChoosen = React.useMemo(() => {
    // let opt1 = null;
    // for (let i = 0; i < bookingOptions.length; i++) {
    //   if (bookingOptions[i].id === booking?._id) {
    //     opt1 = bookingOptions[i];
    //   }
    // }
    // console.log({ opt1 });
    const filter = bookingOptions.filter(function (value, index, arr) {
      return value.id === booking?._id;
    })[0];
    // console.log({ filter });
    // console.log("=", opt1 === filter);
    return filter === undefined ? null : filter;
    // return null;
  }, [booking?._id, bookingOptions]);
  const roomChoosen = React.useMemo(() => {
    for (let i = 0; i < roomOptions.length; i++) {
      if (roomOptions[i].id === room?._id) {
        return roomOptions[i];
      }
    }
    return null;
  }, [room?._id, roomOptions]);
  // console.log({bookingIndex});
  const handleDialogItemClick = React.useCallback(
    (value) => {
      setOpenDialog(false);
      // setDialogSelectedValue(value);
      submitAdvance(value);
    },
    [submitAdvance]
  );

  const handleRecurrenceDialogItemClick = React.useCallback(
    (value) => {
      setOpenRecurrenceDialog(false);
      // setDialogSelectedValue(value);
      // submitAdvance(value);
      navigate("/class/" + value._id);
    },
    [navigate]
  );

  const handlePosDialogItemClick = React.useCallback(
    async (value) => {
      setOpenPosDialog(false);
      if (value) {
        setProgress(10);
        setFetching((prevState) => ({ ...prevState, pos: true }));
        let alertContent = "";
        let newItem = {
          _id: value._id,
          classId: data?._id,
          lastUpdatedBy: userInfo?._id,
        };
        console.log({ newItem });
        const newPos = await updatePos(token, newItem)
          .then((res) => {
            // console.log({ res });
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent += `\nUpdate POS [OK]`;
              return res.data;
            } else {
              alertContent += `\nUpdate POS [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            // console.log({ err });
            if (!mountedRef.current) return null;
            // console.log({ err });
            alertContent += `\nUpdate POS [Error: ${err}]`;
            return null;
          });
        if (newPos) {
          newItem = {
            _id: data?._id,
            posId: newPos._id,
            lastUpdatedBy: userInfo._id,
          };
          console.log({ newItem });
          await updateClass(token, newItem)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent += `\nUpdate Class [OK]`;
              } else {
                alertContent += `\nUpdate Class [Error: ${res.status}]`;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent += `\nUpdate Class [Error: ${err}]`;
            });
        }
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, pos: false }));
        if (alertContent) {
          alert(alertContent);
        }
      }
      navigate("pos");
    },
    [data?._id, navigate, token, userInfo._id]
  );

  const getPendingPosList = React.useCallback(
    async (e) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, pos: true }));
      let alertContent = "";
      const pendingPosList = await getPosByProperties(token, {
        status: POS_STATUS[1].value,
        classId: null,
      })
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            console.log({ res });
            return res.data;
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, pos: false }));
      // console.log({alertContent});
      if (alertContent) {
        alert(alertContent);
      }
      pendingPosList.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      return pendingPosList;
    },
    [token]
  );

  // const handleDialogCalendarItemClick = React.useCallback(
  //   (value) => {
  //     setOpenDialog(false);
  //     // setDialogSelectedValue(value);
  //     submitAdvance(value);
  //   },
  //   [submitAdvance]
  // );

  const currentMovePercent = React.useMemo(() => {
    let compareDate = new Date();
    if (pendingTime && data?.status === "pending") {
      compareDate = pendingTime;
    }
    if (moment(compareDate).isSameOrAfter(moment(startTime), "day")) {
      // console.log("current date is the day same or after classDate");
      return operationPercent?.move;
    } else {
      // console.log("current date is the before classDate");
      return 0;
    }
  }, [data?.status, operationPercent?.move, pendingTime, startTime]);

  const actions = ["Add-on", "VAT", "Invoice", "Booking Classes"];
  const handleClickAction = React.useCallback(
    async (event, action) => {
      switch (action) {
        case "Add-on": {
          if (data?.posId) {
            navigate(pathname + "/pos");
          } else {
            setPendingPosList(await getPendingPosList());
            setOpenPosDialog(true);
          }
          break;
        }
        case "Invoice":
          // console.log({ pathname });
          navigate("invoice");
          break;
        case "VAT":
          // console.log({ pathname });
          navigate("vat");
          break;
        case "Booking Classes":
          // console.log({ pathname });
          // navigate("vat");
          setOpenBookingClassesDialog(true);
          break;
        default:
          return;
      }
    },
    [data?.posId, getPendingPosList, navigate, pathname]
  );
  const rightButton = (
    <MoreActionButton actions={actions} handleClickAction={handleClickAction} />
  );

  // const rightButton = {
  //   icon: <PreviewIcon fontSize="inherit" />,
  //   callback: function (event) {
  //     navigate(pathname + "/invoice");
  //   },
  // };

  // const handleOpenVAT = React.useCallback(
  //   (e) => {
  //     navigate("vat");
  //   },
  //   [navigate]
  // );
  return (
    <>
      {fetching.setting ||
      fetching.rooms ||
      fetching.class ||
      fetching.booking ||
      fetching.pos ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      ) : (
        <>
          <Header
            title={(id ? "Edit" : "New") + " Class"}
            rightElement={itemId ? rightButton : null}
            triggerParentClick={returnParent}
          />
          <Grid container direction="column" spacing={3}>
            {itemId ? (
              <>
                <SessionInfoHeader
                  title="Class"
                  no={classNo}
                  calendarLink={data?.calendarLink}
                  parentPath="booking"
                  parentData={
                    bookingOptions?.filter((value, index, arr) => {
                      return value?.id === data?.bookingId?._id;
                    })[0]
                  }
                />
                <Grid item>
                  <TextField
                    label="Class Id"
                    fullWidth
                    disabled
                    variant="outlined"
                    value={itemId}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {userInfo?.role === "admin" ? (
              <Grid item>
                <Autocomplete
                  className="booking-options"
                  options={bookingOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={bookingChoosen}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      newValue = bookingOptions[0];
                    }
                    // setBookingId(newValue.id);
                    setBooking(newValue.data);
                    setStatus(
                      newValue.data.status === "waiting"
                        ? CLASS_STATUS_OPTIONS[4].value
                        : CLASS_STATUS_OPTIONS[0].value
                    );
                    // setCustomerId(newValue.data.customerId._id);
                    setCustomer(newValue.data.customerId);
                    setPax(newValue.data.pax);
                    setParkingName(newValue.data.parkingName);
                    setNote(newValue.data.note ? newValue.data.note : "");
                    setAttendees(
                      newValue.data.customerId.email
                        ? [newValue.data.customerId.email]
                        : []
                    );
                  }}
                  id="booking-options"
                  sx={{ width: "100%" }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Booking" variant="outlined" />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item>
              <Autocomplete
                disabled={userInfo?.role !== "admin"}
                className="room-options"
                options={roomOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={roomChoosen}
                getOptionLabel={(option) => (option.label ? option.label : "")}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    newValue = roomOptions[0];
                  }
                  setRoom(newValue?.data);
                  // setRoomId(  newValue.id);
                  if (!itemId) {
                    setPricePerHour(newValue.data.pricePerHour);
                  }
                  // setPax(newValue.capacity);
                  // console.log({newValue});
                }}
                id="room-options"
                sx={{ width: "100%" }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Room" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  // disabled={userInfo?.role !== "admin"}
                  renderInput={(props) => (
                    <TextField fullWidth {...props} error={startTimeBook} />
                  )}
                  label={
                    `Start Time` +
                    (startTimeBook
                      ? ` (Origin: ${moment(startTimeBook).format(
                          "dddd, DD/MM/yyyy HH:mm"
                        )})`
                      : ``)
                  }
                  value={startTime}
                  inputFormat="dddd, DD/MM/yyyy HH:mm"
                  onChange={(newValue) => {
                    // console.log({newValue});
                    if (newValue) {
                      newValue.set({ second: 0, millisecond: 0 });
                      setStartTime(newValue.toISOString());
                      setTime(convertTimeToHourMin(moment(endTime) - newValue));
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  error={endTimeBook}
                  // disabled={userInfo?.role !== "admin"}
                  renderInput={(props) => (
                    <TextField fullWidth {...props} error={endTimeBook} />
                  )}
                  label={
                    `End Time` +
                    (endTimeBook
                      ? ` (Origin: ${moment(endTimeBook).format(
                          "dddd, DD/MM/yyyy HH:mm"
                        )})`
                      : ``)
                  }
                  value={endTime}
                  inputFormat="dddd, DD/MM/yyyy HH:mm"
                  onChange={(newValue) => {
                    if (newValue) {
                      newValue.set({ second: 0, millisecond: 0 });
                      setEndTime(newValue.toISOString());
                      setTime(
                        convertTimeToHourMin(newValue - moment(startTime))
                      );
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <TextField
                label="Time in use"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="time"
                variant="outlined"
                value={time}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={userInfo?.role !== "admin"}
                label="Price Per Hour"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="pricePerHour"
                variant="outlined"
                value={pricePerHour}
                onChange={(e) => {
                  setPricePerHour(e.target.value === "" ? 0 : e.target.value);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={userInfo?.role !== "admin"}
                label="Attendees"
                fullWidth
                name="time"
                variant="outlined"
                value={attendees}
                onChange={(e) => {
                  setAttendees(e.target.value.split(","));
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={userInfo?.role !== "admin"}
                label="Pax"
                type="number"
                fullWidth
                variant="outlined"
                value={pax}
                onChange={(e) => {
                  console.log({ e: e.target.value });
                  if (e.target.value === "" || e.target.value < 0) {
                    setPax(0);
                  } else {
                    setPax(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={userInfo?.role !== "admin"}
                label="Parking Name"
                fullWidth
                variant="outlined"
                value={parkingName}
                onChange={(e) => {
                  setParkingName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={userInfo?.role !== "admin"}
                label="Note"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </Grid>
            {/* <Grid item>
              <TextField
                label="Invoice Number"
                fullWidth
                name="invoice"
                variant="outlined"
                value={invoice}
                onChange={(e) => {
                  setInvoice(e.target.value);
                }}
              />
            </Grid> */}
            <Grid item>
              <Autocomplete
                disabled={itemId ? true : false}
                className="class-repeat-options"
                options={CLASS_REPEAT_OPTIONS}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                // defaultValue={CLASS_REPEAT_OPTIONS[0]}
                value={
                  recurrence?.repeat
                    ? recurrence.repeat
                    : CLASS_REPEAT_OPTIONS[0]
                }
                onChange={(event, newValue) => {
                  setRecurrence((prevState) =>
                    prevState
                      ? {
                          ...prevState,
                          repeat: newValue,
                        }
                      : {
                          // id: v4(),
                          repeat: newValue,
                        }
                  );
                }}
                id="class-repeat-options"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Repeat" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item xs>
                  <NumberField
                    label="Count"
                    fullWidth
                    disabled={
                      itemId || recurrence?.repeat === CLASS_REPEAT_OPTIONS[0]
                        ? true
                        : false
                    }
                    name="count"
                    myValue={recurrence?.count}
                    myOnChange={(e, newValue) => {
                      // const newCount = !e.target.value? "0": e.target.value;
                      // console.log(e.target.value);
                      setRecurrence((prevState) => ({
                        ...prevState,
                        count: parseInt(newValue),
                      }));
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={recurrence?.count === 1}
                    variant="contained"
                    className="btn-submit"
                    size="medium"
                    color="primary"
                    onClick={(event) => {
                      setOpenRecurrenceDialog(true);
                    }}
                  >
                    Show
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth disabled={userInfo?.role !== "admin"}>
                  <InputLabel id="select-status-label">Status</InputLabel>
                  <Select
                    labelId="select-status-label"
                    id="select-status"
                    value={status}
                    fullWidth
                    label="Status"
                    onChange={(event) => {
                      const state = event.target.value;
                      setStatus(state);
                      const fee =
                        ((moment(endTime) - moment(startTime)) /
                          (1000 * 3600)) *
                        pricePerHour;
                      console.log({ fee });
                      if (state === "cancel") {
                        setCancelFee(fee * operationPercent?.cancel);
                        setMoveFee(0);
                      } else if (state === "move") {
                        setMoveFee(fee * currentMovePercent);
                        setCancelFee(0);
                        // const classDate = moment(startTime).format("DD/MM/yyyy");
                        // // console.log({ classDate });
                        // // console.log(moment());
                        // if (moment().isBefore(moment(classDate))) {
                        //   console.log("current date is the before classDate");
                        //   setMoveFee(0);
                        // } else {
                        //   console.log(
                        //     "current date is the day same or after classDate"
                        //   );
                        //   setMoveFee(fee * operationPercent?.move);
                        // }
                      } else if (state === "pending") {
                        setPendingTime(new Date());
                      } else {
                        setMoveFee(0);
                        setCancelFee(0);
                        setPendingTime(null);
                      }
                    }}
                  >
                    {CLASS_STATUS_OPTIONS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {pendingTime ? (
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    disabled={true}
                    renderInput={(props) => <TextField fullWidth {...props} />}
                    label="Pending Time"
                    value={pendingTime}
                    inputFormat="dddd, DD/MM/yyyy HH:mm"
                    onChange={(newValue) => {
                      if (newValue) {
                        newValue.set({ second: 0, millisecond: 0 });
                        setPendingTime(newValue.toISOString());
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item className={itemId ? "" : "no-display"}>
              <NumberField
                label={"Move Fee (" + currentMovePercent * 100 + "%)"}
                fullWidth
                disabled={status !== "move"}
                name="moveFee"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={moveFee}
                myOnChange={(e, newValue) => {
                  setMoveFee(newValue);
                }}
              />
              {/* <TextField
                label={"Move Fee (" + currentMovePercent * 100 + "%)"}
                InputLabelProps={{ shrink: true }}
                disabled={status !== "move"}
                fullWidth
                name="moveFee"
                variant="outlined"
                value={moveFee}
                onChange={(e) => {
                  setMoveFee(e.target.value === "" ? 0 : e.target.value);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }} */}
              {/* /> */}
            </Grid>
            <Grid item className={itemId ? "" : "no-display"}>
              <NumberField
                label={"Cancel Fee (" + operationPercent?.cancel * 100 + "%)"}
                fullWidth
                disabled={status !== "cancel"}
                name="cancelFee"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={cancelFee}
                myOnChange={(e, newValue) => {
                  setCancelFee(newValue);
                }}
              />
              {/* <TextField
                label={"Cancel Fee (" + operationPercent?.cancel * 100 + "%)"}
                InputLabelProps={{ shrink: true }}
                disabled={status !== "cancel"}
                fullWidth
                name="cancelFee"
                variant="outlined"
                value={cancelFee}
                onChange={(e) => {
                  setCancelFee(e.target.value === "" ? 0 : e.target.value);
                }}
                // InputProps={{
                //   inputComponent: NumberFormatVNCurrency,
                // }}
              /> */}
            </Grid>
            <Grid item>
              <NumberField
                error
                label="Payed"
                fullWidth
                name="payed"
                suffix=" đ"
                isThousandSeparator={true}
                myValue={payed}
                myOnChange={(e, newValue) => {
                  setPayed(newValue);
                }}
              />
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth error>
                  <InputLabel id="select-class-payment-type-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    labelId="select-class-payment-type-label"
                    id="select-class-payment"
                    value={paymentType}
                    fullWidth
                    label="Payment Type"
                    onChange={(event) => {
                      setPaymentType(event.target.value);
                    }}
                  >
                    {PAYMENT_TYPE_OPTIONS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* <Autocomplete
              className="payment-type-options"
              options={PAYMENT_TYPE_OPTIONS}
              value={paymentType}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, newValue) => {
                setPaymentType(newValue);
                // console.log({newValue});
              }}
              id="payment-type-options"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Type"
                  variant="outlined"
                />
              )}
            /> */}
            </Grid>
            {/* <Grid item className={itemId ? "" : "no-display"}>
              <Grid container alignItems="center" direction="row" spacing={2}>
                <Grid item>
                  <Typography>VAT</Typography>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    fullWidth
                    className="btn-vat"
                    size="medium"
                    color="primary"
                    onClick={(event) => handleOpenVAT(event)}
                  >
                    {vatId?._id ? "Open" : "New"}
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <ConfirmUpdateDialog
            dialogOpen={openDialog}
            setDialogOpenState={(state) => {
              setOpenDialog(state);
            }}
            handleItemClick={handleDialogItemClick}
            countAll={recurrence.count}
            countFromNow={recurrence.countFromNow}
            title="Class"
          />
          <RecurrenceDialog
            dialogOpen={openRecurrenceDialog}
            setDialogOpenState={(state) => {
              setOpenRecurrenceDialog(state);
            }}
            handleItemClick={handleRecurrenceDialogItemClick}
            recurrenceList={recurrence?.list}
            title={`Recurrence List (${recurrence?.count})`}
          />
          <RecurrenceDialog
            dialogOpen={openBookingClassesDialog}
            setDialogOpenState={(state) => {
              setOpenBookingClassesDialog(state);
            }}
            handleItemClick={handleRecurrenceDialogItemClick}
            recurrenceList={bookingClasses}
            title={`Booking Classes (${bookingClasses?.length})`}
          />
          <PosDialog
            dialogOpen={openPosDialog}
            setDialogOpenState={(state) => {
              setOpenPosDialog(state);
            }}
            handleItemClick={handlePosDialogItemClick}
            dataList={pendingPosList}
            title={`Choose Pending Pos (${pendingPosList?.length})`}
          />
        </>
      )}
    </>
  );
}
