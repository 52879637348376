import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { Grid, MenuItem, Select, LinearProgress } from "@mui/material";
import moment from "moment";
import { debounce } from "lodash";
import { Box } from "@mui/system";
import {
  deleteAddonSession,
  getAddonSessionAll,
  getAddonSessionByDateRange,
  searchAddonSession,
} from "../../../../../api/api";
import AddonSessionItem from "./AddonSessionItem";
import { ADDON_SESSION_DATE_RANGES } from "../../../../../constants";
import { AuthContext } from "../../../../../context/AuthProvider";
import { AppContext } from "../../../../../context/AppProvider";
import ListSearch from "../../../../Header/ListSearch";
import ListHeader from "../../../../Header/ListHeader";
import Header from "../../../../Header/Header";
import LinearProgressWithLabel from "../../../../../helpers/LinearProgressWithLabel";
import { updateInventory } from "../../Pos/EditPos";

export default function AddonSession() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  // console.log({ pathArray });
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log({ id });
  const [initDatas, setInitDatas] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const { token, userInfo } = React.useContext(AuthContext);
  const { searchAddonSessionMem, setSearchAddonSessionMem } =
    React.useContext(AppContext);
  const { dateRangeAddonSessionMem, setDateRangeAddonSessionMem } =
    React.useContext(AppContext);
  const [fetching, setFetching] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const updateDatas = React.useCallback(
    (newDatas, setInit = true) => {
      // console.log({ res });
      if (userInfo?.role !== "admin") {
        newDatas = newDatas.filter((value, index, array) => {
          return value.workingSessionId?.userId?._id === userInfo?._id;
        });
      }
      newDatas.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      console.log({ newDatas });
      if (setInit) {
        setInitDatas(newDatas);
      }
      setDatas(newDatas);
      // console.log({ newDatas });
    },
    [userInfo?._id, userInfo?.role]
  );

  const debounceFetcher = React.useMemo(() => {
    //   console.log("[debounceFetcher-search]", search);
    const loadResults = async (value) => {
      console.log("[debounceFetcher-loadOptions]", value);
      setProgress(50);
      setFetching(true);
      await searchAddonSession(token, { search: value })
        .then((res) => {
          if (res && res.status === 200) {
            updateDatas(res.data, false);
          }
        })
        .catch((err) => {
          console.log({ err });
          alert(err);
        });
      setProgress(100);
      setFetching(false);
    };
    return debounce(loadResults, 1000);
  }, [token, updateDatas]);

  //   console.log("re-render");
  const handleSearch = React.useCallback(
    (searchText) => {
      console.log({ searchText });
      debounceFetcher(searchText);
    },
    [debounceFetcher]
  );
  const deleteItem = React.useCallback(
    async (item) => {
      setProgress(10);
      setFetching(true);
      let alertContent = "";
      await deleteAddonSession(token, item)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            const newDatas = datas.filter(function (value, index, arr) {
              return value._id !== item._id;
            });
            setDatas(newDatas);
            alertContent += `\nDelete Addon Session [OK]`;
          } else {
            alertContent += `\nDelete Addon Session [Error: ${res.status}]`;
          }
        })
        .catch((err) => {
          console.log({ err });
          if (!mountedRef.current) return null;
          // return err.response.status;
          alertContent += `\nDelete Addon Session [Error: ${err}]`;
        });
      setProgress(50);
      alertContent +=
        "\n" +
        (await updateInventory(
          token,
          mountedRef,
          undefined,
          item?.addons.map((addon) => ({
            ...addon,
            addonId: addon.addonId._id,
          }))
        ));
      setProgress(100);
      setFetching(false);
      if (alertContent) {
        alert(alertContent);
      }
    },
    [token, datas]
  );

  React.useEffect(() => {
    async function abc() {
      if (searchAddonSessionMem) {
        handleSearch(searchAddonSessionMem);
      } else {
        let alertContent;
        console.log("Getting addon sessions...");
        setProgress(50);
        setFetching(true);
        if (dateRangeAddonSessionMem >= 0) {
          const startDate = moment()
            .subtract(dateRangeAddonSessionMem, "day")
            .startOf("day")
            .toString();
          const endDate = moment()
            .add(dateRangeAddonSessionMem, "day")
            .endOf("day")
            .toString();
          console.log({ startDate });
          console.log({ endDate });
          await getAddonSessionByDateRange(token, {
            startDate,
            endDate,
          })
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        } else {
          await getAddonSessionAll(token)
            .then((res) => {
              if (res && res.status === 200) {
                updateDatas(res.data);
              }
            })
            .catch((err) => {
              console.log({ err });
              alertContent = err;
            });
        }
        setProgress(100);
        setFetching(false);
        if (alertContent) {
          alert(alertContent);
        }
      }
    }
    abc();
  }, [
    dateRangeAddonSessionMem,
    handleSearch,
    searchAddonSessionMem,
    token,
    updateDatas,
  ]);
  let rows = [];
  for (var i = 0; i < datas.length; i++) {
    const item = datas[i];
    rows.push(
      <Grid item key={item._id}>
        <AddonSessionItem
          key={item._id}
          number={i + 1}
          item={item}
          deleteItem={deleteItem}
        />
      </Grid>
    );
  }
  const rightButton = {
    icon: <AddIcon fontSize="inherit" />,
    callback: function (event) {
      navigate(pathname + "/new");
    },
  };
  const selectBox = (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={dateRangeAddonSessionMem}
      // label="Date Range"
      fullWidth
      onChange={(event) => {
        setDateRangeAddonSessionMem(event.target.value);
      }}
    >
      {ADDON_SESSION_DATE_RANGES.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      <Header
        title="Addon Import"
        rightButton={userInfo?.role === "admin" ? rightButton : null}
      />
      <ListSearch
        handleChange={(searchText) => {
          if (searchText) {
            handleSearch(searchText);
          } else {
            setDatas(initDatas);
          }
          setSearchAddonSessionMem(searchText);
        }}
        selectBox={selectBox}
        placeholder="WorkingId | Session | User"
        value={searchAddonSessionMem}
      />
      <ListHeader
        titles={[
          { width: 1, align: "center", title: "No." },
          { width: 4, align: "left", title: "Time" },
          { width: 1, align: "left", title: "Session" },
          { width: 2, align: "right", title: "User" },
          { width: 2, align: "center", title: "Total" },
          { width: 2, align: "center", title: "Action" },
        ]}
      />
      <div className="list-item">
        {fetching ? (
          <div className="center-text">
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        ) : (
          <Grid container direction="column" spacing={0}>
            {rows?.length > 0 ? (
              rows
            ) : (
              <Grid item>
                <Box component="div" className="center-text">
                  No Addon Import found!
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
}
