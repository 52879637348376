import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  createPos,
  getAddonAll,
  getAddonAllGroupCate,
  getPosById,
  getPosLatest,
  searchPos,
  updateAddonInventory,
  updateClass,
  updatePos,
} from "../../../../api/api";
import { AuthContext } from "../../../../context/AuthProvider";
import Header from "../../../Header/Header";
import "./EditPos.css";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { numberWithCommas } from "../../../../helpers/stringDate";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import { PAYMENT_TYPE_OPTIONS, POS_STATUS } from "../../../../constants";
import { generateRandomIntegerInRange } from "../../../../helpers/general";
import MoreActionButton from "../../../Header/MoreActionButton";
import "./AddonItem.css";
class AddonPosModel {
  constructor(addonId, name, quantity, price, priceIn) {
    this.addonId = addonId;
    this.name = name;
    this.quantity = quantity;
    this.price = price;
    this.priceIn = priceIn;
  }
}

function AddonItem({ addon, handleAddonClick }) {
  return (
    <Button
      variant="contained"
      className={"btn-addon-item " + addon?.color?.label || ""}
      size="medium"
      color="primary"
      onClick={(event) => handleAddonClick(event, addon)}
    >
      {addon.name}
    </Button>
  );
}

function AddonPosItem({
  addonPos,
  handleReduceQuantity,
  handleRemoveAddonPos,
}) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        {addonPos.name + " (" + numberWithCommas(addonPos.price, " đ)")}
      </Grid>
      <Grid item className="addon-pos-quantity">
        x {addonPos.quantity}
      </Grid>
      <Grid item xs></Grid>
      <Grid item>
        <IconButton
          aria-label="reduce"
          size="medium"
          onClick={(event) => handleReduceQuantity(event, addonPos)}
        >
          <RemoveCircleOutlineIcon
            className={"addon-pos-list-icon"}
            fontSize="inherit"
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="remove"
          size="medium"
          onClick={(event) => handleRemoveAddonPos(event, addonPos)}
        >
          <DeleteIcon className={"addon-pos-list-icon"} fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export async function updateInventory(token, mountedRef, addonsOld, addonsNew) {
  let addons;
  let alertContent;
  console.log({ addonsOld, addonsNew });
  if ((!addonsOld || addonsOld.length === 0) && addonsNew) {
    //new pos
    addons = addonsNew.map((item) => {
      return { ...item, change: -item.quantity };
    });
  } else if (addonsOld && (!addonsNew || addonsNew.length === 0)) {
    //delete pos
    addons = addonsOld.map((item) => {
      return { ...item, change: item.quantity };
    });
  } else if (addonsOld && addonsNew) {
    //update pos
    addons = [];
    const addonsNewTmp = [...addonsNew];
    // console.log({ addonsNewTmp });
    for (let i = 0; i < addonsOld.length; i++) {
      // console.log({ addonsNewTmp });
      const oldItem = addonsOld[i];
      console.log({ oldItem });
      let match = false;
      for (let k = 0; k < addonsNewTmp.length; k++) {
        const newItem = addonsNewTmp[k];
        console.log({ newItem });
        if (
          oldItem?.addonId === newItem?.addonId &&
          oldItem?.price === newItem?.price &&
          oldItem?.priceIn === newItem?.priceIn
        ) {
          //add same item
          addons.push({
            ...newItem,
            change: oldItem.quantity - newItem.quantity,
          });
          console.log({ addons: [...addons] });
          match = true;
          addonsNewTmp.splice(k, 1);
          console.log({ addonsNewTmp: [...addonsNewTmp] });
          break;
        }
      }
      if (!match) {
        //add old item
        addons.push({ ...oldItem, change: oldItem.quantity });
      }
      if (i === addonsOld.length - 1) {
        addonsNewTmp.forEach((newItem) => {
          //add new item
          addons.push({ ...newItem, change: -newItem.quantity });
        });
      }
      // console.log({ addons });
    }
  }
  console.log({ addons });
  if (addons && addons.length > 0) {
    for (let i = 0; i < addons.length; i++) {
      const item = addons[i];
      if (item.change === 0) {
      } else {
        const msg = await updateAddonInventory(token, {
          _id: item.addonId,
          change: item.change,
          priceIn: item.priceIn,
        })
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              console.log({ res });
              // alertContent = `Update success!`;
              return null;
            } else {
              return item?.name;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            return item?.name;
          });
        if (msg) {
          if (!alertContent) {
            alertContent = `Update Inventory [Error]:`;
          }
          alertContent += `\n${msg}`;
        } else {
          alertContent = `Update Inventory [OK]`;
        }
      }
    }
  } else {
    alertContent = `Update Inventory [No Action]`;
  }
  return alertContent;
}

export default function EditPos() {
  const { id, classId } = useParams();
  const posId = id;
  console.log({ posId, classId });
  moment.locale("vi");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [addons, setAddons] = React.useState([]);
  const [addonsPos, setAddonsPos] = React.useState([]);
  const [addonsPosQuantity, setAddonsPosQuantity] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [status, setStatus] = React.useState(POS_STATUS[0].value);
  const [total, setTotal] = React.useState(0);
  const [paymentType, setPaymentType] = React.useState(
    PAYMENT_TYPE_OPTIONS[0].value
  );
  const [fetching, setFetching] = React.useState({
    addons: false,
    pos: false,
  });
  const [progress, setProgress] = React.useState(0);
  // console.log({ addonsPos });
  const [returnParent, setReturnParent] = React.useState(false);
  const mountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  React.useEffect(() => {
    async function abc() {
      setProgress(0);
      let alertContent;
      console.log("Getting addons...");
      setFetching((prevState) => ({ ...prevState, addons: true }));
      await getAddonAllGroupCate(token)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            const cateList = res.data;
            console.log({ cateList });
            cateList.sort(function (a, b) {
              if (a.category.name < b.category.name) {
                return -1;
              }
              if (a.category.name > b.category.name) {
                return 1;
              }
              return 0;
            });
            for (let i = 0; i < cateList.length; i++) {
              const addonList = cateList[i]?.addons;
              addonList.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            }
            setAddons(cateList);
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent = err;
        });
      setProgress(50);
      setFetching((prevState) => ({ ...prevState, addons: false }));
      if (posId) {
        console.log("Getting pos by id...");
        setFetching((prevState) => ({ ...prevState, pos: true }));
        await getPosById(token, posId)
          .then((res) => {
            if (res && res.status === 200) {
              if (!mountedRef.current) return null;
              const posData = res.data;
              console.log({ posData });
              setData(posData);
              setAddonsPos(posData.addons);
              setStatus(posData.status);
              setPaymentType(posData.paymentType);
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, pos: false }));
      } else if (classId) {
        console.log("Getting pos by class...");
        setFetching((prevState) => ({ ...prevState, pos: true }));
        await searchPos(token, { search: classId })
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              const posDatas = res.data;
              console.log({ posDatas });
              if (posDatas.length > 0) {
                setData(posDatas[0]);
                setAddonsPos(posDatas[0].addons);
                setStatus(posDatas[0].status);
                setPaymentType(posDatas[0].paymentType);
              }
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent = err;
          });
        setProgress(100);
        setFetching((prevState) => ({ ...prevState, pos: false }));
      }
      if (alertContent) {
        alert(alertContent);
      }
    }
    abc();
  }, [classId, posId, token]);

  React.useEffect(() => {
    let sum = 0;
    let quantity = 0;
    addonsPos.forEach((item) => {
      sum += item.quantity * item.price;
      quantity += item.quantity;
    });
    setTotal(sum);
    setAddonsPosQuantity(quantity);
  }, [addonsPos]);
  const handleAddonClick = React.useCallback(
    (event, addon) => {
      console.log({ addon });
      let addonPosIndex = -1;
      for (let i = 0; i < addonsPos.length; i++) {
        const item = addonsPos[i];
        if (
          item.addonId._id === addon._id &&
          item.price === addon.priceOut &&
          item.priceIn === addon.priceIn
        ) {
          //check if addon in pos is the same with previous one | if no it's a new one
          addonPosIndex = i;
          break;
        }
      }
      console.log({ addonPosIndex });
      if (addonPosIndex >= 0) {
        //update addon's quantity already had in class
        //   addonsClass[addonClassIndex].quantity += 1;
        setAddonsPos((prevState) =>
          prevState.map((item) => {
            if (
              item.addonId._id === addon._id &&
              item.price === addon.priceOut &&
              item.priceIn === addon.priceIn
            ) {
              return new AddonPosModel(
                item.addonId,
                item.name,
                item.quantity + 1,
                item.price,
                item.priceIn
              );
            } else {
              return item;
            }
          })
        );
      } else {
        //add new addon to class
        setAddonsPos((prevState) => [
          ...prevState,
          new AddonPosModel(
            addon,
            addon.name,
            1,
            addon.priceOut,
            addon.priceIn
          ),
        ]);
      }
    },
    [addonsPos]
  );

  const handleSubmit = React.useCallback(
    async (ev) => {
      setProgress(10);
      setFetching((prevState) => ({ ...prevState, pos: true }));
      let alertContent = "";
      let newPos = {
        // classId: ,
        // customerId: data?.customerId?._id,
        addons: addonsPos.map((item) => ({
          addonId: item.addonId._id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          priceIn: item.priceIn,
        })),
        total,
        status,
        paymentType,
        lastUpdatedBy: userInfo?._id,
      };
      // let newPos;
      if (data) {
        newPos["_id"] = data?._id;
        // newItem["classId"] = data?.classId?._id;
        console.log({ newPos });
        newPos = await updatePos(token, newPos)
          .then((res) => {
            // console.log({ res });
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              alertContent += `\nUpdate POS [OK]`;
              return res.data;
            } else {
              alertContent += `\nUpdate POS [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            // console.log({ err });
            if (!mountedRef.current) return null;
            // console.log({ err });
            alertContent += `\nUpdate POS [Error: ${err}]`;
            return null;
          });
        // console.log({ alertContent });
      } else {
        const posLatest = await getPosLatest(token)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              console.log({ posLatest: res.data });
              return res.data;
            } else {
              alertContent += `\nGet POS Latest [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            // setProgress(80);
            // setFetching((prevState) => ({ ...prevState, pos: false }));
            // alert(err);
            alertContent += `\nGet POS Latest [Error: ${err}]`;
            return null;
          });
        setProgress(50);
        // console.log({ posLatest });
        if (posLatest && posLatest.no) {
          newPos["no"] = `#${
            parseInt(posLatest.no.substring(1)) + 1
          }-${generateRandomIntegerInRange(10, 99)}`;
        } else {
          newPos["no"] = `#1-${generateRandomIntegerInRange(10, 99)}`;
        }
        newPos["classId"] = classId;
        console.log({ newPos });
        newPos = await createPos(token, newPos)
          .then((res) => {
            if (!mountedRef.current) return null;
            if (res && res.status === 200) {
              console.log({ newPos: res.data });
              alertContent += `\nCreate POS [OK]`;
              return res.data;
            } else {
              alertContent += `\nCreate POS [Error: ${res.status}]`;
              return null;
            }
          })
          .catch((err) => {
            if (!mountedRef.current) return null;
            console.log({ err });
            alertContent += `\nCreate POS [Error: ${err}]`;
            return null;
          });
        setProgress(80);
        if (classId && newPos) {
          let newClass = {
            _id: classId,
            posId: newPos._id,
            lastUpdatedBy: userInfo._id,
          };
          console.log({ newClass });
          newClass = await updateClass(token, newClass)
            .then((res) => {
              if (!mountedRef.current) return null;
              if (res && res.status === 200) {
                alertContent += `\nUpdate Class [OK]`;
                return res.data;
              } else {
                alertContent += `\nUpdate Class [Error: ${res.status}]`;
                return null;
              }
            })
            .catch((err) => {
              if (!mountedRef.current) return null;
              console.log({ err });
              alertContent += `\nUpdate Class [Error: ${err}]`;
              return null;
            });
        }
      }
      if (newPos) {
        alertContent +=
          "\n" +
          (await updateInventory(
            token,
            mountedRef,
            data?.addons.map((addon) => ({
              ...addon,
              addonId: addon.addonId._id,
            })),
            newPos?.addons
          ));
      }
      setProgress(100);
      setFetching((prevState) => ({ ...prevState, pos: false }));
      if (alertContent) {
        alert(alertContent);
      }
      setReturnParent(true);
    },
    [addonsPos, classId, data, paymentType, status, token, total, userInfo?._id]
  );

  const handleRemoveAddonPos = React.useCallback((event, addonPos) => {
    setAddonsPos((prevState) =>
      prevState.filter((value, index, array) => {
        return !(
          value.addonId._id === addonPos.addonId._id &&
          value.price === addonPos.price &&
          value.priceIn === addonPos.priceIn
        );
      })
    );
  }, []);

  const handleReduceQuantity = React.useCallback(
    (event, addonPos) => {
      let remove = false;
      for (let i = 0; i < addonsPos.length; i++) {
        const item = addonsPos[i];
        if (
          item.addonId._id === addonPos.addonId._id &&
          item.price === addonPos.price &&
          item.priceIn === addonPos.priceIn
        ) {
          if (item.quantity === 1) {
            remove = true;
          }
          break;
        }
      }
      if (remove) {
        handleRemoveAddonPos(event, addonPos);
      } else {
        setAddonsPos((prevState) =>
          prevState.map((item) => {
            if (
              item.addonId._id === addonPos.addonId._id &&
              item.price === addonPos.price &&
              item.priceIn === addonPos.priceIn
            ) {
              return new AddonPosModel(
                item.addonId,
                item.name,
                item.quantity - 1,
                item.price,
                item.priceIn
              );
            } else {
              return item;
            }
          })
        );
      }
    },
    [addonsPos, handleRemoveAddonPos]
  );

  const handleUnlinkFromClass = React.useCallback(async () => {
    setProgress(10);
    setFetching((prevState) => ({ ...prevState, pos: true }));
    let alertContent = "";
    let newItem = {
      _id: data?._id,
      classId: "undefined",
      lastUpdatedBy: userInfo?._id,
    };
    console.log({ newItem });
    const newPos = await updatePos(token, newItem)
      .then((res) => {
        // console.log({ res });
        if (!mountedRef.current) return null;
        if (res && res.status === 200) {
          alertContent += `\nUpdate POS [OK]`;
          return res.data;
        } else {
          alertContent += `\nUpdate POS [Error: ${res.status}]`;
          return null;
        }
      })
      .catch((err) => {
        // console.log({ err });
        if (!mountedRef.current) return null;
        // console.log({ err });
        alertContent += `\nUpdate POS [Error: ${err}]`;
        return null;
      });
    console.log({ newPos });
    if (newPos) {
      newItem = {
        _id: data?.classId?._id,
        posId: "undefined",
        lastUpdatedBy: userInfo._id,
      };
      console.log({ newItem });
      const newClass = await updateClass(token, newItem)
        .then((res) => {
          if (!mountedRef.current) return null;
          if (res && res.status === 200) {
            alertContent += `\nUpdate Class [OK]`;
            return res.data;
          } else {
            alertContent += `\nUpdate Class [Error: ${res.status}]`;
            return null;
          }
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          console.log({ err });
          alertContent += `\nUpdate Class [Error: ${err}]`;
        });
      console.log({ newClass });
    }
    setProgress(100);
    setFetching((prevState) => ({ ...prevState, pos: false }));
    if (alertContent) {
      alert(alertContent);
    }
    setReturnParent(true);
  }, [data?._id, data?.classId?._id, token, userInfo._id]);

  const actionsPos = ["Merge", "Split"];
  const actionsClass = ["Unlink from Class"];

  const handleClickAction = React.useCallback(
    async (event, action) => {
      switch (action) {
        case "Merge": {
          break;
        }
        case "Split":
          // console.log({ pathname });
          // navigate("invoice");
          break;
        case "Unlink from Class":
          await handleUnlinkFromClass(data);
          break;
        default:
          return;
      }
    },
    [data, handleUnlinkFromClass]
  );
  const rightButton = (
    <MoreActionButton
      actions={classId ? actionsClass : actionsPos}
      handleClickAction={handleClickAction}
    />
  );

  return (
    <>
      <Header
        title={data ? "Edit POS" : "New POS"}
        triggerParentClick={returnParent}
        rightElement={data ? rightButton : null}
      />
      {fetching.addons || fetching.pos ? (
        <div className="center-text">
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </div>
      ) : (
        <div className="content">
          <Grid container spacing={3} direction="column">
            <Grid
              item
              className={data?.classId?._id && posId ? "" : "no-display"}
            >
              <TextField
                label="Class Id"
                fullWidth
                disabled
                variant="outlined"
                value={data?.classId?._id}
              />
            </Grid>
            <Grid item className={data?._id && classId ? "" : "no-display"}>
              <TextField
                label="Pos Id"
                fullWidth
                disabled
                variant="outlined"
                value={data?._id}
              />
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-pos-status-label">Status</InputLabel>
                  <Select
                    labelId="select-pos-status-label"
                    id="select-pos-status"
                    value={status}
                    fullWidth
                    label="Status"
                    onChange={(event) => {
                      const state = event.target.value;
                      setStatus(state);
                    }}
                  >
                    {POS_STATUS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-pos-payment-type-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    labelId="select-pos-payment-type-label"
                    id="select-pos-payment"
                    value={paymentType}
                    fullWidth
                    label="Payment Type"
                    onChange={(event) => {
                      setPaymentType(event.target.value);
                    }}
                  >
                    {PAYMENT_TYPE_OPTIONS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item>
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "regular" }}
              >
                {"Total: "}
              </Box>
              <Box
                component="span"
                sx={{ display: "inline", fontWeight: "bold" }}
              >
                {numberWithCommas(total, " đ")}
              </Box>
            </Grid> */}
            <Grid item>
              <div className="addon-pos-list">
                <Grid container direction="column" spacing={1}>
                  {addonsPos.map((item) => (
                    <Grid
                      item
                      key={item.addonId._id + item.priceIn + item.price + ""}
                    >
                      <AddonPosItem
                        addonPos={item}
                        handleReduceQuantity={handleReduceQuantity}
                        handleRemoveAddonPos={handleRemoveAddonPos}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
            <Grid item className={!data ? "no-display" : ""}>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
            {addons.map((cateItem) => {
              return (
                <Grid
                  item
                  key={cateItem?.category?._id}
                  className="addon-list-wrapper"
                >
                  <Typography variant="h6" component="div">
                    {cateItem?.category?.name}
                  </Typography>
                  <div className="addon-list">
                    <Grid container direction="row" spacing={1}>
                      {cateItem?.addons?.map((addonItem) => {
                        return (
                          <Grid item key={addonItem._id}>
                            <AddonItem
                              addon={addonItem}
                              handleAddonClick={handleAddonClick}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div className="pos-total">
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "regular" }}
            >
              {"Total: "}
            </Box>
            <Box
              component="span"
              sx={{ display: "inline", fontWeight: "bold" }}
            >
              {numberWithCommas(total, " đ") + ` (${addonsPosQuantity} items)`}
            </Box>
          </div>
        </div>
      )}
    </>
  );
}
