import moment from "moment";

// Client ID and API key from the Developer Console
var CLIENT_ID =
  "934743650147-rpkcmh6jktn0diao69g103q0didpijcd.apps.googleusercontent.com";
var API_KEY = "AIzaSyBUP3V05N6VAM8ikXhq0J8CN_mwHl_xZCg";

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES = "https://www.googleapis.com/auth/calendar";
var gapi = window.gapi;
// var gapi = window.gapi;
/**
 *  On load, called to load the auth2 library and API client library.
 */
export function handleClientLoad() {
  // console.log("handleClientLoad");
  gapi.load("client:auth2", () => {
    // console.log("client loaded");
    initClient();
  });
}

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
function initClient() {
  gapi.client
    .init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    .then(
      function () {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

        // Handle the initial sign-in state.
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        // authorizeButton.onclick = handleAuthClick;
        // signoutButton.onclick = handleSignoutClick;
      },
      function (error) {
        console.log({ error });
        // appendPre(JSON.stringify(error, null, 2));
      }
    );
}

/**
 *  Called when the signed in status changes, to update the UI
 *  appropriately. After a sign-in, the API is called.
 */
function updateSigninStatus(isSignedIn) {
  console.log("GAPI-signedIn", isSignedIn);
  if (isSignedIn) {
    // authorizeButton.style.display = 'none';
    // signoutButton.style.display = 'block';
    // listUpcomingEvents();
  } else {
    // authorizeButton.style.display = 'block';
    // signoutButton.style.display = 'none';
  }
}

/**
 *  Sign in the user upon button click.
 */
export async function handleGapiSignIn(event) {
  if (!gapi.auth2.getAuthInstance().isSignedIn.get()) {
    await gapi.auth2.getAuthInstance().signIn();
  }
}

/**
 *  Sign out the user upon button click.
 */
export async function handleGapiSignOut(event) {
  if (gapi.auth2?.getAuthInstance()?.isSignedIn.get()) {
    await gapi.auth2.getAuthInstance().signOut();
  }
}

/**
 * Append a pre element to the body containing the given message
 * as its text node. Used to display the results of the API call.
 *
 * @param {string} message Text to be placed in pre element.
 */
function appendPre(message) {
  var pre = document.getElementById("content");
  var textContent = document.createTextNode(message + "\n");
  pre.appendChild(textContent);
}

/**
 * Print the summary and start datetime/date of the next ten events in
 * the authorized user's calendar. If no events are found an
 * appropriate message is printed.
 */
function listUpcomingEvents() {
  gapi.client.calendar.events
    .list({
      calendarId: "primary",
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      maxResults: 10,
      orderBy: "startTime",
    })
    .then(function (response) {
      var events = response.result.items;
      appendPre("Upcoming events:");

      if (events.length > 0) {
        for (let i = 0; i < events.length; i++) {
          var event = events[i];
          var when = event.start.dateTime;
          if (!when) {
            when = event.start.date;
          }
          appendPre(event.summary + " (" + when + ")");
        }
      } else {
        appendPre("No upcoming events found.");
      }
    });
}

export function makeCalendarEvent(
  summary,
  location,
  description,
  start,
  end,
  recurrence,
  attendeeEmails,
  colorId = undefined //"11" = red
) {
  const event = {
    reminders: {
      useDefault: true,
      //   overrides: [
      //     { method: "email", minutes: 24 * 60 },
      //     { method: "popup", minutes: 10 },
      //   ],
    },
  };
  if (summary) {
    event["summary"] = summary;
  }
  if (location) {
    event["location"] = location;
  }
  if (description) {
    event["description"] = description;
  }
  if (start) {
    event["start"] = {
      dateTime: moment(start).toISOString(),
      // timeZone: "Etc/GMT",
    };
  }
  if (end) {
    event["end"] = {
      dateTime: moment(end).toISOString(),
      // timeZone: "Etc/GMT",
    };
  }
  if (recurrence) {
    event["recurrence"] = recurrence;
  }
  if (attendeeEmails) {
    event["attendeeEmails"] = attendeeEmails.map((item) => ({ email: item }));
  }
  if (colorId) {
    event["colorId"] = colorId;
  }
  event["status"] = "confirmed";
  // const event = {
  //   summary,
  //   location,
  //   description,
  //   start: {
  //     dateTime: moment(start).toISOString(),
  //     // timeZone: "Etc/GMT",
  //   },
  //   end: {
  //     dateTime: moment(end).toISOString(),
  //     // timeZone: "Etc/GMT",
  //   },
  //   recurrence,
  //   attendees: attendeeEmails
  //     ? attendeeEmails.map((item) => ({ email: item }))
  //     : [],
  //   reminders: {
  //     useDefault: true,
  //     //   overrides: [
  //     //     { method: "email", minutes: 24 * 60 },
  //     //     { method: "popup", minutes: 10 },
  //     //   ],
  //   },
  //   colorId,
  // };
  return event;
}

export async function insertCalendarEvent(
  event,
  calendarId = "primary",
  callback = (evt) => {}
) {
  await handleGapiSignIn();
  const response = await gapi.client.calendar.events
    .insert({
      calendarId: calendarId,
      resource: event,
    })
    .then((event) => {
      console.log("Event created:", event);
      callback(event);
      return event;
    })
    .catch((err) => {
      console.log({ err });
      return err;
    });
  return response;

  // request.execute((event) => {
  //   console.log("Event created:", event);
  //   callback(event);
  // });
  // const calEvent = await request.execute();
  // console.log("Event created:", calEvent);
  // callback(calEvent);
}

export async function updateCalendarEvent(
  event,
  eventId,
  calendarId = "primary",
  callback = (evt) => {},
  method = "patch" //put-patch
) {
  await handleGapiSignIn();
  // console.log({ eventId });
  if (method === "patch") {
    const response = await gapi.client.calendar.events
      .patch({
        calendarId,
        eventId,
        resource: event,
      })
      .then((event) => {
        console.log("Event updated:", event);
        callback(event);
        return event;
      })
      .catch((err) => {
        console.log({ err });
        return err;
      });
    return response;
  } else {
    //put
    const response = await gapi.client.calendar.events
      .update({
        calendarId,
        eventId,
        resource: event,
      })
      .then((event) => {
        console.log("Event updated:", event);
        callback(event);
        return event;
      })
      .catch((err) => {
        console.log({ err });
        return err;
      });
    return response;
  }
  // request.execute((event) => {
  //   console.log("Event updated:", event);
  //   callback(event);
  // });
  // const calEvent = await request.execute();
  // console.log("Event updated:", calEvent);
  // callback(calEvent);
}

export async function moveCalendarEvent(
  newCalendarId,
  eventId,
  calendarId = "primary",
  callback = (evt) => {}
) {
  // console.log({ newCalendarId });
  // console.log({ eventId });
  // console.log({ calendarId });
  await handleGapiSignIn();
  // console.log({ eventId });
  const response = await gapi.client.calendar.events
    .move({
      calendarId,
      eventId,
      destination: newCalendarId,
    })
    .then((event) => {
      console.log("Event moved:", event);
      callback(event);
      return event;
    })
    .catch((err) => {
      console.log({ err });
      return err;
    });
  return response;

  // request.execute((event) => {
  //   console.log("Event moved:", event);
  //   callback(event);
  // });
  // const event = await request.execute();
  // console.log("Event moved:", event);
  // callback(event);
}

export async function deleteCalendarEvent(
  eventId,
  calendarId = "primary",
  callback = (evt) => {}
) {
  await handleGapiSignIn();
  console.log({ eventId });
  const response = await gapi.client.calendar.events
    .delete({
      calendarId,
      eventId,
    })
    .then((event) => {
      console.log("Event deleted:", event);
      callback(event);
      return event;
    })
    .catch((err) => {
      console.log({ err });
      return err;
    });
  return response;
  // request.execute((event) => {
  //   console.log("Event deleted:", event);
  //   callback(event);
  // });
  // try {
  //   const event = await request.execute();
  //   console.log("Event deleted:", event);
  //   callback(event);
  //   return event;
  // } catch (err) {
  //   console.log({ err });
  // }
}

export async function getCalendarEvent(
  eventId,
  calendarId = "primary",
  callback = (evt) => {}
) {
  await handleGapiSignIn();
  console.log({ eventId });
  const response = await gapi.client.calendar.events
    .get({
      calendarId,
      eventId,
    })
    .then((event) => {
      console.log("Event:", event);
      callback(event);
      return event;
    })
    .catch((err) => {
      console.log({ err });
      return err;
    });
  return response;
}
