import React from "react";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

export default function NumberField({
  myValue,
  myOnChange,
  prefix = "",
  suffix = "",
  isThousandSeparator = false,
  ...props
}) {
  if (prefix || suffix || isThousandSeparator) {
    return (
      <TextField
        variant="outlined"
        {...props}
        InputLabelProps={{ shrink: true }}
        value={myValue === "" ? 0 : myValue}
        onChange={(e) => {
          const newValue = parseFloat(!e.target.value ? "0" : e.target.value);
          // console.log({ e });
          // console.log({newValue});
          myOnChange(e, newValue);
        }}
        inputProps={{ suffix, prefix, isThousandSeparator }}
        InputProps={{
          inputComponent: MyNumberFormat,
        }}
      />
    );
  } else {
    return (
      <TextField
        {...props}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        value={myValue === "" ? 0 : myValue}
        onChange={(e) => {
          const newValue = parseFloat(!e.target.value ? "0" : e.target.value);
          // console.log(e.target.value);
          // console.log({newValue});
          myOnChange(e, newValue);
        }}
      />
    );
  }
}

// const MyNumberFormat = ({ myValue, myOnChange, prefix, suffix, ...props }) => {
//   return (
//     <NumberFormat
//       suffix={suffix}
//       prefix={prefix}
//       {...props}
//       thousandSeparator
//       isNumericString
//       value={myValue}
//       onValueChange={(e) => {
//         const newValue = !e?.target?.value ? 0 : e?.target?.value;
//         // myOnChange(e, parseInt(newValue));
//         console.log({ newValue });
//       }}
//     />
//   );
// };

export const MyNumberFormat = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, suffix, prefix, isThousandSeparator, ...others } = props;

  return (
    <NumberFormat
      suffix={suffix}
      prefix={prefix}
      {...others}
      getInputRef={ref}
      onValueChange={(values) => {
        // const newValue = !values.value ? 0 : values.floatValue;
        // myOnChange(e, parseInt(newValue));
        console.log({ values });
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={isThousandSeparator}
      isNumericString
    />
  );
});

MyNumberFormat.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
