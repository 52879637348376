import "./StatisticalMenu.css";
import React from "react";
import { Button, Grid } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../../../Header/Header";
import { STATISTICAL_MENU } from "./constants";
import { AuthContext } from "../../../../context/AuthProvider";
import StatisticalStaff from "./StatisticalStaff/StatisticalStaff";
import StatisticalRoom from "./StatisticalRoom/StatisticalRoom";

export default function StatisticalMenu() {
  const { token, userInfo } = React.useContext(AuthContext);
  //   const { path } = useMatch();
  const { pathname } = useLocation();
  //   console.log({ pathname });
  const paths = pathname.split("/");
  //   console.log(paths);
  const navigate = useNavigate();

  const handleItemClick = React.useCallback(
    (event, type) => {
      // console.log({ type });
      navigate(type);
    },
    [navigate]
  );

  if (paths.length === 2) {
    let menu = STATISTICAL_MENU;
    return (
      <>
        <Header title="Statistical Menu" />
        <Grid container direction="column" spacing={0}>
          {menu.map((item) => (
            <Grid item key={item.type}>
              <Button
                variant="outlined"
                startIcon={item.icon}
                fullWidth
                className="btn-item-settings"
                size="large"
                color="secondary"
                onClick={(event) => handleItemClick(event, item.type)}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <Routes>
        <Route element={<StatisticalStaff />} exact path="/staff" />
        <Route element={<StatisticalStaff />} exact path="/spending" />
        <Route element={<StatisticalRoom />} exact path="/room" />
        <Route element={<StatisticalStaff />} exact path="/pos" />
        <Route element={<StatisticalStaff />} exact path="/overview" />
      </Routes>
    );
  }
}
