import React from "react";
import { AuthContext } from "../../../../context/AuthProvider";
import moment from "moment";
import {
  createCustomer,
  updateCustomer,
  createPhoto,
} from "../../../../api/api";
import Header from "../../../Header/Header";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ObjectID } from "bson";
import {
  CONTACT_OPTIONS,
  CUSTOMER_FROM_OPTIONS,
  GENDER_OPTIONS,
  LANGUAGE_OPTIONS,
} from "../../../../constants";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import FileUpload from "../../../../helpers/FileUpload/FileUpload";
import FileBase64 from "react-filebase64";
import PhotoActionsDialog from "../../../../helpers/PhotoActionsDialog";
import { useLocation, useNavigate, useParams } from "react-router";
import PhotoViewer from "../../../PhotoViewer/PhotoViewer";

export default function EditCustomer({ itemId, datas, setDatas }) {
  console.log({ itemId });
  console.log({ datas });
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");
  const navigate = useNavigate();
  const { token, userInfo, setUserInfo } = React.useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [contactInfo, setContactInfo] = React.useState("");
  const [from, setFrom] = React.useState(CUSTOMER_FROM_OPTIONS[0]);
  const [language, setLanguage] = React.useState(LANGUAGE_OPTIONS[0]);
  const [gender, setGender] = React.useState(GENDER_OPTIONS[0]);
  const [job, setJob] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [companyAddress, setCompanyAddress] = React.useState("");
  const [companyTaxCode, setCompanyTaxCode] = React.useState("");
  const [companyEmail, setCompanyEmail] = React.useState("");
  const [companyPhone, setCompanyPhone] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  const [returnParent, setReturnParent] = React.useState(false);
  const [photos, setPhotos] = React.useState([null, null]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = React.useState(0);

  const item = React.useMemo(() => {
    console.log("getting item");
    setProgress(10);
    setFetching(true);
    let alertContent;
    let item = null;
    if (itemId) {
      for (let i = 0; i < datas.length; i++) {
        if (datas[i]._id === itemId) {
          item = datas[i];
        }
      }
      setProgress(50);
      // const item = getItem(itemId);
      if (item) {
        setData(item);
        setName(item.name);
        setPhone(item.phone);
        setEmail(item.email);
        setBirthday(moment(item.birthday).format("DD/MM/yyyy"));
        setAddress(item.address);
        setContact(item.contact);
        setContactInfo(item.contactInfo);
        setFrom(item.from);
        setLanguage(item.language);
        setGender(item.gender);
        setJob(item.job);
        setCompanyName(item.companyName);
        setCompanyAddress(item.companyAddress);
        setCompanyTaxCode(item.companyTaxCode);
        setCompanyEmail(item.companyEmail);
        setCompanyPhone(item.companyPhone);
        if (item.photos) {
          setPhotos(item.photos);
        }
        console.log({ item });
      } else {
        alertContent = `No Customer with ID: ${itemId} found!`;
      }
    }
    setProgress(100);
    setFetching(false);
    if (alertContent) {
      alert(alertContent);
    }
    return item;
  }, [datas, itemId]);

  const setDataList = React.useCallback(
    (itemId, res) => {
      if (itemId) {
        //updated
        setDatas(
          datas.map((item) => {
            if (item._id === itemId) {
              return res.data;
            } else {
              return item;
            }
          })
        );
      } else {
        //created
        setDatas([...datas, res.data]);
      }
    },
    [datas, setDatas]
  );
  const handleSubmit = React.useCallback(
    async (event) => {
      setProgress(10);
      setFetching(true);
      let alertContent;
      const photosTmp = photos;
      for (let i = 0; i < photos.length; i++) {
        if (photos[i]?._id === undefined && photos[i]?.data) {
          //upload new photo
          await createPhoto(token, { data: photos[i]?.data })
            .then((res) => {
              if (res && res.status === 200) {
                photosTmp[i] = { _id: res.data._id };
              } else {
                console.log({ err: res.status });
              }
            })
            .catch((err) => {
              console.log({ err });
            });
        }
      }
      const photosTmp2 = photosTmp.map((item) => ({
        photoId: item?._id || undefined,
      }));
      console.log({ photosTmp2 });
      const newItem = {
        // _id: new ObjectID('6280ba76f388785fc4bd254b'),
        name,
        phone,
        email,
        birthday: moment(birthday, "DD/MM/YYYY").toDate(),
        address,
        contact,
        contactInfo,
        from,
        language,
        gender,
        job,
        companyName,
        companyAddress,
        companyTaxCode,
        companyEmail,
        companyPhone,
        photos: photosTmp2,
        lastUpdatedBy: userInfo._id,
      };
      if (itemId) {
        newItem["_id"] = itemId;
        console.log({ newItem });
        await updateCustomer(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              setDataList(itemId, res);
              alertContent = `Update success!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            console.log({ err });
            alertContent = err;
          });
      } else {
        console.log({ newItem });
        await createCustomer(token, newItem)
          .then((res) => {
            if (res && res.status === 200) {
              setDataList(itemId, res);
              alertContent = `Create success!`;
            } else {
              alertContent = `Error: ${res.status}`;
            }
          })
          .catch((err) => {
            console.log({ err });
            alertContent = err;
          });
      }
      setProgress(80);
      setFetching(false);
      if (alertContent) {
        alert(alertContent);
      }
      setReturnParent(true);
    },
    [photos, name, phone, email, birthday, address, contact, contactInfo, from, language, gender, job, companyName, companyAddress, companyTaxCode, companyEmail, companyPhone, userInfo._id, itemId, token, setDataList]
  );
  // console.log({ identityPhoto });
  const handleDialogItemClick = (value) => {
    if (value === "view") {
      navigate(`photo-${currentPhotoIndex}`);
    } else {
    }
  };
  const action = pathArray.pop();
  if (action.includes("photo-")) {
    const photoIndex = action.replace("photo-", "");
    return (
      <PhotoViewer src={photos[photoIndex]?.data} title="Customer Photo" />
    );
  } else {
    return (
      <>
        <Header
          title={itemId ? "Edit Customer" : "New Customer"}
          triggerParentClick={returnParent}
        />
        {fetching ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        ) : (
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Autocomplete
                className="language-options"
                options={LANGUAGE_OPTIONS}
                value={language}
                onChange={(event, newValue) => {
                  setLanguage(newValue);
                  // console.log({newValue});
                }}
                id="language-options"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Language" variant="outlined" />
                )}
              />
              {/* <TextField
            label="Language (vi | en)"
            fullWidth
            variant="outlined"
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          /> */}
            </Grid>
            <Grid item>
              <Autocomplete
                className="gender-options"
                options={GENDER_OPTIONS}
                value={gender}
                onChange={(event, newValue) => {
                  setGender(newValue);
                  // console.log({newValue});
                }}
                id="gender-options"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Gender" variant="outlined" />
                )}
              />
            </Grid>
            {/* <Grid item>
          <TextField
            label="Gender (male | female)"
            fullWidth
            variant="outlined"
            value={gender}
            onChange={(e) => {
              setGender(e.target.value);
            }}
          />
        </Grid> */}
            <Grid item>
              <TextField
                label="Name (required)"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Phone (required)"
                fullWidth
                variant="outlined"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Birthday (Ex:20/03/1991)"
                fullWidth
                variant="outlined"
                value={birthday}
                onChange={(e) => {
                  setBirthday(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Address"
                fullWidth
                variant="outlined"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                className="contact-options"
                options={CONTACT_OPTIONS}
                value={contact}
                onChange={(event, newValue) => {
                  setContact(newValue);
                  // console.log({newValue});
                }}
                id="contact-options"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contact (required)"
                    variant="outlined"
                  />
                )}
              />
              {/* <TextField
            label="Contact(Zalo | Mess)"
            fullWidth
            variant="outlined"
            value={contact}
            onChange={(e) => {
              setContact(e.target.value);
            }}
          /> */}
            </Grid>
            <Grid item>
              <TextField
                label="Contact Info"
                fullWidth
                variant="outlined"
                value={contactInfo}
                onChange={(e) => {
                  setContactInfo(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                className="customer-from-options"
                options={CUSTOMER_FROM_OPTIONS}
                value={from}
                onChange={(event, newValue) => {
                  setFrom(newValue);
                  // console.log({newValue});
                }}
                id="customer-from-options"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="From" variant="outlined" />
                )}
              />
              {/* <TextField
            label="From (GG Search | FB)"
            fullWidth
            variant="outlined"
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
          /> */}
            </Grid>
            <Grid item>
              <TextField
                label="Job"
                fullWidth
                variant="outlined"
                value={job}
                onChange={(e) => {
                  setJob(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Company Name"
                fullWidth
                variant="outlined"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Company Address"
                fullWidth
                variant="outlined"
                value={companyAddress}
                onChange={(e) => {
                  setCompanyAddress(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Company Tax Code"
                fullWidth
                variant="outlined"
                value={companyTaxCode}
                onChange={(e) => {
                  setCompanyTaxCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Company Email"
                fullWidth
                variant="outlined"
                value={companyEmail}
                onChange={(e) => {
                  setCompanyEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Company Phone"
                fullWidth
                variant="outlined"
                value={companyPhone}
                onChange={(e) => {
                  setCompanyPhone(e.target.value);
                }}
              />
            </Grid>
            {photos.map((item, index) => (
              <>
                <Grid item>
                  <Typography>{`Photo ${index + 1}: `}</Typography>
                  <FileBase64
                    multiple={false}
                    onDone={({ base64 }) =>
                      setPhotos((prevState) => {
                        prevState.map((prevItem, prevIndex) => {
                          if (index === prevIndex) {
                            return {
                              ...prevItem,
                              _id: undefined,
                              data: base64,
                            };
                          } else {
                            return prevItem;
                          }
                        });
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <Box
                    component="img"
                    sx={{ width: "100%" }}
                    // sx={{
                    //   height: 233,
                    //   width: 350,
                    //   maxHeight: { xs: 233, md: 167 },
                    //   maxWidth: { xs: 350, md: 250 },
                    // }}
                    alt={`Photo-${index}`}
                    src={item?.data}
                    onClick={(event) => {
                      setCurrentPhotoIndex(index);
                      setOpenDialog(true);
                      // console.log("image clicked");
                    }}
                  />
                </Grid>
              </>
            ))}
            {/* <img alt="Identity-photo" src={identityPhoto} /> */}
            {/* <Grid item>
              <FileUpload
                label="ID/Passport Photo"
                imageButton={false}
                accept="image/*"
                onChange={(event) => {
                  if (
                    event.target.files !== null &&
                    event.target?.files?.length > 0
                  ) {
                    console.log(`Saving ${event.target.value}`);
                  }
                }}
                onDrop={(event) => {
                  console.log(`Drop ${event.dataTransfer.files[0].name}`);
                }}
              />
            </Grid> */}
            <Grid item>
              <Typography component="div">
                {`Last updated by: ${data?.lastUpdatedBy?.username}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                className="btn-submit"
                size="medium"
                color="primary"
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
        <PhotoActionsDialog
          dialogOpen={openDialog}
          setDialogOpenState={(state) => {
            setOpenDialog(state);
          }}
          handleItemClick={handleDialogItemClick}
        />
      </>
    );
  }
}
